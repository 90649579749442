    <nb-card class="modal-card" [nbSpinner]="isLoading" nbSpinnerStatus="basic">
        <nb-card-header>
            <div class="close-modal-icon" >
                <img src="../../../../assets/images/icons/cancel.svg" alt="">
            </div>
            <div class="modal-title text-left">
                Update assignments and roles
            </div>
        </nb-card-header>
        <nb-card-body>
         
        <div class="create-form-body">
            <div class="form-modal-group"  >
                <div class="practice-list-box">
                    <!-- <div class="practice-item w-50">
                        <label class="label-name" for="locationIds">Assignments and roles</label>
                        <ng-select [disabled]="true" (change)="onChangeAssign()" appendTo="body" [loading]="isPracticesLoading" placeholder="All Practices" [multiple]="false"
                            [(ngModel)]="selectedPractice" id="practiceIds" [searchable]="true"
                            [clearable]="false">
                            <ng-option *ngFor="let practice of practicesDto" [value]="practice.id">{{practice.name}}</ng-option>
                        </ng-select>
                    </div> -->
                    <div class="practice-item w-100" *ngIf="selectedPractice  && userModelDto.userRole !== 'Manager'"> 
                        <div class="permissions-list">
                            <div class="radio-list-permission">
                                <div class="radio-item">
                                    <div class="radio-label">
                                        ORDER ACCESS
                                    </div>
                                    <nb-radio-group [(ngModel)]="item.isOnlyOwnOrdersAcess" class="practice-list-box" name="order">
                                        <nb-radio *ngFor="let option of permissionList" [value]="option.value">
                                            {{ option.label }}
                                        </nb-radio>
                                    </nb-radio-group>
                                </div>
                                <div class="radio-item">    
                                    <div class="radio-label">
                                       PATIENT ACCESS
                                    </div>
                                    <nb-radio-group  [(ngModel)]="item.isOnlyOwnPatientsAcess" class="practice-list-box" name="patient">
                                        <nb-radio *ngFor="let option of permissionList" [value]="option.value">
                                            {{ option.label }}
                                        </nb-radio>
                                    </nb-radio-group>
                                </div>


                                <div class="radio-item">
                                    <div class="radio-label">
                                        PERFORMER NUMBER
                                    </div>
                                    <input  [(ngModel)]="item.idhPerformerNumber" id="performer" type="number" nbInput fullWidth
                                        fieldSize="small" placeholder="">
                                </div>

                                <div class="radio-item">
                                    <div class="radio-label">
                                        ACCOUNT CODE
                                    </div>
                                    <input [(ngModel)]="item.accountCode" id="performer" type="text" nbInput fullWidth
                                        fieldSize="small" placeholder="">
                                </div>

                                <div class="radio-item">
                                    <div class="radio-label">
                                        CREDIT LIMIT
                                    </div>
                                    <input [(ngModel)]="item.creditLimit" id="performer" type="number" nbInput fullWidth
                                        fieldSize="small" placeholder="">
                                </div>
                            </div>
                            <!-- <button nbButton size="small" fullWidth class="settings-bt-clinet" (click)="addAssignModel()">
                                Done
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </nb-card-body>
        <nb-card-footer>
           
            <div class="close-modal-container position-relative">
                <button shape="semi-round" (click)="close()" class="btn-shadow mr-2 close-button-size" nbButton size="small" status="basic">
                    Close
                  </button>
                <button shape="semi-round" (click)="updateAssignment()" nbButton status="primary" size="small" class="save-button-size">
                   Save
                </button>
            </div>
        </nb-card-footer>
    </nb-card>





















