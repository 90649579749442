import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { finalize } from 'rxjs/operators';
import { PatientStatementModel, BillingServiceProxy } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from '../../../@core/service';

@Component({
  selector: 'ngx-print-statment-docket',
  templateUrl: './print-statment-docket.component.html',
  styleUrls: ['./print-statment-docket.component.scss']
})
export class PrintStatmentDocketComponent implements OnInit, AfterViewInit  {
  user: any = {};
  @Input() invoiceId: any;
  patientStatement: PatientStatementModel = new PatientStatementModel();
  @Output() outputInfo = new EventEmitter<any>();
  @Output() loadComplete = new EventEmitter<void>();
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadComplete.emit();
    }, 1000);

  }

  constructor(
    private _authenticationService: AuthenticationService,
    private _billingService: BillingServiceProxy,
    private route: ActivatedRoute
  ) { 
    let currentUser = this._authenticationService.currentUserValue;
      let helper = new JwtHelperService();
      this.user = helper.decodeToken(currentUser.accessToken);
     
  }
  ngOnInit(): void {
    this.getInvoiceDetails();

  }

  isDeffaultFiitertemplate: boolean = false;
  patientStatmentTermsConditions: string = `This is a custom-made medical device that has been manufactured to satisfy the design characteristics and properties specified by the prescriber for the above named patient. This medical device is intended for exclusive use by this patient and conforms to the relevant essential requirements specified in Annex 1 of the Medical Devices Directive and the United Kingdom Medical Devices Regulations. This statement does not apply to medical devices that have been repaired and/or refurbished for an individual patients use.`;
  patientStatmentFooter: string = `2023 GreatLab Ltd, Registered in England and Wales - <b> Company No. 411111</b>`;
  patientStatmentFooterLogo: string = `../../../../assets/images/icons/damas_black.png`;


  getInvoiceDetails() {
    this._billingService.getPatientStatementById(this.invoiceId).pipe(
      finalize(() => {
     
      }))
    .subscribe(
      (result: PatientStatementModel) => { 
        this.patientStatement = result;
        if (!this.patientStatement.locationDocumentSettings || this.patientStatement.locationDocumentSettings?.patientStatementFooter.length == 0) {
          console.log(false);
          this.isDeffaultFiitertemplate = true;
          this.patientStatement.locationDocumentSettings = null;
         
        } 
        let title: any = this.patientStatement.locationDocumentSettings? this.patientStatement.locationDocumentSettings?.patientStatementTitle : 'statement';
        this.outputInfo.emit(title)
      },
      (error: any) => {}
    )
  }

}
