<nb-card class="modal-card">
    <nb-card-header>
        <div class="modal-title">
            Shipping label preview
        </div>
    </nb-card-header>
    <nb-card-body class="modal-body">
        <div class="image-block" [ngClass]="{'single-label' : shippingLabels.length == 1}">
            <nb-icon icon="arrow-back-outline" (click)="previous()" class="control-arrow"
                *ngIf="shippingLabels.length > 1"></nb-icon>
            <img [src]="selectedLabel.labelDownload.png" alt="Shipping label" class="shipping-label" />
            <nb-icon icon="arrow-forward-outline" (click)="next()" class="control-arrow"
                *ngIf="shippingLabels.length > 1"></nb-icon>
        </div>
        <div *ngIf="shippingLabels.length > 1" class="pages-block">
            <span class="page-number m-0">{{selectedPage}} / {{shippingLabels.length}}</span>
        </div>
        <div class="view-tracking d-flex">
            <button nbButton class="track-button" size="small" (click)="viewTracking()">
                <nb-icon icon="search-outline"></nb-icon>
                View tracking
            </button>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="control-buttons d-flex">
            <div class="buttons-group d-flex">
                <button class="btn-control" nbButton size="small" (click)="close()">
                    Close
                </button>
            </div>
            <div class="buttons-group d-flex">
                <button class="btn-control" nbButton size="small" (click)="download()">
                    <nb-icon icon="cloud-download-outline"></nb-icon>
                    Download
                </button>
                <button class="btn-control" nbButton status="primary" size="small" (click)="print()">
                    <nb-icon icon="print-btn-white" pack="great-pack"></nb-icon>
                    Print ({{shippingLabels.length}})
                </button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>