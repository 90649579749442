export enum TeethNotationOption {
  ExcludeForMovement = 1,
  AvoidEngagers,
  ExtractedTeeth,
  LeaveSpaces,
  Restoration,
}

export enum NotationOptions {
  PALMER = "palmer",
  FDI = "fdi",
  UNIVERSAL = "universal",
}

export type NotationResponseOptions = "Universal" | "Palmer" | "FDI";

export interface NotationResponse {
  toothLabelName: NotationResponseOptions;
}

export const DEFAULT_NOTATION = NotationOptions.PALMER;

export enum PalmerToUniversalNotation {
  UR8 = 1,
  UR7 = 2,
  UR6 = 3,
  UR5 = 4,
  UR4 = 5,
  UR3 = 6,
  UR2 = 7,
  UR1 = 8,
  UL1 = 9,
  UL2 = 10,
  UL3 = 11,
  UL4 = 12,
  UL5 = 13,
  UL6 = 14,
  UL7 = 15,
  UL8 = 16,
  LL8 = 17,
  LL7 = 18,
  LL6 = 19,
  LL5 = 20,
  LL4 = 21,
  LL3 = 22,
  LL2 = 23,
  LL1 = 24,
  LR1 = 25,
  LR2 = 26,
  LR3 = 27,
  LR4 = 28,
  LR5 = 29,
  LR6 = 30,
  LR7 = 31,
  LR8 = 32,
}

export enum PalmerToFDINotation {
  UR8 = 18,
  UR7 = 17,
  UR6 = 16,
  UR5 = 15,
  UR4 = 14,
  UR3 = 13,
  UR2 = 12,
  UR1 = 11,
  UL1 = 21,
  UL2 = 22,
  UL3 = 23,
  UL4 = 24,
  UL5 = 25,
  UL6 = 26,
  UL7 = 27,
  UL8 = 28,
  LL8 = 38,
  LL7 = 37,
  LL6 = 36,
  LL5 = 35,
  LL4 = 34,
  LL3 = 33,
  LL2 = 32,
  LL1 = 31,
  LR1 = 41,
  LR2 = 42,
  LR3 = 43,
  LR4 = 44,
  LR5 = 45,
  LR6 = 46,
  LR7 = 47,
  LR8 = 48,
}

export enum UniversalToPalmerSpaces {
  "1_2" = "UR7_UR8",
  "2_3" = "UR6_UR7",
  "3_4" = "UR5_UR6",
  "4_5" = "UR4_UR5",
  "5_6" = "UR3_UR4",
  "6_7" = "UR2_UR3",
  "7_8" = "UR1_UR2",
  "8_9" = "UR1_UL1",
  "9_10" = "UL1_UL2",
  "10_11" = "UL2_UL3",
  "11_12" = "UL3_UL4",
  "12_13" = "UL4_UL5",
  "13_14" = "UL5_UL6",
  "14_15" = "UL6_UL7",
  "15_16" = "UL7_UL8",
  "18_17" = "LL8_LL7",
  "19_18" = "LL7_LL6",
  "20_19" = "LL6_LL5",
  "21_20" = "LL5_LL4",
  "22_21" = "LL4_LL3",
  "23_22" = "LL3_LL2",
  "24_23" = "LL2_LL1",
  "25_24" = "LR1_LL1",
  "26_25" = "LR2_LR1",
  "27_26" = "LR3_LR2",
  "28_27" = "LR4_LR3",
  "29_28" = "LR5_LR4",
  "30_29" = "LR6_LR5",
  "31_30" = "LR7_LR6",
  "32_31" = "LR8_LR7",
}

export enum PalmerSpacesToUniversal {
  "UR7_UR8" = "1_2",
  "UR6_UR7" = "2_3",
  "UR5_UR6" = "3_4",
  "UR4_UR5" = "4_5",
  "UR3_UR4" = "5_6",
  "UR2_UR3" = "6_7",
  "UR1_UR2" = "7_8",
  "UR1_UL1" = "8_9",
  "UL1_UL2" = "9_10",
  "UL2_UL3" = "10_11",
  "UL3_UL4" = "11_12",
  "UL4_UL5" = "12_13",
  "UL5_UL6" = "13_14",
  "UL6_UL7" = "14_15",
  "UL7_UL8" = "15_16",
  "LL8_LL7" = "18_17",
  "LL7_LL6" = "19_18",
  "LL6_LL5" = "20_19",
  "LL5_LL4" = "21_20",
  "LL4_LL3" = "22_21",
  "LL3_LL2" = "23_22",
  "LL2_LL1" = "24_23",
  "LR1_LL1" = "25_24",
  "LR2_LR1" = "26_25",
  "LR3_LR2" = "27_26",
  "LR4_LR3" = "28_27",
  "LR5_LR4" = "29_28",
  "LR6_LR5" = "30_29",
  "LR7_LR6" = "31_30",
  "LR8_LR7" = "32_31",
}

export enum UniversalToFDISpaces {
  "1_2" = "17_18",
  "2_3" = "16_17",
  "3_4" = "15_16",
  "4_5" = "14_15",
  "5_6" = "13_14",
  "6_7" = "12_13",
  "7_8" = "11_12",
  "8_9" = "11_21",
  "9_10" = "21_22",
  "10_11" = "22_23",
  "11_12" = "23_24",
  "12_13" = "24_25",
  "13_14" = "25_26",
  "14_15" = "26_27",
  "15_16" = "27_28",
  "18_17" = "38_37",
  "19_18" = "37_36",
  "20_19" = "36_35",
  "21_20" = "35_34",
  "22_21" = "34_33",
  "23_22" = "33_32",
  "24_23" = "32_31",
  "25_24" = "41_31",
  "26_25" = "42_41",
  "27_26" = "43_42",
  "28_27" = "44_43",
  "29_28" = "45_44",
  "30_29" = "46_45",
  "31_30" = "47_46",
  "32_31" = "48_47",
}

export const getEnumKeyByVal = (inEnum, value) => {
  const keys = Object.keys(inEnum).filter((k) => {
    return inEnum[k] === value;
  });
  return keys.length > 0 ? keys[0] : null;
};

export const getEnumKeys = (inEnum) => {
  return Object.keys(inEnum).filter((key) => {
    return Boolean(parseInt(key, 10)) === false;
  });
};

export enum InstructionsImproveOptions {
  MAINTAIN = "maintain",
  IMPROVE = "improve",
  IDEALIZE = "idealize",
}

export enum InstructionsImproveItems {
  upperMidlineInstruction = "upperMidlineInstruction",
  lowerMidlineInstruction = "lowerMidlineInstruction",
  overjet = "overjet",
  overbite = "overbite",
  archForm = "archForm",
  canineRelationship = "canineRelationship",
  molarRelationship = "molarRelationship",
  posteriorCrossbite = "posteriorCrossbite",
}

export enum InstructionsChangeItems {
  ipr = "ipr",
  engagers = "engagers",
  procline = "procline",
  expand = "expand",
  distalize = "distalize",
}

export enum InstructionsChangeOptions {
  YES = "yes",
  NO = "no",
  IF_NEEDED = "only if needed",
}


export enum FakeConvertorToValidRestorationTypeTeeth {
  UR8 = 1,
  UR7 = 2,
  UR6 = 3,
  UR5 = 4,
  UR4 = 5,
  UR3 = 6,
  UR2 = 7,
  UR1 = 8,
  UL1 = 9,
  UL2 = 10,
  UL3 = 11,
  UL4 = 12,
  UL5 = 13,
  UL6 = 14,
  UL7 = 15,
  UL8 = 16,
  LL8 = 17,
  LL7 = 18,
  LL6 = 19,
  LL5 = 20,
  LL4 = 21,
  LL3 = 22,
  LL2 = 23,
  LL1 = 24,
  LR1 = 25,
  LR2 = 26,
  LR3 = 27,
  LR4 = 28,
  LR5 = 29,
  LR6 = 30,
  LR7 = 31,
  LR8 = 32,
}