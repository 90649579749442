<form class="form-horizontal" [formGroup]="form">
    <nb-card class="modal-card" [nbSpinner]="isLoading" nbSpinnerStatus="info">
        <nb-card-header>
            <div class="close-modal-icon" (click)="close()">
                <img src="../../../../assets/images/icons/cancel.svg" alt="">
            </div>
            <div class="modal-title text-left">{{!isEdit ? 'Create' : 'Update' }} practice</div>
        </nb-card-header>
        <nb-card-body>


            <div class="create-form-body">
                <div class="clear-form" (click)="resetForm()">
                    Clear all
                </div>

                <div *ngIf="!isDoctorAlreadyExist">
                    <span class="group-title">Step 1: Create Practice profile</span>
                </div>

                <div class="row m-0 w-100">
                    <div class="col-6 pl-0 ">
                        <div [ngClass]="displayFieldCss('name')">
                            <label class="label-name" for="name">Name</label>
                            <input [(ngModel)]="practiceModelDto.name" formControlName="name" id="name" type="text"
                                nbInput fullWidth fieldSize="small" placeholder="Greatlab">
                            <app-field-error-display [displayError]="isFieldValid('name')"
                                errorMsg="Please inform your company name">
                            </app-field-error-display>
                        </div>

                        <div class="form-modal-group " [ngClass]="displayFieldCss('email')">
                            <label class="label-name" for="email">Email</label>
                            <input [(ngModel)]="practiceModelDto.email" formControlName="email" id="email" type="text"
                                nbInput fullWidth fieldSize="small" placeholder="company@greatlab.io">
                            <app-field-error-display [displayError]="isFieldValid('email')"
                                errorMsg="Please inform your email">
                            </app-field-error-display>
                        </div>

                        <div class="checkbox-container">
                            <input type="checkbox" [checked]="isSaveAsBilling" (change)="toggleSaveAsBilling()">
                            <span class="checkbox-text">Save as Billing</span>
                        </div>

                    </div>
                    <div class="col-6 pr-0">


                       
                        <div class="form-modal-group" [ngClass]="displayFieldCss('locationExportGroupId')">
                            <label class="label-name" for="locationExportGroupId">Practice Group</label>
                            <ng-select appendTo="body" [loading]="isPracticeGroup" placeholder="Select practice group"
                                [multiple]="false" [(ngModel)]="practiceModelDto.locationExportGroupId"
                                 formControlName="locationExportGroupId" [searchable]="true" [clearable]="false">
                                <ng-option *ngFor="let  exportGroup of locationExportGroups" [value]="exportGroup.id">
                                    {{exportGroup.name}}
                                </ng-option>
                                <ng-template ng-footer-tmp>
                                    <div class="create-location-group" (click)="createGroup()">
                                        <img src="../../../../assets/images/icons/blue-plus.svg" alt="">
                                        Create Group
                                    </div>
                                </ng-template>
                            </ng-select>
                            <app-field-error-display [displayError]="isFieldValid('locationExportGroupId')"
                                errorMsg="Please inform country">
                            </app-field-error-display>
                        </div>


                            <div class="form-modal-group " [ngClass]="displayFieldCss('idhPracticeNumber')">
                                <label class="label-name" for="idhPracticeNumber">IDH Practice Number</label>
                                <input [(ngModel)]="practiceModelDto.idhPracticeNumber" formControlName="idhPracticeNumber"
                                    id="idhPracticeNumber" type="text" nbInput fullWidth fieldSize="small"
                                    placeholder="">
                                <app-field-error-display [displayError]="isFieldValid('idhPracticeNumber')"
                                    errorMsg="Please inform your billingEmail">
                                </app-field-error-display>
                            </div>


                        <div *ngIf="!isSaveAsBilling">
                            <div class="form-modal-group " [ngClass]="displayFieldCss('billingEmail')">
                                <label class="label-name" for="billingEmail">Billing Email</label>
                                <input [(ngModel)]="practiceModelDto.billingEmail" formControlName="billingEmail"
                                    id="billingEmail" type="text" nbInput fullWidth fieldSize="small"
                                    placeholder="company@greatlab.io">
                                <app-field-error-display [displayError]="isFieldValid('billingEmail')"
                                    errorMsg="Please inform your billingEmail">
                                </app-field-error-display>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="line"></div>
                <div>
                    <span class="group-title">Delivery address</span>
                </div>
                <div class="row m-0 w-100">
                    <div class="col-6 pl-0 ">
                        <div class="form-modal-group " [ngClass]="displayFieldCss('address')">
                            <label class="label-name" for="country">Address Line 1</label>
                            <input [(ngModel)]="practiceModelDto.address" formControlName="address" id="address"
                                type="text" nbInput fullWidth fieldSize="small"
                                placeholder="16 Elgin Rd, Ballsbridge, Dublin 4">
                            <app-field-error-display [displayError]="isFieldValid('address')"
                                errorMsg="Please inform address line 1">
                            </app-field-error-display>
                        </div>

                        <div class="form-modal-group " [ngClass]="displayFieldCss('city')">
                            <label class="label-name" for="city">City/Town</label>
                            <input [(ngModel)]="practiceModelDto.city" formControlName="city" id="city" type="text"
                                nbInput fullWidth fieldSize="small" placeholder="....">
                            <app-field-error-display [displayError]="isFieldValid('city')"
                                errorMsg="Please inform city">
                            </app-field-error-display>
                        </div>
                    </div>
                    <div class="col-6 pr-0">
                        <div *ngIf="!IsAddress2" style="padding-top: 27px;">
                            <span (click)="toggleIsAddress2()" class="checkbox-text">+ Address Line 2</span>
                        </div>
                        <div *ngIf="IsAddress2">
                            <div class="form-modal-group " [ngClass]="displayFieldCss('address2')">
                                <label class="label-name" for="address2">Address Line 2</label>
                                <input [(ngModel)]="practiceModelDto.address2" formControlName="address2" id="address"
                                    type="text" nbInput fullWidth fieldSize="small"
                                    placeholder="16 Elgin Rd, Ballsbridge, Dublin 4">
                                <app-field-error-display [displayError]="isFieldValid('address2')"
                                    errorMsg="Please inform address">
                                </app-field-error-display>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row m-0 w-100">
                    <div class="col-6 pl-0 ">
                        <div class="form-modal-group" [ngClass]="displayFieldCss('country')">
                            <label class="label-name" for="country">Country</label>
                            <ng-select appendTo="body" [loading]="isCountriesLoading" placeholder="Select country"
                                [multiple]="false" [(ngModel)]="practiceModelDto.country" formControlName="country"
                                (change)="onCountryChange(practiceModelDto.country, true)" [searchable]="true"
                                [clearable]="false">
                                <ng-option *ngFor="let country of countries" [value]="country.countryName">
                                    {{country.countryName}}
                                </ng-option>
                            </ng-select>
                            <app-field-error-display [displayError]="isFieldValid('country')"
                                errorMsg="Please inform country">
                            </app-field-error-display>
                        </div>

                        <div class="form-modal-group w-50 " [ngClass]="displayFieldCss('postCode')">
                            <label class="label-name" for="postCode">ZIP/PostCode</label>
                            <input [(ngModel)]="practiceModelDto.postCode" formControlName="postCode" id="postCode"
                                type="text" nbInput fullWidth fieldSize="small" placeholder="....">
                            <app-field-error-display [displayError]="isFieldValid('postCode')"
                                errorMsg="Please inform postCode">
                            </app-field-error-display>
                        </div>

                        <div class="checkbox-container">
                            <input type="checkbox" [checked]="isSaveAsBillingAddress"
                                (change)="toggleSaveAsBillingAddress()">
                            <span class="checkbox-text">Save as Billing</span>
                        </div>
                    </div>
                    <div class="col-6 pr-0">
                        <ng-container *ngIf="states">
                            <div class="form-modal-group" [ngClass]="displayFieldCss('state')">
                                <label class="label-name" for="state">State/Province/Region</label>
                                <ng-select appendTo="body" placeholder="Select" [multiple]="false"
                                    [(ngModel)]="practiceModelDto.state" formControlName="state" [searchable]="true"
                                    [clearable]="false">
                                    <ng-option *ngFor="let state of states" [value]="state.abbreviation">
                                        {{state.name}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!states">
                            <div class="form-modal-group" [ngClass]="displayFieldCss('state')">
                                <label class="label-name" for="state">State/Province/Region</label>
                                <input type="text" nbInput fullWidth fieldSize="small" placeholder=""
                                    formControlName="state" [(ngModel)]="practiceModelDto.state">
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="!isSaveAsBillingAddress">
                    <div class="line"></div>
                    <div>
                        <span class="group-title">Billing address</span>
                    </div>
                    <div class="row m-0 w-100">
                        <div class="col-6 pl-0 ">
                            <div class="form-modal-group " [ngClass]="displayFieldCss('billingAddress')">
                                <label class="label-name" for="billingAddress">Address Line 1</label>
                                <input [(ngModel)]="practiceModelDto.billingAddress" formControlName="billingAddress"
                                    id="billingAddress" type="text" nbInput fullWidth fieldSize="small"
                                    placeholder="16 Elgin Rd, Ballsbridge, Dublin 4">
                                <app-field-error-display [displayError]="isFieldValid('billingAddress')"
                                    errorMsg="Please inform address line 1">
                                </app-field-error-display>
                            </div>

                            <div class="form-modal-group " [ngClass]="displayFieldCss('billingCity')">
                                <label class="label-name" for="billingCity">City/Town</label>
                                <input [(ngModel)]="practiceModelDto.billingCity" formControlName="billingCity"
                                    id="billingCity" type="text" nbInput fullWidth fieldSize="small" placeholder="....">
                                <app-field-error-display [displayError]="isFieldValid('billingCity')"
                                    errorMsg="Please inform city">
                                </app-field-error-display>
                            </div>
                        </div>
                        <div class="col-6 pr-0">
                            <div *ngIf="!IsBillingAddress2" style="padding-top: 27px;">
                                <span (click)="toggleIsBillingAddress2()" class="checkbox-text">+ Address Line 2</span>
                            </div>
                            <div *ngIf="IsBillingAddress2">
                                <div class="form-modal-group " [ngClass]="displayFieldCss('billingAddress2')">
                                    <label class="label-name" for="billingAddress2">Address Line 2</label>
                                    <input [(ngModel)]="practiceModelDto.billingAddress2"
                                        formControlName="billingAddress2" id="billingAddress2" type="text" nbInput
                                        fullWidth fieldSize="small" placeholder="16 Elgin Rd, Ballsbridge, Dublin 4">
                                    <app-field-error-display [displayError]="isFieldValid('billingAddress2')"
                                        errorMsg="Please inform address">
                                    </app-field-error-display>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row m-0 w-100">
                        <div class="col-6 pl-0 ">
                            <div class="form-modal-group" [ngClass]="displayFieldCss('billingCountry')">
                                <label class="label-name" for="billingCountry">Country</label>
                                <ng-select appendTo="body" [loading]="isCountriesLoading" placeholder="Select country"
                                    [multiple]="false" [(ngModel)]="practiceModelDto.billingCountry"
                                    formControlName="billingCountry"
                                    (change)="onBillingCountryChange(practiceModelDto.billingCountry, true)"
                                    [searchable]="true" [clearable]="false">
                                    <ng-option *ngFor="let country of countries" [value]="country.countryName">
                                        {{country.countryName}}
                                    </ng-option>
                                </ng-select>
                                <app-field-error-display [displayError]="isFieldValid('country')"
                                    errorMsg="Please inform country">
                                </app-field-error-display>
                            </div>

                            <div class="form-modal-group w-50 " [ngClass]="displayFieldCss('billingPostCode')">
                                <label class="label-name" for="billingPostCode">ZIP/PostCode</label>
                                <input [(ngModel)]="practiceModelDto.billingPostCode" formControlName="billingPostCode"
                                    id="billingPostCode" type="text" nbInput fullWidth fieldSize="small"
                                    placeholder="....">
                                <app-field-error-display [displayError]="isFieldValid('billingPostCode')"
                                    errorMsg="Please inform postCode">
                                </app-field-error-display>
                            </div>

                        </div>
                        <div class="col-6 pr-0">
                            <ng-container *ngIf="billingStates">
                                <div class="form-modal-group" [ngClass]="displayFieldCss('billingState')">
                                    <label class="label-name" for="billingState">State/Province/Region</label>
                                    <ng-select appendTo="body" placeholder="Select" [multiple]="false"
                                        [(ngModel)]="practiceModelDto.billingState" formControlName="billingState"
                                        [searchable]="true" [clearable]="false">
                                        <ng-option *ngFor="let state of billingStates" [value]="state.abbreviation">
                                            {{state.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!billingStates">
                                <div class="form-modal-group" [ngClass]="displayFieldCss('billingState')">
                                    <label class="label-name" for="billingState">State/Province/Region</label>
                                    <input type="text" nbInput fullWidth fieldSize="small" placeholder=""
                                        formControlName="billingState" [(ngModel)]="practiceModelDto.billingState">
                                </div>
                            </ng-container>
                            <div class="billing-owner">
                                <label class="label-name" for="billing-owner">Billing Owner</label>
                                <ng-select appendTo="body" [loading]="isBillingUsersLoading" placeholder="Select User"
                                    [multiple]="false" [(ngModel)]="practiceModelDto.billingOwnerId" formControlName="billingOwnerId"
                                    [searchable]="true" [clearable]="true">
                                    <ng-option *ngFor="let user of billingUsers" [value]="user.id">
                                        {{user.fullName}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="line"></div>

                <div class="row m-0 w-100">
                    <div class="col-6 pl-0 ">
                        <div [ngClass]="displayFieldCss('locationRouteId')">
                            <label class="label-name" for="locationRouteId">Location Route</label>
                            <ng-select appendTo="body" [loading]="isLocationListLoading"
                                [(ngModel)]="practiceModelDto.locationRouteId" formControlName="locationRouteId"
                                id="locationRouteId" type="text" placeholder="Select route"
                                (keyup)="getLocationRouteSearch($event.target.value)" [searchable]="true"
                                [clearable]="false">
                                <ng-option [value]='0' disabled>None</ng-option>
                                <ng-option *ngFor="let route of locationRouteListDto"
                                    [value]="route.id">{{route.name}}</ng-option>
                            </ng-select>
                            <app-field-error-display [displayError]="isFieldValid('locationRouteId')"
                                errorMsg="Please inform location route">
                            </app-field-error-display>
                        </div>

                        <div [ngClass]="displayFieldCss('priceListId')">
                            <label class="label-name" for="priceListId">PriceList</label>
                            <ng-select appendTo="body" [loading]="isPriceListLoading"
                                [(ngModel)]="practiceModelDto.priceListId" formControlName="priceListId"
                                id="priceListId" type="text" placeholder="Select priceList" [searchable]="true"
                                [clearable]="false">
                                <ng-option [value]='0' disabled>None</ng-option>
                                <ng-option *ngFor="let pricelist of pricelistsDto"
                                    [value]="pricelist.id">{{pricelist.name}}</ng-option>
                            </ng-select>
                        </div>

                        <div class="form-modal-group " [ngClass]="displayFieldCss('phone')">
                            <label class="label-name" for="name">Phone</label>
                            <ngx-intl-tel-input [(ngModel)]="phoneNubmer" [cssClass]="'phone-number-input'"
                                [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryName" [maxLength]="15"
                                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone">
                            </ngx-intl-tel-input>



                            <!-- <input [(ngModel)]="practiceModelDto.phone" formControlName="phone" id="phone" type="text"
                                nbInput fullWidth fieldSize="small" placeholder=" +353 1 344 1111"> -->
                            <app-field-error-display [displayError]="isFieldValid('phone')"
                                errorMsg="Please inform phone">
                            </app-field-error-display>
                        </div>
                    </div>
                </div>
             
                <div class="pt-2">
                    <span class="group-title">Step 2: Assign Doctor to this Practice</span>
                
                    <div class="form-modal-group  pt-2" >
                        <ngx-assing-clients-controle [isEdit]="isEdit" [practiceNew]="practiceModelDto" (newItemEventDentist)="setupDentistList($event)"
                        [companyId]="companyId" [practice]="practice"> </ngx-assing-clients-controle>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="line"></div>
            <div class="close-modal-container justify-content-between align-items-center">

                <div class="" *ngIf="isEdit">
                    <button (click)="archivePractice()" class="great-button-danger" *ngIf="!practice.isArchived" nbButton status="danger"
                        size="small">
                        Remove Practice
                    </button>
                    <button (click)="deArchivePractice()" class="great-button-danger" *ngIf="practice.isArchived" nbButton status="danger"
                        size="small">
                        Unarchive Practice
                    </button>
                </div>

                    <!-- <div class="delete-account" *ngIf="isEdit" (click)="deletePractice()">
                    <nb-icon icon="trash-icon-greatlab" pack="great-pack"></nb-icon>
                    Delete Practice
                </div> -->
                <div class="">
                    <button shape="semi-round" (click)="close()" class="btn-shadow mr-2 close-button-size" nbButton
                    size="small" status="basic">
                    Close
                </button>
                <button *ngIf="!isEdit" shape="semi-round" (click)="onSubmit()" nbButton status="primary" size="small"
                    class="save-button-size">
                    Create
                </button>
                <button *ngIf="isEdit" shape="semi-round" (click)="onUpdate()" nbButton status="primary" size="small"
                    class="save-button-size">
                    Update
                </button>
                </div>  
            </div>
        </nb-card-footer>
    </nb-card>
</form>