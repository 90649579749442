<form class="form-horizontal" [formGroup]="form">
    <nb-card class="modal-card">
        <nb-card-header>
            <div class="close-modal-icon" (click)="close()">
                <img src="../../../../assets/images/icons/cancel.svg" alt="">
            </div>
            <div class="modal-title text-left">Create Group</div>
        </nb-card-header>
        <nb-card-body>

            <!-- firstName: [null, Validators.required],
            dateOfBirth: [null, [Validators.required, ]],
            gender: [null, [Validators.required, ]], -->



            <div class="create-form-body">
                <div class="form-modal-group" [ngClass]="displayFieldCss('name')">
                    <label class="label-name" for="name">Group Name </label>
                    <input [(ngModel)]="groupName" formControlName="name" id="name"
                        type="text" nbInput fullWidth fieldSize="small">
                    <app-field-error-display [displayError]="isFieldValid('name')"
                        errorMsg="Please inform your name">
                    </app-field-error-display>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="close-modal-container justify-content-between align-items-center">
                <button shape="semi-round" (click)="close()" class="btn-shadow mr-2 close-button-size" nbButton
                    size="small" status="basic">
                    Close
                </button>
                <button shape="semi-round" (click)="onSubmit()" nbButton status="primary" size="small"
                    class="save-button-size">
                    Create
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</form>