


import { Pipe, PipeTransform } from '@angular/core';
 

@Pipe({name: 'filterByReceivingType'})


export class FilterByReceivingTypePipe implements PipeTransform {
 
  transform(items: any[], type: string): any[] {
    if (!items) return [];
    if (type === null) return []; 
    if (!type) return items;
    debugger
    return items.filter(item => item.type === type);
  }

}
 