import {
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    ViewChild,
  } from '@angular/core';
  import { CustomSelectComponent } from './custom-select.component';
  import { CustomSelectService } from './custom-select.service';
  
  @Component({
    selector: 'custom-select-option',
    template: `<span #option><ng-content ></ng-content></span>`,
    styleUrls: ['./custom-select-option.component.scss'],
    host: {
      role: 'listbox',
      '[attr.aria-label]': 'value',
    },
  })
  export class CustomSelectOptionComponent {
    @Input()
    public value!: any;
  
    @HostBinding('class.disabled')
    @Input()
    public disabled = false;
  
    private select: CustomSelectComponent;
  
    @ViewChild('option')
    private option!: ElementRef;
  
    constructor(private dropdownService: CustomSelectService) {
      this.select = this.dropdownService.getSelect();
    }
  
    @HostListener('click', ['$event'])
    public onClick(event: UIEvent): void {
      event.preventDefault();
      if (!this.disabled) {
        this.select.selectOption(this);
      }
    }
  
    public getOptionElement(): any {
      return this.option.nativeElement;
    }
  }
  