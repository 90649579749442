import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbInputModule,
  NbFormFieldModule,
  NbCardModule,
  NbPopoverModule,
  NbSpinnerModule,
  NbListModule,
  NbCheckboxModule,
  NbToggleModule,
  NbTabsetModule,
  NbRadioModule,
  NbTooltipModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  FilterOrderTypePipe,
  SearchFilterPipe,
  FilterPipe,
  SafePipe,
  OrderByPipe,
  CutTextPipe,
  AddSpacePipe,
  FilterProductPipe,
  OrderFilterPipe,
  FilterCalendarDataPipe,
  FilterByTeethPipe,
  StlFilePipe,
  FilterByReceivingTypePipe
} from './pipes';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { MainLayoutComponent } from './layouts';
import { RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth/auth.layout';
import { FieldErrorDisplayComponent } from './helpers/field-error-display/field-error-display.component';
import { DeleteModalApproveComponent } from './helpers/delete-modal-approve/delete-modal-approve.component';
import { UploadPhotosByOrderComponent } from './helpers/upload-photos-by-order/upload-photos-by-order.component';
import { OrderSumbitOrderModalComponent } from './helpers/order-sumbit-order-modal/order-sumbit-order-modal.component';
import { OrderChangeStatusModalComponent } from './helpers/order-change-status-modal/order-change-status-modal.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FindOrderByBarcodeComponent } from './helpers/find-order-by-barcode/find-order-by-barcode.component';
import { GenericBarcodeComponent } from './helpers/generic-barcode/generic-barcode.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxPrintModule } from 'ngx-print';
import { MultiselectTeethControlComponent } from './helpers/multiselect-teeth-control/multiselect-teeth-control.component';
import { UploadFilesComponent } from './helpers/upload-files/upload-files.component';
import { ChangeTagByOrderComponent } from './helpers/change-tag-by-order/change-tag-by-order.component';
import { DateRangePickerComponent } from './helpers/date-range-picker/date-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ConfettiAnimationComponent } from './helpers/confetti-animation/confetti-animation.component';
import { SeatchPipe } from './pipes/search.pipe';
import { DentistInstructionsComponent } from './helpers/dentist-instructions/dentist-instructions.component';
import { TreatmentInstructionsComponent } from './helpers/treatment-instructions/treatment-instructions.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import {
  FormsModule as ngFormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { NewStageModalComponent } from './helpers/new-stage-modal/new-stage-modal.component';
import { IprInstructionModalComponent } from './helpers/treatment-instructions/ipr-instruction-modal/ipr-instruction-modal.component';
import { TreatmentTeethWithPinsComponent } from './helpers/treatment-instructions/teeth-with-pins/teeth-with-pins.component';
import { TreatmentTeethWithoutPinsComponent } from './helpers/treatment-instructions/teeth-without-pins/teeth-without-pins.component';
import { VoiceRecorderComponent } from './helpers/voice-recorder/voice-recorder.component';
import { AudioRecordingService } from './helpers/voice-recorder/audio-recording.service';
import { QuantityControlComponent } from './helpers/quantity-control/quantity-control.component';
import { StickerBarCodeComponent } from './helpers/sticker-bar-code/sticker-bar-code.component';
import { FileDragNDropDirective } from './directives/file-drag-n-drop/file-drag-n-drop.directive';
import { DoingBlinkingIconComponent } from './helpers/doing-blinking-icon/doing-blinking-icon.component';
import { ElapsedTimePipe } from './pipes/statistics-spent-time.pipe';
import { ElapsedTimeClockComponent } from './helpers/elapsed-time-clock/elapsed-time-clock.component';
import { FilterByCategoruesTurnOffPipe } from './pipes/filter-by-categorues-turn-off.pipe';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard/copy-to-clipboard.derective'
import { CheckPermissionDirective } from './directives/permission/check-permission'
import { ScrollIntoViewDirective } from './directives/scroll-view/scroll-into-view.directive';
import { DynamicToAddComponent } from './helpers/popover-dynamic/dynamic.components'
import { NgxPaginationModule } from 'ngx-pagination';
import { TutorialModalComponent } from './helpers/tutorial-modal/tutorial-modal.component';
import { TeethControlComponent } from './helpers/teeth-control/teeth-control.component';
import { OrderSelectOrthodonticColoursComponent } from './helpers/order-select-orthodontic-colours/order-select-orthodontic-colours.component';
import { OrthodonticProductColorsComponent } from './helpers/orthodontic-product-colors/orthodontic-product-colors.component';
import { SimpleOrderSelectOrthodonticColoursComponent } from './helpers/simple-order-select-orthodontic-colours/simple-order-select-orthodontic-colours.component';
import { SearchFilterAllPipe } from './pipes/search-filter-all.pipe';
import { HotglueWidgetComponent } from './helpers/hotglue-widget/hotglue-widget.component';
import { StlModelViewerModalComponent } from './helpers/stl-model-viewer-modal/stl-model-viewer-modal.component';
import { StlModelViewerModule } from 'angular-stl-model-viewer';
import { ManualInvoiceModalComponent } from './helpers/manual-invoice-modal/manual-invoice-modal.component';
import { PrintOrderDocketComponent } from './dockets/print-order-docket/print-order-docket.component';
import { PrintInvoiceDocketComponent } from './dockets/print-invoice-docket/print-invoice-docket.component';
import { PrintStatmentDocketComponent } from './dockets/print-statment-docket/print-statment-docket.component';
import { CustomSelectOptionComponent } from './helpers/custom-select/custom-select-option.component';
import { CustomSelectComponent } from './helpers/custom-select/custom-select.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { A11yModule } from '@angular/cdk/a11y';
import { ImpressionsReceivedSelectComponent } from './helpers/impressions-received-select/impressions-received-select.component';
import { ToggleSwitchButtonComponent } from './helpers/toggle-switch-button/toggle-switch-button.component';
import { PracticeGroupModalComponent } from './helpers/practice-group-modal/practice-group-modal.component';
import { InternalNotesComponent } from './components/internal-notes/internal-notes.component';
import { ChangeLocationComponent } from './helpers/change-location/change-location.component';
const NB_MODULES = [
  StlModelViewerModule,
  RouterModule,
  NbLayoutModule,
  NbMenuModule,
  NgxBarcodeModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  CommonModule,
  // DragDropModule,
  NbSelectModule,
  ngFormsModule,
  NbTooltipModule,
  FormsModule,
  ReactiveFormsModule,
  NbIconModule,
  NbInputModule,
  NbToggleModule,
  NbFormFieldModule,
  NbCardModule,
  NbEvaIconsModule,
  FormsModule,
  NbListModule,
  NbRadioModule,
  NbSpinnerModule,
  NgSelectModule,
  NbPopoverModule,
  MatChipsModule,
  MatSliderModule,
  NgxPrintModule,
  NgxDaterangepickerMd.forRoot(),
  NbCheckboxModule,
  NbTabsetModule,
  NgxPaginationModule,

  OverlayModule,
  PortalModule,
  A11yModule,
];
const COMPONENTS = [
  PracticeGroupModalComponent,
  ToggleSwitchButtonComponent,
  ManualInvoiceModalComponent,
  StlModelViewerModalComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  MainLayoutComponent,
  AuthLayoutComponent,
  DeleteModalApproveComponent,
  OrderSumbitOrderModalComponent,
  OrderChangeStatusModalComponent,
  ImpressionsReceivedSelectComponent,
  FieldErrorDisplayComponent,
  UploadPhotosByOrderComponent,
  VoiceRecorderComponent,
  ScrollIntoViewDirective,
  CopyToClipboardDirective,
  CheckPermissionDirective,
  FileDragNDropDirective,
  TreatmentTeethWithoutPinsComponent,
  TreatmentTeethWithPinsComponent,
  IprInstructionModalComponent,
  DoingBlinkingIconComponent,
  ElapsedTimeClockComponent,
  DynamicToAddComponent,
  TutorialModalComponent,
  TeethControlComponent,
  OrderSelectOrthodonticColoursComponent,
  OrthodonticProductColorsComponent,
  SimpleOrderSelectOrthodonticColoursComponent,
  PrintStatmentDocketComponent,
  PrintOrderDocketComponent,
  PrintInvoiceDocketComponent,
  CustomSelectComponent,
  CustomSelectOptionComponent,
  NewStageModalComponent,
  TreatmentInstructionsComponent,
  DentistInstructionsComponent,
  FindOrderByBarcodeComponent,
  GenericBarcodeComponent,
  MultiselectTeethControlComponent,
  UploadFilesComponent,
  ChangeTagByOrderComponent,
  DateRangePickerComponent,
  ConfettiAnimationComponent,
  QuantityControlComponent,
  StickerBarCodeComponent,
  DoingBlinkingIconComponent,
  ElapsedTimeClockComponent,
  HotglueWidgetComponent,
  NewStageModalComponent,
  TreatmentInstructionsComponent,
  DentistInstructionsComponent,
  QuantityControlComponent,
  GenericBarcodeComponent,
  MultiselectTeethControlComponent,
  UploadFilesComponent,
  ChangeTagByOrderComponent,
  DateRangePickerComponent,
  ConfettiAnimationComponent,
  StickerBarCodeComponent,
  ChangeLocationComponent,
  InternalNotesComponent
];
const PIPES = [
  StlFilePipe,
  SeatchPipe,
  FilterPipe,
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  FilterOrderTypePipe,
  SearchFilterPipe,
  SafePipe,
  OrderByPipe,
  CutTextPipe,
  ElapsedTimePipe,
  FilterByCategoruesTurnOffPipe,
  FilterProductPipe,
  OrderFilterPipe,
  AddSpacePipe,
  SearchFilterAllPipe,
  FilterCalendarDataPipe,
  FilterByTeethPipe,
  FilterByReceivingTypePipe
];

@NgModule({
  providers: [AudioRecordingService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, ],
  entryComponents: [DynamicToAddComponent, TutorialModalComponent, DeleteModalApproveComponent, OrderSumbitOrderModalComponent, OrderChangeStatusModalComponent, ChangeTagByOrderComponent]
  
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers,
      ],
    };
  }
}
