


export const IMPRESSIONS: any[] = [
    { icon: null, name: "Standard Impressions", id: 1, },
    { icon: null, name: "3Shape Communicate", id: 2, },
    { icon: null, name: "ITero", id: 3, },
    { icon: null, name: "CareStream", id: 4, },
    { icon: null, name: "MeditLink", id: 5, },
    { icon: null, name: "Shinning", id: 6, },
    { icon: null, name: "3M True Definition", id: 7, },
    { icon: null, name: "Dexis", id: 8, },
    { icon: null, name: "Dental Wings", id: 9, },
    { icon: null, name: "Cerec", id: 10, },
    { icon: null, name: "Planmeca", id: 11, },
    { icon: null, name: "Primescan", id: 12, },
    { icon: null, name: "Condor Flash", id: 13, },
    { icon: null, name: "Panda Scanner", id: 14, },
    { icon: null, name: "Last Aligner Model", id: 15, }
]
  // { icon: null, id: 1, name: 'Standard Impressions' },
    // { icon: null, id: 3, name: 'Great Scanner' },
    // { icon: null, id: 4, name: 'CEREC' },
    // { icon: null, id: 5, name: 'TRIOS' },
    // { icon: null, id: 6, name: '3M TRUE Definition' },
    // { icon: null, id: 7, name: 'CareStream' },
    // { icon: null, id: 8, name: 'Dental Wings' },
    // { icon: null, id: 9, name: 'iTero STL' },
    // { icon: null, id: 10, name: '3Shape Communicate' },
    // { icon: null, id: 11, name: 'Medit Link' },
    // { icon: null, id: 12, name: 'Last Aligner Model' },