import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './@core/guards/auth.guard';
import { PrintTemplateComponent } from './print-template/print-template.component';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';

export const routes: Routes = [
  {
    path: "print",
    outlet: "print",
    component: PrintTemplateComponent,
    children: [
      { path: "invoice/:invoiceIds", component: InvoiceTemplateComponent },
    ]
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
      canActivate: [AuthGuard], 
  },
  {
    path: '3shape',
    loadChildren: () => import('./pages/shape-redirect/shape-redirect.module')
      .then(m => m.ShapeRedirectnModule),
  },
  {
    path: 'xero',
    loadChildren: () => import('./pages/xero-redirect/xero-redirect.module')
      .then(m => m.XeroRedirectnModule),
  },

  {
   path: '',
   loadChildren: () => import('./auth/auth.module')
   .then(m => m.NgxAuthModule),
  },
  
  { path: '', redirectTo: 'pages/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/dashboard' },
  { path: '*', redirectTo: 'pages/dashboard' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
