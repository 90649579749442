<section class="header-conatiner">
  <div class="container">
    <div class="justify-content-between row m-0 w-100">
      <div class="col-4">
        <div class="great-logo-container" routerLink="/pages/dashboard">
          <img [src]="user.logo? user.logo : './assets/images/greatlab-logo.png'" alt="logo">
          <!-- <ngx-change-location> </ngx-change-location> -->
        </div>
      </div>

      <div class="col-4">
        <div class="great-search-container">
          <nb-form-field>
            <nb-icon nbPrefix icon="search-outline" pack="eva" class="secondary-color"></nb-icon>
            <input  (change)="searchBar($event.target.value)" type="text" nbInput fullWidth fieldSize="small" placeholder="Search..." shape="round">
          </nb-form-field>
        </div>
      </div>

      <div class="col-4">
        <div class="great-header-menu-container">
          <div class="actions-list">
         
            <a  *ngIf="user.userroles !== 'Doctor' && user.userroles !== 'Manager'" 
             target="_blank"  class="action-item" data-canny-link href="https://greatlab.canny.io/feature-requests ">
              <!-- <img class="action-item-icon" src="./assets/images/icons/light-bulb.svg" alt="help"> -->
              <div class="action-item-label">Request Feature</div>
            </a>
            <div
            *ngIf="user.userroles !== 'Doctor' && user.userroles !== 'Manager'"
            (click)="openChat()" class="action-item icon-border"  nbTooltip="Help" nbTooltipPlacement="bottom">
              <img class="action-item-icon" src="./assets/images/icons/help.svg" alt="help">
              <!-- <div class="action-item-label">Help</div>   -->
            </div>
            <div class="action-item icon-border"  nbTooltip="Updates" nbTooltipPlacement="bottom"
             *ngIf="user.userroles != 'Doctor' && user.userroles != 'Manager'">
              <img class="action-item-icon" src="./assets/images/icons/notifications.svg" alt="notifications">
            </div>
            <div *checkPermission="'IsSettingsOff'">
              <div class="action-item icon-border"    nbTooltip="Settings" nbTooltipPlacement="bottom"
              *ngIf="user.userroles !== 'Doctor' && user.userroles !== 'Manager'" routerLink="/pages/settings">
                <img class="action-item-icon" src="./assets/images/icons/settings.svg" alt="settings">
              </div>
            </div>
            
            <div class="action-item icon-border"   nbTooltip="Profile" nbTooltipPlacement="bottom">
              <img [nbPopover]="profileTemplateRef" nbPopoverPlacement="bottom" class="action-item-icon"
                src="./assets/images/icons/person.svg" alt="person">
              <div>
                <div class="show-details-action">
                  <ng-template #profileTemplateRef>
                    <div class="popover-section">
                      <div class="popover-group">
                        <div class="name-title">
                          {{user.username}}
                        </div>
                        <hr>
                        <div (click)="logout()" class="popover-item">
                          Log out
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="header-menu">
  <div class="container">
    <div class="row w-100 m-0">
      <div class="col-9 ">
        <div class="navigation-container">
          <div class="navigation-list">
            <a *ngIf="isWelocme && user.userroles == 'LocationAdmin' " class="navigat-item position-relative" routerLink="/pages/welcome" routerLinkActive="active">
              Welcome
            </a>
            <a *checkPermission="'IsDashboardOff'"  class="navigat-item position-relative" routerLink="/pages/dashboard" routerLinkActive="active">
              Dashboard
              <div *ngIf="user.userroles == 'Doctor' || user.userroles == 'Manager' && countInboxDoctor !== 0" class="badge-danger-notify">
                {{countInboxDoctor}}</div>
            </a>
          
            <a *checkPermission="'IsOrdersOff'" class="navigat-item" routerLink="/pages/orders" routerLinkActive="active">
              Orders
            </a>
            <!-- account-holders -->
            <div *checkPermission="'IsDoctorsOff'">
              <a  *ngIf="user.userroles !== 'Doctor' && user.userroles !== 'Manager'" class="navigat-item" routerLink="/pages/doctors/list"
              routerLinkActive="active">
              Doctors
            </a>
            </div>
         
     
            <a *checkPermission="'IsBillingOff'" class="navigat-item" routerLink="/pages/billing"  routerLinkActive="active">
              Billing
            </a>
            <div *checkPermission="'IsWorkbenchOff'">
              <a   *ngIf="user.userroles !== 'Doctor' && user.userroles !== 'Manager'" class="navigat-item" routerLink="/pages/workbench" routerLinkActive="active">
                Workbench
              </a>
            </div>
           
            <a *ngIf="user.userroles == 'Doctor' || user.userroles == 'Manager'" class="navigat-item" routerLink="/pages/patients"
              routerLinkActive="active">
              Patients
            </a>

            <!-- <a class="navigat-item position-relative"
            routerLink="/pages/scunhub/list" routerLinkActive="active">
            Scanhub
          </a> -->
            <div *checkPermission="'IsInboxOff'">
              <a  *ngIf="user.userroles !== 'Doctor' && user.userroles !== 'Manager' && user.userroles !== 'Admin'" class="navigat-item position-relative"
              routerLink="/pages/inbox" routerLinkActive="active">
              Inbox
              <div *ngIf="countInbox !== 0" class="badge-danger-notify">{{countInbox}}</div>
            </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="header-menu-actions">
          <div class="actions" *ngIf="showButton && ( user.userroles !== 'Doctor' && user.userroles !== 'Manager')">
            <ngx-toggle-switch-button [toggleVal]="isOrderFlowType" (outPutEvents)="orderFlowType($event)"> </ngx-toggle-switch-button>
          </div>
          <button *ngIf="user.userroles !== 'Admin'" (click)="findOrder()" class="btn-shadow" nbButton size="small"
            status="basic">
            <nb-icon class="custom-icon" pack="great-pack" icon="find-order"></nb-icon>
            Find order
          </button>
        </div>
      </div>
    </div>
  </div>
</section>