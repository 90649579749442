import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbDialogRef, NbDialogService, NbIconConfig, NbToastrService } from '@nebular/theme';
import { finalize } from 'rxjs/operators';
import { UserInviteDTO, ProductCreateModel, ProductCategoryModel, ProductSubcategoryModel, ProductMaterialModel, CompanyModel, LocationModel, AuthServiceProxy, ProductServiceProxy, CompanyServiceProxy, LocationServiceProxy, CompanyModelPagedListDto, LocationModelPagedListDto, UserConfirmationLinkResult, PracticeServiceProxy, UserNamePhoneModel, ResultMessage, PracticeModelPagedListDto, PracticeModel, UserInviteNewDTO, UserInvitePractices, PracticeAcessModel, CountryModel } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from '../../../@core/service';
import { ModalCreateMemberComponent } from '../../settings/modal-create-member/modal-create-member.component';
import { DeleteAccountHolderComponent } from '../delete-account-holder/delete-account-holder.component';
import { ModalGenInviteLinkComponent } from '../../settings/modal-gen-invite-link/modal-gen-invite-link.component';
import { log } from 'console';
import { DeleteModalApproveComponent } from '../../../@theme/helpers/delete-modal-approve/delete-modal-approve.component';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { AssignmentsModalEditComponent } from '../assignments-modal-edit/assignments-modal-edit.component';

function createValidation(edit: any,locations): ValidatorFn {
  if (edit?.id) {
    return Validators.nullValidator;
  } else if (locations) {
    return Validators.nullValidator;
  } else {
    return Validators.required
  }
}
@Component({
  selector: 'ngx-add-doctor-modal',
  templateUrl: './add-doctor-modal.component.html',
  styleUrls: ['./add-doctor-modal.component.scss']
})
export class AddDoctorModalComponent implements OnInit {

 

  errorMessage: string = '';
  selectedPractice: any;
  practiceAssign: any[] = [];

  performerNumber: any;
  order: string = 'all';
  patient: string = 'all';
  tmpaccountCode: string = '';
  tmpcreditLimit: string = '';
  permissionList = [
    { value: 'own', label: 'Own' },
    { value: 'all', label: 'All' },
  ];

  onChangeAssign() {
    if (this.userModelDto.userRole == 'Manager') {
      this.addAssignModel();
    }
  }

  addAssignModel() { 
    if (this.edit?.id) {
      if (this.userModelDto.practiceIds.find((x: any) => x.id == this.selectedPractice)) {
        const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
        this.toastrService.danger('', "Client already added", { icon: iconConfig });
        return false;
      }
      let practiceAssign: any = {};
      practiceAssign.practiceName = this.practicesDto.find(x => x.id === this.selectedPractice).name;
      practiceAssign.practiceId = this.selectedPractice;
      practiceAssign.name = this.practicesDto.find(x => x.id === this.selectedPractice).name;
      practiceAssign.role = this.order == 'all' ? "Principal" : 'Solo';
      practiceAssign.isOwnOrders = this.order == 'all' ? false : true;
      practiceAssign.isOwnPatients = this.patient == 'all' ? false : true;
      practiceAssign.idhPerformerNumber = this.performerNumber;
      practiceAssign.accountCode = this.tmpaccountCode;
      practiceAssign.creditLimit = this.tmpcreditLimit;
      this.practiceAssign.push(practiceAssign);

      let item: PracticeAcessModel = new PracticeAcessModel();
      item.isOnlyOwnOrdersAcess = this.order == 'all' ? false : true;
      item.isOnlyOwnPatientsAcess = this.patient == 'all' ? false : true;
      item.idhPerformerNumber = this.performerNumber;
      item.accountCode = this.tmpaccountCode;
      item.creditLimit = this.tmpcreditLimit;
      item.practiceId = this.selectedPractice;
      item.userId = this.edit.id;
      debugger
        this._authService.addAcessToPractice(item).pipe(
          finalize(() => {
          }))
          .subscribe(
            (resp: any) => {
              this.selectedPractice = null;
              this.userModelDto.practiceIds.push(practiceAssign);
              
            },
            (error: any) => { }
          )
    } else {
      if (this.practiceAssign.find(x => x.practiceId == this.selectedPractice)) {
        const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
        this.toastrService.danger('', "Client already added", { icon: iconConfig });
        return false;
      }
     let practiceAssign: any = {};
     practiceAssign.practiceName = this.practicesDto.find(x => x.id === this.selectedPractice).name;
     practiceAssign.practiceId = this.selectedPractice;
     practiceAssign.isOwnOrders = this.order == 'all' ? false : true;
     practiceAssign.isOwnPatients = this.patient == 'all' ? false : true;
     practiceAssign.idhPerformerNumber = this.performerNumber;
     practiceAssign.accountCode = this.tmpaccountCode;
     practiceAssign.creditLimit = this.tmpcreditLimit;
     this.practiceAssign.push(practiceAssign);
     this.selectedPractice = null;
  
  
    }


    


 



  }


  unArchivedAccount() {
    if (this.edit?.id) {
      this._authService.unarchiveUserById(this.edit.id).pipe(
        finalize(() => {
        }))
        .subscribe(
          (resp: any) => {
            this.dialogRef.close(true);
          },
          (error: any) => { }
        )
    } else {
    }
  }

  removeClients(practice: any) {
    console.log(practice);
    
    if (this.edit?.id) {
      let item: PracticeAcessModel = new PracticeAcessModel();
      item.practiceId = practice.id;
      item.userId = this.edit.id;
      this._authService.removeAcessToPractice(item).pipe(
        finalize(() => {
        }))
        .subscribe(
          (resp: any) => {
            if (resp.result) {
              this.userModelDto.practiceIds = this.userModelDto.practiceIds.filter(x => x !== practice);
              const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
              this.toastrService.success('',resp.message, { icon:iconConfig}); 
            } else {
              const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
              this.toastrService.danger('',resp.message, { icon:iconConfig}); 
            }
          },
          (error: any) => { }
        )
    } else {
      this.practiceAssign = this.practiceAssign.filter(x => x !== practice);
    }
  }


  selectedCountryName: any = 'gb';
  countries:any[] = [];
  changePhoneNumCountry() {
    console.log(this.edit);
    
    let currentLocation: any = this.locationListDto.find(x => x.id == this.locationId);
    let code = this.countries.find(x => x.countryName == currentLocation.country)?.countryCode;
    this.selectedCountryName = code.toLowerCase();
  }

  loadCountries() {
    this._locationService.getAllCountries().pipe(
      finalize(() => {
      }))
      .subscribe(
        (result: CountryModel[]) => {
          this.countries = result;
          this.changePhoneNumCountry();
        },
        (error: any) => {
        }
      )
  }




  @Input() isStep: any;
  @Input() locationId?: any;
  @Input() companyId?: any;
  @Input() edit?: any = {id: null};
  form: FormGroup;
  inviteUrl: string = null;
  userModelDto: UserInviteNewDTO = new UserInviteNewDTO();
  productModelDto: ProductCreateModel = new ProductCreateModel();
  productCategoryDtoList: ProductCategoryModel[] = [];
  productSubcategoryDtoList: ProductSubcategoryModel[] = [];
  productMaterialDtoList: ProductMaterialModel[] = [];
  productStandardTierDtoList: ProductMaterialModel[] = [];

  companyListDto: CompanyModel[] = [];
  locationListDto: LocationModel[] = [];
  practicesDto: PracticeModel[] = [];
// bool 
isCompanyLoading = true;
isCategoryLoading: boolean = false;
isSubcategoryMaterialLoading: boolean = false;
isTierLoading: boolean = false;
isLocationLoading: boolean = false;
isPracticesLoading: boolean = false;

// other
searchCompany: string = '';
companyIdDto: string;
user: any = {};
isHideCompany: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthServiceProxy,
    private _practiceService: PracticeServiceProxy,
    private _companyService: CompanyServiceProxy,
    private toastrService: NbToastrService,
    private _locationService: LocationServiceProxy,
    private dialogService: NbDialogService,
      private _authenticationService: AuthenticationService,
    protected dialogRef: NbDialogRef<ModalCreateMemberComponent>,
  ) {

     

    
       let currentUser = this._authenticationService.currentUserValue;
        let helper = new JwtHelperService();
        this.user = helper.decodeToken(currentUser.accessToken);
        this.userModelDto.userRole = 'Doctor';
        if (this.user.userroles !== 'Admin') {
          this.userModelDto.companyId = this.user.companyId;
          this.getAllCompanyWithAdminRole('');
          this.getAllLocationWithAdminRole(this.user.companyId);
        } else {
          this.getAllCompanyWithAdminRole('');
        }

   }





    getAllCompanyWithAdminRole(searchCompany: any) {
      this.isCompanyLoading = true;
      this._companyService.getAll(1, 10, searchCompany).pipe(
        finalize(() => {
          this.isCompanyLoading = false;
        }))
        .subscribe(
          (resp: CompanyModelPagedListDto) => {
          this.companyListDto = resp.items;
          if (this.userModelDto.locationIds && this.userModelDto.companyId) {
            this.getAllLocationWithAdminRole(this.userModelDto.companyId);
          }
          },
          (error: any) => { }
        )
    }



    getAllLocationWithAdminRole(companyId: any) {
      this.locationId = null;
      this.isLocationLoading = true;
      this._locationService.getAll(companyId, 1, 999, '').pipe(
        finalize(() => {
          this.isLocationLoading = false;
        }))
        .subscribe(
          (resp: LocationModelPagedListDto) => {
          this.locationListDto = resp.items;
          if (this.locationListDto.length > 0) {
            this.locationId = this.locationListDto[0].id;
          }
          },
          (error: any) => { }
        )
    }

    // deleteAccount() {
      
    //   this.dialogRef.close(false);
    //   this.dialogService.open(DeleteAccountHolderComponent, {context: {account: this.edit}} )
    //   .onClose.subscribe((resp) => {
    //     if (resp) {
    
    //     }
    //   });
    // }
    onUpdate() {
       if (this.form.valid) {
        let accountInfo: UserNamePhoneModel = new UserNamePhoneModel();
        accountInfo.fullName =  this.userModelDto.fullName;
        accountInfo.id =  this.edit.id;
        accountInfo.email =  this.userModelDto.email;
        accountInfo.accountCode =  this.userModelDto.accountCode;
        accountInfo.phoneNumber = this.phoneNumbByObject?.nationalNumber? this.phoneNumbByObject.nationalNumber : '' ;
        accountInfo.phoneNumberCountryCode = this.phoneNumbByObject?.countryCode? this.phoneNumbByObject.countryCode : '' ;
      this._practiceService.updateAccountDetails(accountInfo).pipe(
        finalize(() => {
          this.isLocationLoading = false;
        }))
        .subscribe(
          (resp: ResultMessage) => {
             this.edit.dentistName = this.userModelDto.fullName;
             this.edit.userName = this.userModelDto.email;  
       
            if (!resp.result) {
              const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
              this.toastrService.danger('',resp.message, { icon:iconConfig}); 
            } else {
              this.dialogRef.close(this.edit);
            }
          },
          (error: any) => {
            const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
            this.toastrService.danger('',error.message, { icon:iconConfig}); 
           }
        )
    } else {
      this.validateAllFormFields(this.form);
    }
    }


  ngOnInit(): void {
    console.log(this.edit, 'this.edit');
    this.loadCountries();
    this.userModelDto.practiceIds = [];
    if (this.companyId) {
      this.userModelDto.companyId = this.companyId;
    }
    if (this.edit?.id) {
      this.userModelDto.userName = this.edit.userName;
      this.userModelDto.fullName = this.edit.dentistName;
      this.userModelDto.email = this.edit.email;
      this.userModelDto.userRole = this.edit.role;
      this.userModelDto.accountCode = this.edit.accountCode;
       this.userModelDto.companyId = this.edit.companyId;
      this.userModelDto.locationIds = [ ];
      this.userModelDto.practiceIds = this.edit.practicesRoles;
      this.phoneNumbByObject = this.edit.phone;
      this.selectedCountryName = this.edit.countryCode
      this.getAllPracticesWithLocation('', '');
    } else {
      if (this.locationId) {
        this.userModelDto.locationIds = this.locationId;
        this.getAllPracticesWithLocation('', '');
      }
    }

 

    // this._authService.sendEmailInvitation()
    this.form = this.formBuilder.group({
      companyId: [0 ,  Validators.required],
      name: [null, [Validators.required, ]],
      email: [null],
      phone: [null],
      userName : [null],
      locationIds: [null,  createValidation(this.edit, this.locationId)],
      practiceIds: [null],
      accountCode: [],
      order: [],
      performer: [],
      patient: [],
      rolename: [null, [Validators.required, ]],
      assignmentaccountCode: [],
      assignmentcreditLimit: [],
      
    });
  }
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  close() {
    this.dialogRef.close(false);
  }
  addMember() {
    this.dialogRef.close(true);

  }

  getAllPracticesWithLocation(e: any, search: any) {
      this.isPracticesLoading = true; 
      if (this.locationListDto?.length > 0 ) {
        let locationContry = this.locationListDto.find(x => x.id == this.locationId)
        let code = this.countries.find(x => x.countryName == locationContry.country).countryCode;
        this.selectedCountryName = code.toLowerCase();
      }
      this._practiceService.getAllPracticesByCompanyId(this.userModelDto.companyId).pipe(
        finalize(() => {
          this.isPracticesLoading = false;
        }))
        .subscribe(
          (resp: PracticeModelPagedListDto) => {
          this.practicesDto = resp.items;
        
          },
          (error: any) => { }
        )
  }
 
  checkUserName(){
    this._authService.checkUserName(this.userModelDto.userName).pipe(
      finalize(() => {}))
      .subscribe(
        (resp: ResultMessage) => {
          if(!resp.result){
            this.errorMessage = resp.message;
          } else {
            this.errorMessage = '';
          }
        },
       
      )
  }
  

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  closeLink() {
    this.dialogRef.close(true)
  }
  isLoading: boolean = false;
  onSubmit() {
    if (this.form.valid) {
      if (this.companyId) {
        this.userModelDto.companyId = this.companyId;
      }

      this.userModelDto.locationIds = [`${this.userModelDto.locationIds}`];
      this.isLoading = true;
      this.userModelDto.practiceIds = this.practiceAssign.map((x: UserInvitePractices) => {
        let data: UserInvitePractices = new UserInvitePractices(); 
        data.practiceId = x.practiceId;
        data.isOwnOrders = x.isOwnOrders;
        data.isOwnPatients = x.isOwnPatients;
        data.idhPerformerNumber = x.idhPerformerNumber;
        return data
      });
      this.userModelDto.phoneNumber = this.phoneNumbByObject?.nationalNumber? this.phoneNumbByObject.nationalNumber : '' ;
      this._authService.sendEmailInvitationNew(this.userModelDto).pipe(
        // this._authService.sendEmailInvitation(this.userModelDto).pipe(
        finalize(() => {
          this.isLoading = false;
        }))
        .subscribe(
          (resp: UserConfirmationLinkResult) => {
         
            if (this.isStep && this.userModelDto.practiceIds.length == 0) {
              let result = {
                status: true,
                account: resp.userId,
                locationId: this.userModelDto.locationIds,
                details: this.userModelDto,
                link: resp.link
              }
              this.dialogRef.close(result)
            } else {  
              let result = {
                link: resp.link
              }
              this.dialogRef.close(result);
              const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
              this.toastrService.success('','Success', { icon:iconConfig}); 
            }
          },
          (error: any) => {
            const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
            this.toastrService.danger('',error.Message, { icon:iconConfig}); 
           }
        )
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  getInviteLink(id: any) {
    this.dialogService.open(ModalGenInviteLinkComponent, {context: {
      
      isLink: false,
       userId: id,
       title: 'Invite link',
       desc: 'Share setup link with the client (Please note expires after 24 hours)'
    
    }});
  }


  deleteAccount() {
    this.dialogService.open(DeleteModalApproveComponent, {
      context: {
        headTitle: 'Are you sure ?',
        title: ``,
        btnText: 'Remove',
        btnClose: 'Close'
      }
    })
      .onClose.subscribe((resp) => {
        if (resp) {
            this._authService.archiveUserById(this.edit.id).pipe(
            finalize(() => {
            }))
            .subscribe(
              (resp: ResultMessage) => {
                if (resp.result) {
                  const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
                  this.toastrService.success('', "Clinet successfully archived", { icon:iconConfig});
                  this.dialogRef.close(true);
                } else {
                  const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                  this.toastrService.danger('',resp.message, { icon:iconConfig}); 
                }
              },
              (error: any) => { 
                const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                this.toastrService.danger('','Not archive', { icon:iconConfig}); 
              }
            )
        }
      });
  }
 


  phoneNumbByObject: any;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Ireland];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }




  editClients(item: any, type: any) {
    console.log(type, 'selectedPractice');
    let data: any = {};
    if (!item.id) {
      data.accountCode = item.accountCode;
      data.creditLimit = item.creditLimit;
      data.idhPerformerNumber = item.idhPerformerNumber;
      data.isOnlyOwnOrdersAcess = item.isOwnOrders;
      data.isOnlyOwnPatientsAcess = item.isOwnPatients;
      data.name = item.name;
    } else {
      data = item;
    }
    this.dialogService.open(AssignmentsModalEditComponent, {
      context: {
        userDto: this.edit,
        companyId: this.companyId,
        userModelDto: this.userModelDto,
        item: data,
        event: type
      }
    }).onClose.subscribe((resp) => {
      if (resp) {
      }
    });
  } 


}
