<div class="assign-section">
    <table  class="client-table-list" *ngIf="practiceAssignModelClientsList.length > 0">
        <colgroup>
            <col style="width:5%">
            <col style="width:40%">
            <col style="width:40%">
            <col style="width:15%">
        </colgroup>
        <thead>
            <tr>
                <th class="table-title" scope="col"></th>
                <th class="table-title" scope="col"></th>
                <th class="table-title" scope="col"> </th>
                <th class="table-title" scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="isEdit">
                <tr *ngFor="let client of practice.clients">
                    <td>
                        <img class="icon-client" src="../../../../assets/images/icons/user-icon-clients.svg" alt="">
                    </td>
                    <td class="client-label">{{client.fullName}} </td>
                    <td class="client-label"> {{client.role}}</td>
                    <td class="action-func" (click)="removeClients(client)">Remove</td>
                </tr>
            </ng-container>
            <ng-container *ngIf="!isEdit">
                <tr *ngFor="let client of practiceAssignModelClientsList">
                    <td>
                        <img class="icon-client" src="../../../../assets/images/icons/user-icon-clients.svg" alt="">
                    </td>
                    <td class="client-label">{{client.fullName}} </td>
                    <td class="client-label"> {{client.role}} ({{client.isOnlyOwnOrdersAcess ? 'Solo' : 'Principal'}})</td>
                    <td class="action-func" (click)="removeClients(client)">Remove</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="management-assign-clients">
        <div class="clients-container">
            <div class="clients-actions-box" (click)="showClistentControle = !showClistentControle">
                Add client  
                <img src="../../../../assets/images/icons/down-arrow-select.svg" alt="">
            </div>
            <div class="selected-popover-container" [ngClass]="{'active' : showClistentControle}">
                <div class="client-list">
                    <div class="head-table-search">
                        <nb-form-field class="w-100 search-input">
                            <nb-icon nbPrefix icon="search-outline" pack="eva"
                                class="secondary-color"></nb-icon>
                            <input (keyup)="searchDentist()" [(ngModel)]="searchInput" type="text" nbInput fullWidth fieldSize="medium"
                                placeholder="Search Clients" shape="round">
                        </nb-form-field>
                    </div>
                   
                    <div  class="clients-list-scroll" *ngIf="dentistList.length > 0"
                          [nbSpinner]="isDentistLoading" nbSpinnerStatus="primary">
                        <nb-radio-group [(ngModel)]="selectClientsId"  (valueChange)="selectClient($event)" >    
                            <nb-radio *ngFor="let dentist of dentistList"
                              [value]="dentist.userId">
                              {{ dentist.fullName }}
                              <small>{{dentist.roleName}}</small>
                            </nb-radio>
                          </nb-radio-group>
                    </div>
                  
                </div>
                <div class="permissions-list" *ngIf="selectClientsModel?.roleName == 'Doctor'">
                    <div class="radio-list-permission">
                        <div class="radio-item">
                            <div class="radio-label">
                                ORDER 
                            </div>
                            <nb-radio-group [(ngModel)]="order" class="radio-box-flex" name="order">
                                <nb-radio *ngFor="let option of permissionList" [value]="option.value">
                                    {{ option.value }}
                                </nb-radio>
                            </nb-radio-group>
                        </div>
                        <div class="radio-item">
                            <div class="radio-label">
                               PATIENT
                            </div>
                            <nb-radio-group [(ngModel)]="patient"  class="radio-box-flex" name="patient">
                                <nb-radio *ngFor="let option of permissionList" [value]="option.value">
                                    {{ option.value }}
                                </nb-radio>
                            </nb-radio-group>
                        </div>
                    </div>
                    <button nbButton size="small" fullWidth class="settings-bt-clinet" (click)="addAssignModel()">
                        Done
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>