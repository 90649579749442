import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngxFilterCalendarData'
})
export class FilterCalendarDataPipe implements PipeTransform {
  transform(data: any[], tagsSelected: any[], statusSelected: any[], routeSelected: any[]): any[] {
    if (!data) return [];

    let filteredData = [...data];

    // Filter by tags
    if (tagsSelected && tagsSelected.length > 0) {
      filteredData = filteredData.filter(item => {
        return tagsSelected.some(tag => item.tagList && item.tagList.includes(tag));
      });
    }

    // Filter by status
    if (statusSelected && statusSelected.length > 0) {
      filteredData = filteredData.filter(item => {
        return statusSelected.includes(Number(item.orderStatusId));
      });
    }

    // Filter by route
    if (routeSelected && routeSelected.length > 0) {
      filteredData = filteredData.filter(item => {
        return routeSelected.includes(item.locationRoutName);
      });
    }

    return filteredData;
  }
}
