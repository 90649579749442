import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../@core/service';
import { log } from 'console';
import { BillingServiceProxy, BillingStatementModel, InvoiceModel, LocationServiceProxy, ManualInvoiceResponseModel, OrderServiceProxy, PatientStatementModel } from '../../shared/service-proxies/event-service-proxy';
import { finalize } from 'rxjs/operators';
import { NgxPrintService, PrintOptions } from 'ngx-print';

@Component({
  selector: 'ngx-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
export class InvoiceTemplateComponent implements OnInit, AfterViewInit {
  private loadCounts = { orderDocket: false, invoiceDocket: false, statementDocket: false };
  invoiceIds: any;
  promises: Promise<any>[];
  manualModel: ManualInvoiceResponseModel = new ManualInvoiceResponseModel();
  statmentModel: PatientStatementModel = new PatientStatementModel();

  constructor(
     private route: ActivatedRoute,
     private printService: PrintService,
   
     private _orderService: OrderServiceProxy
     ) {
    this.invoiceIds = JSON.parse(route.snapshot.params["invoiceIds"]);
    
  }
  getManualInvoice() {
    this._orderService.createInvoiceForOrder(this.invoiceIds.orderId).pipe(
      finalize(() => {
      })
    ).subscribe(
      (resp) => {
        this.manualModel = resp;
      
      },
      (error: any) => {
        this.printService.errorPrint();
        console.error('Error fetching currency:', error);
      }
    );
  }
  ngAfterViewInit(): void {


  }
  ngOnInit() {
    // this.getInvoiceDetails().then((result: any) => {
    //   this.manualModel = result;
    // }).catch(error => {
    //   console.error("Error fetching invoice details:", error);
    // });
  }






  getInvoiceDetails(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._orderService.createInvoiceForOrder(this.invoiceIds.orderId).pipe(
        finalize(() => {
        })
      ).subscribe(
        (res: any) => {
          setTimeout(() => resolve(res), 1000);
        },
        (error: any) => {
          reject(error); 
        }
      );
    });
  }

 

  onOrderDocketLoad() {
    if (this.invoiceIds.isDocket) {
    this.loadCounts.orderDocket = true;
    this.checkAllLoaded();
    } else {
      this.loadCounts.orderDocket = true;
    }
  }

  onInvoiceDocketLoad() {
    if (this.invoiceIds.isInvoice) {
      this.loadCounts.invoiceDocket = true;
      this.checkAllLoaded();
    } else {
      this.loadCounts.invoiceDocket = true;
    }
  }

  onStatementDocketLoad() {
    if (this.invoiceIds.isStatment) {
      this.loadCounts.statementDocket = true;
      this.checkAllLoaded();
    } else {
      this.loadCounts.statementDocket = true;
    }
  }

  private checkAllLoaded() {
    if (!this.invoiceIds.isDocket) {
      this.loadCounts.orderDocket = true;
    }
      if (!this.invoiceIds.isInvoice) {
        this.loadCounts.invoiceDocket = true;
      }
       if (!this.invoiceIds.isStatment) {
        this.loadCounts.statementDocket = true;
       }
    if (this.loadCounts.orderDocket && this.loadCounts.invoiceDocket && this.loadCounts.statementDocket) {
      this.onAllDataReady();
    }
  }

  private onAllDataReady() {
    setTimeout(() => {
      // this.printDocket();
      this.printService.onDataReady();
    }, 2000); // Optional delay for any last-minute operations
  }



}
