<div class="d-flex justify-content-center teeth-section"   fxLayout="row">
  <div class="upper-arch d-flex justify-content-start flex-column">
    <div class="teeth-margin">
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

      xml:space="preserve"
      width="100%" height="100%" viewBox="0 0 250 200"
      (click)="clickItem($event)">
      <g>


        <path d="M30.5 169C40.9775 168.36 52.1619 177.228 50 187.5C48.1585 196.25 39.3601 200.705 30.5 199.5C20.7086 198.169 15 190 15 179.5C15 169 23.2024 169.446 30.5 169Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M37 138.5C46 139.5 53.1619 145.728 51 156C49.1585 164.75 42.8601 169.737 34 168.533C24.2086 167.202 20.5 162 20.5 148.5C20.5 135 29.7335 137.693 37 138.5Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M34.2757 104.173C39.9373 96.8467 59.5 112.634 59.5 124.551C59.5 134.008 50.9449 139.08 41.6328 137.806C27.4442 135.865 25 127 26.9187 120.038C28.8373 113.076 29 111 34.2757 104.173Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M64.9328 101.066C68.0893 95.5727 57.0417 83.4873 57.0417 83.4873C51.6468 77.6973 40.0473 79.3356 37.0508 86.7834C34.8407 92.2767 39.5762 100.261 45.9941 103.813C52.5692 107.452 61.7763 106.559 64.9328 101.066Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M68.1066 65.473C65.651 63.4387 57.6666 59.3702 51.7362 64.4558C44.8713 70.3429 50.1998 77.4048 55.9608 81.2385C60.8811 84.5126 68.6347 85.307 71.2751 81.2385C73.5148 77.7873 72.8099 69.3693 68.1066 65.473Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M77.0637 41.7789C72.4905 40.103 68.6685 41.1805 65.1986 44.5396C61.0158 48.5887 58.9836 54.5571 61.8086 58.8951C65.7637 64.9685 79.8887 69.9378 84.4087 64.9685C87.7988 61.2415 82.7137 43.8494 77.0637 41.7789Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M98.1016 30.7551C94.4584 28.1922 90.8272 28.2394 86.9668 30.3853C82.3135 32.9721 79.1056 37.7634 80.5743 42.2641C82.6305 48.5655 93.932 56.2985 99.101 52.9787C102.978 50.4888 102.603 33.9215 98.1016 30.7551Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M125.283 24.8231C124.756 21.741 119.49 21.741 111.591 22.2547C104.745 22.6998 100.067 25.362 100.532 29.4464C101.058 34.0696 107.106 48.2457 116.33 46.9119C126.173 45.4886 125.809 27.9053 125.283 24.8231Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M221 169C210.523 168.36 199.338 177.228 201.5 187.5C203.342 196.25 212.14 200.705 221 199.5C230.791 198.169 236.5 190 236.5 179.5C236.5 169 228.298 169.446 221 169Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M214.5 138.5C205.5 139.5 198.338 145.728 200.5 156C202.342 164.75 208.64 169.737 217.5 168.533C227.291 167.202 231 162 231 148.5C231 135 221.767 137.693 214.5 138.5Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M217.224 104.173C211.563 96.8467 192 112.634 192 124.551C192 134.008 200.555 139.08 209.867 137.806C224.056 135.865 226.5 127 224.581 120.038C222.663 113.076 222.5 111 217.224 104.173Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M186.567 101.066C183.411 95.5727 194.458 83.4873 194.458 83.4873C199.853 77.6973 211.453 79.3356 214.449 86.7834C216.659 92.2767 211.924 100.261 205.506 103.813C198.931 107.452 189.724 106.559 186.567 101.066Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M183.393 65.473C185.849 63.4387 193.833 59.3702 199.764 64.4558C206.629 70.3429 201.3 77.4048 195.539 81.2385C190.619 84.5126 182.865 85.307 180.225 81.2385C177.985 77.7873 178.69 69.3693 183.393 65.473Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M174.436 41.7789C179.01 40.103 182.831 41.1805 186.301 44.5396C190.484 48.5887 192.516 54.5571 189.691 58.8951C185.736 64.9685 171.611 69.9378 167.091 64.9685C163.701 61.2415 168.786 43.8494 174.436 41.7789Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M153.398 30.7551C157.042 28.1922 160.673 28.2394 164.533 30.3853C169.187 32.9721 172.394 37.7634 170.926 42.2641C168.869 48.5655 157.568 56.2985 152.399 52.9787C148.522 50.4888 148.897 33.9215 153.398 30.7551Z" stroke="#7A7A7A" stroke-width="2"/>
        <path d="M126.217 24.8231C126.744 21.741 132.01 21.741 139.909 22.2547C146.755 22.6998 151.433 25.362 150.968 29.4464C150.442 34.0696 144.394 48.2457 135.17 46.9119C125.327 45.4886 125.691 27.9053 126.217 24.8231Z" stroke="#7A7A7A" stroke-width="2"/>
      </g>
      <g>

        <path class="tooth" [attr.data-title]="'1_2'" [ngClass]="{'dp-selected': isSpaceChecked('1_2')}" d="M15.2715 156.748L18.4731 161.411C19.4109 162.777 19.0638 164.645 17.6978 165.583L13.0342 168.784C12.3782 169.235 11.5702 169.406 10.788 169.26L4.55167 168.101C2.92272 167.798 1.84765 166.232 2.15044 164.603L3.61244 156.738C3.91523 155.109 5.48122 154.034 7.11017 154.337L13.3465 155.496C14.1287 155.642 14.8212 156.092 15.2715 156.748Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'2_3'" [ngClass]="{'dp-selected': isSpaceChecked('2_3')}" d="M21.2583 125.418L24.0868 130.317C24.9152 131.752 24.4236 133.587 22.9887 134.415L18.0897 137.243C17.4007 137.641 16.5818 137.749 15.8133 137.543L9.68624 135.901C8.08584 135.473 7.1361 133.827 7.56492 132.227L9.63548 124.5C10.0643 122.899 11.7093 121.95 13.3097 122.378L19.4367 124.02C20.2053 124.226 20.8605 124.729 21.2583 125.418Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'3_4'" [ngClass]="{'dp-selected': isSpaceChecked('3_4')}" d="M31.9908 90.2769L34.5334 95.3302C35.2781 96.8102 34.682 98.6137 33.2019 99.3585L28.1487 101.901C27.438 102.259 26.6143 102.319 25.8588 102.07L19.8361 100.079C18.263 99.559 17.4092 97.8622 17.9292 96.2891L20.4398 88.6932C20.9597 87.1201 22.6565 86.2663 24.2297 86.7863L30.2524 88.7769C31.0078 89.0266 31.6332 89.5662 31.9908 90.2769Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'4_5'" [ngClass]="{'dp-selected': isSpaceChecked('4_5')}" d="M43.2845 67.7748L44.6338 73.2684C45.0291 74.8774 44.0451 76.5022 42.4361 76.8974L36.9425 78.2467C36.1698 78.4365 35.3534 78.3116 34.6728 77.8994L29.2471 74.6135C27.8299 73.7552 27.3768 71.9105 28.2351 70.4933L32.3793 63.6504C33.2376 62.2332 35.0823 61.7801 36.4995 62.6384L41.9252 65.9243C42.6058 66.3365 43.0947 67.0021 43.2845 67.7748Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'5_6'" [ngClass]="{'dp-selected': isSpaceChecked('5_6')}" d="M55.6318 49.1366L55.915 54.7864C55.998 56.4412 54.7238 57.8499 53.069 57.9329L47.4192 58.2161C46.6246 58.2559 45.8467 57.9785 45.2566 57.4447L40.5524 53.1897C39.3236 52.0782 39.2285 50.1811 40.3399 48.9523L45.7064 43.0193C46.8179 41.7906 48.715 41.6955 49.9438 42.8069L54.648 47.062C55.2381 47.5957 55.592 48.342 55.6318 49.1366Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'6_7'" [ngClass]="{'dp-selected': isSpaceChecked('6_7')}" d="M78.2985 28.5975L76.9329 34.087C76.5329 35.6949 74.9052 36.674 73.2973 36.2741L67.8078 34.9084C67.0357 34.7163 66.3715 34.2254 65.9614 33.5436L62.6915 28.1082C61.8375 26.6884 62.296 24.8451 63.7158 23.991L70.571 19.8671C71.9907 19.013 73.834 19.4716 74.6881 20.8914L77.9579 26.3268C78.3681 27.0086 78.4906 27.8254 78.2985 28.5975Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'7_8'" [ngClass]="{'dp-selected': isSpaceChecked('7_8')}" d="M99.5869 17.2313L96.953 22.2375C96.1815 23.7038 94.3675 24.2671 92.9012 23.4956L87.895 20.8617C87.1908 20.4912 86.6627 19.8562 86.4267 19.0964L84.5457 13.0385C84.0544 11.4562 84.9388 9.77518 86.5212 9.28385L94.1613 6.91152C95.7437 6.42019 97.4247 7.30463 97.916 8.88696L99.797 14.9448C100.033 15.7046 99.9574 16.5271 99.5869 17.2313Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'15_16'" [ngClass]="{'dp-selected': isSpaceChecked('15_16')}" d="M234.78 156.748L231.579 161.411C230.641 162.777 230.988 164.645 232.354 165.583L237.018 168.784C237.674 169.235 238.482 169.406 239.264 169.26L245.5 168.101C247.129 167.798 248.204 166.232 247.902 164.603L246.44 156.738C246.137 155.109 244.571 154.034 242.942 154.337L236.706 155.496C235.923 155.642 235.231 156.092 234.78 156.748Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'14_15'" [ngClass]="{'dp-selected': isSpaceChecked('14_15')}" d="M228.794 125.418L225.965 130.317C225.137 131.752 225.628 133.587 227.063 134.415L231.962 137.243C232.651 137.641 233.47 137.749 234.239 137.543L240.366 135.901C241.966 135.473 242.916 133.827 242.487 132.227L240.417 124.5C239.988 122.899 238.343 121.95 236.742 122.378L230.615 124.02C229.847 124.226 229.191 124.729 228.794 125.418Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'13_14'" [ngClass]="{'dp-selected': isSpaceChecked('13_14')}" d="M218.061 90.2769L215.519 95.3301C214.774 96.8102 215.37 98.6137 216.85 99.3584L221.903 101.901C222.614 102.259 223.438 102.319 224.193 102.07L230.216 100.079C231.789 99.559 232.643 97.8622 232.123 96.289L229.612 88.6932C229.092 87.12 227.395 86.2663 225.822 86.7862L219.8 88.7769C219.044 89.0266 218.419 89.5662 218.061 90.2769Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'12_13'" [ngClass]="{'dp-selected': isSpaceChecked('12_13')}" d="M206.768 67.7748L205.418 73.2683C205.023 74.8774 206.007 76.5021 207.616 76.8974L213.11 78.2467C213.882 78.4365 214.699 78.3116 215.379 77.8994L220.805 74.6135C222.222 73.7552 222.675 71.9105 221.817 70.4933L217.673 63.6504C216.814 62.2332 214.97 61.7801 213.553 62.6384L208.127 65.9243C207.446 66.3365 206.957 67.0021 206.768 67.7748Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'11_12'" [ngClass]="{'dp-selected': isSpaceChecked('11_12')}" d="M194.42 49.1366L194.137 54.7864C194.054 56.4412 195.328 57.8499 196.983 57.9328L202.633 58.2161C203.427 58.2559 204.205 57.9784 204.795 57.4447L209.5 53.1896C210.728 52.0782 210.824 50.1811 209.712 48.9523L204.346 43.0193C203.234 41.7906 201.337 41.6954 200.108 42.8069L195.404 47.062C194.814 47.5957 194.46 48.342 194.42 49.1366Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'10_11'" [ngClass]="{'dp-selected': isSpaceChecked('10_11')}" d="M171.754 28.5975L173.119 34.087C173.519 35.6949 175.147 36.674 176.755 36.274L182.244 34.9084C183.016 34.7163 183.68 34.2254 184.091 33.5436L187.36 28.1082C188.215 26.6884 187.756 24.8451 186.336 23.991L179.481 19.8671C178.061 19.013 176.218 19.4716 175.364 20.8913L172.094 26.3268C171.684 27.0086 171.561 27.8254 171.754 28.5975Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'9_10'" [ngClass]="{'dp-selected': isSpaceChecked('9_10')}" d="M150.465 17.2312L153.099 22.2375C153.87 23.7038 155.685 24.2671 157.151 23.4956L162.157 20.8617C162.861 20.4912 163.389 19.8562 163.625 19.0964L165.506 13.0385C165.998 11.4562 165.113 9.77516 163.531 9.28384L155.891 6.91151C154.308 6.42018 152.627 7.30461 152.136 8.88694L150.255 14.9448C150.019 15.7046 150.095 16.5271 150.465 17.2312Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
        <path class="tooth" [attr.data-title]="'8_9'" [ngClass]="{'dp-selected': isSpaceChecked('8_9')}" d="M131.121 13.1573L127.121 17.1573C125.95 18.3289 124.05 18.3289 122.879 17.1573L118.879 13.1573C118.316 12.5947 118 11.8317 118 11.036V4.69287C118 3.03602 119.343 1.69287 121 1.69287H129C130.657 1.69287 132 3.03602 132 4.69287V11.036C132 11.8317 131.684 12.5947 131.121 13.1573Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>



      </g>
    </svg>


    </div>
    <div class="mt-3" class="teeth-chips">
      <mat-chip-list #chipList>
        <mat-chip class="mat_chip_tooth" *ngFor="let s of selectedUpper; index as i" selectable="false" removable="true"
          (removed)="removeSelected(selectedUpper[i].tooth)">
          {{ mainConverterWithPins(s.tooth) }}-{{s.amount}}/{{s.beforeStep}}
          <nb-icon status="danger" matChipRemove icon="close-outline"></nb-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="mt-3 lower-arch d-flex justify-content-start flex-column">
    <div class="teeth-margin">
      <svg

        fill="none"
        xmlns="http://www.w3.org/2000/svg"

        xml:space="preserve"
        width="100%" height="100%" viewBox="0 0 250 200"
        (click)="clickItem($event)">
          <g>
          <path d="M47.9233 14.5754C46.2718 24.4521 38.8828 30.2395 30.4618 30.2394C20.8579 30.2394 13.0002 26.0625 13.0002 19.7968C13.0002 13.5311 22.6041 0.999804 30.4618 0.999804C38.3195 0.999804 48.7964 9.35419 47.9233 14.5754Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M52.9161 46.0112C51.029 57.158 44.5814 63.6898 34.9594 63.6898C23.9858 63.6897 18.0002 58.9755 18.0002 51.9041C18.0002 44.8326 23.9858 30.6898 34.9594 30.6898C45.9329 30.6898 53.9137 40.1185 52.9161 46.0112Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M28.7156 68.8776C24.9024 74.0989 24.5668 80.3212 26.9696 86.6303C30.83 96.7668 37.4465 100.957 46.177 96.0288C53.0718 92.1368 57.1131 79.3204 55.7809 73.0548C53.675 63.1502 49.4806 64.4567 40.9386 64.7005C36.3024 64.8329 31.7665 64.7001 28.7156 68.8776Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M41.6837 99.3265C37.4707 103.574 33.7162 108.019 35.1309 114.506C36.4551 120.578 48.7902 120.425 54.7893 114.506C59.6169 109.744 62.161 100.339 60.523 96.2906C58.885 92.2427 47.432 93.5308 41.6837 99.3265Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M52.6437 117.824C47.9471 122.544 43.7618 127.484 45.3389 134.693C46.815 141.44 60.5657 141.27 67.2532 134.693C72.6348 129.4 71.158 122.322 69.332 117.824C67.506 113.325 59.0516 111.383 52.6437 117.824Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M79.764 149.754C82.1055 143.788 82.4652 135.688 80.4083 132.687C78.6448 130.114 65.9316 134.144 62.5519 142.521C59.8321 149.262 63.2626 154.385 66.1659 157.242C69.0692 160.1 76.5692 157.894 79.764 149.754Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M77.608 157.641C76.6578 163.324 79.2589 165.818 83.7195 168.083C89.0642 170.797 94.219 173.251 96.8156 167.039C99.4347 160.773 98.5617 149.095 93.3233 146.154C88.9579 143.703 78.481 152.419 77.608 157.641Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M117.902 171.603C117.52 175.057 115.686 179.341 106.5 178.19C97.3147 177.039 98.0004 173.851 98.0003 165.848C98.0003 161.244 105.655 148.854 110.248 149.734C114.84 150.615 118.668 164.697 117.902 171.603Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M189.077 14.5754C190.729 24.4521 198.118 30.2395 206.539 30.2394C216.143 30.2394 224 26.0625 224 19.7968C224 13.5311 214.396 0.999804 206.539 0.999804C198.681 0.999804 188.204 9.35419 189.077 14.5754Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M184.084 46.0112C185.972 57.158 192.419 63.6898 202.041 63.6898C213.015 63.6897 219 58.9755 219 51.9041C219 44.8326 213.015 30.6898 202.041 30.6898C191.068 30.6898 183.087 40.1185 184.084 46.0112Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M208.285 68.8776C212.098 74.0989 212.434 80.3212 210.031 86.6303C206.17 96.7668 199.554 100.957 190.823 96.0288C183.929 92.1368 179.887 79.3204 181.22 73.0548C183.325 63.1502 187.52 64.4567 196.062 64.7005C200.698 64.8329 205.234 64.7001 208.285 68.8776Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M195.317 99.3265C199.53 103.574 203.284 108.019 201.87 114.506C200.545 120.578 188.21 120.425 182.211 114.506C177.384 109.744 174.84 100.339 176.478 96.2906C178.116 92.2427 189.569 93.5308 195.317 99.3265Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M184.357 117.824C189.053 122.544 193.239 127.484 191.662 134.693C190.185 141.44 176.435 141.27 169.747 134.693C164.366 129.4 165.842 122.322 167.668 117.824C169.494 113.325 177.949 111.383 184.357 117.824Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M157.236 149.754C154.895 143.788 154.535 135.688 156.592 132.687C158.356 130.114 171.069 134.144 174.449 142.521C177.168 149.262 173.738 154.385 170.835 157.242C167.931 160.1 160.431 157.894 157.236 149.754Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M159.392 157.641C160.343 163.324 157.742 165.818 153.281 168.083C147.936 170.797 142.781 173.251 140.185 167.039C137.566 160.773 138.439 149.095 143.677 146.154C148.043 143.703 158.52 152.419 159.392 157.641Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          <path d="M119.098 171.603C119.481 175.056 121.315 179.341 130.5 178.19C139.686 177.039 139 173.851 139 165.848C139 161.244 131.346 148.854 126.753 149.734C122.16 150.615 118.333 164.697 119.098 171.603Z" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" fill="none"/>
          </g>
          <g>


            <path class="tooth" [attr.data-title]="'26_25'" [ngClass]="{'dp-selected': isSpaceChecked('26_25')}" d="M98.5445 182.156L95.6802 177.278C94.8413 175.849 93.003 175.371 91.5742 176.21L86.6961 179.074C86.01 179.477 85.512 180.136 85.3117 180.906L83.715 187.045C83.2979 188.648 84.2597 190.286 85.8632 190.703L93.6055 192.717C95.209 193.134 96.847 192.172 97.2641 190.569L98.8609 184.43C99.0612 183.66 98.9474 182.842 98.5445 182.156Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'27_26'" [ngClass]="{'dp-selected': isSpaceChecked('27_26')}" d="M76.1055 168.869L74.2106 163.539C73.6557 161.978 71.9402 161.162 70.3791 161.717L65.049 163.612C64.2993 163.879 63.6862 164.432 63.3445 165.151L60.6207 170.879C59.9093 172.375 60.5455 174.165 62.0419 174.877L69.2667 178.312C70.7631 179.023 72.5528 178.387 73.2643 176.891L75.9881 171.162C76.3297 170.444 76.372 169.619 76.1055 168.869Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'28_27'" [ngClass]="{'dp-selected': isSpaceChecked('28_27')}" d="M56.8215 151.768L56.2528 146.14C56.0862 144.491 54.6149 143.29 52.9664 143.457L47.3382 144.025C46.5466 144.105 45.8192 144.496 45.316 145.113L41.3044 150.026C40.2565 151.31 40.4475 153.2 41.7309 154.247L47.9278 159.307C49.2112 160.355 51.1011 160.164 52.1489 158.88L56.1606 153.967C56.6638 153.351 56.9015 152.56 56.8215 151.768Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'29_28'" [ngClass]="{'dp-selected': isSpaceChecked('29_28')}" d="M41.181 131.198L41.9556 125.595C42.1824 123.954 41.0358 122.439 39.3946 122.212L33.791 121.438C33.0029 121.329 32.2037 121.537 31.5694 122.018L26.5122 125.846C25.1912 126.847 24.9311 128.728 25.9313 130.049L30.7603 136.427C31.7604 137.748 33.642 138.008 34.9629 137.008L40.0201 133.179C40.6545 132.699 41.072 131.986 41.181 131.198Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'30_29'" [ngClass]="{'dp-selected': isSpaceChecked('30_29')}" d="M29.9211 108.93L32.085 103.704C32.7188 102.173 31.9916 100.418 30.4607 99.7844L25.2341 97.6205C24.4989 97.3161 23.673 97.3163 22.938 97.6209L17.0781 100.049C15.5475 100.684 14.8209 102.439 15.4552 103.969L18.5179 111.36C19.1523 112.89 20.9073 113.617 22.4379 112.983L28.2978 110.554C29.0328 110.25 29.6168 109.666 29.9211 108.93Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'31_30'" [ngClass]="{'dp-selected': isSpaceChecked('31_30')}" d="M20.6946 74.5905L23.523 69.6915C24.3515 68.2566 23.8598 66.4219 22.425 65.5934L17.526 62.765C16.8369 62.3672 16.0181 62.2594 15.2495 62.4653L9.12252 64.107C7.52212 64.5359 6.57238 66.1809 7.0012 67.7813L9.07175 75.5087C9.50058 77.1091 11.1456 78.0588 12.746 77.63L18.873 75.9883C19.6415 75.7823 20.2968 75.2795 20.6946 74.5905Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'32_31'" [ngClass]="{'dp-selected': isSpaceChecked('32_31')}" d="M15.1281 41.3795L18.209 36.6352C19.1114 35.2457 18.7165 33.3877 17.3269 32.4853L12.5827 29.4044C11.9154 28.971 11.1033 28.8205 10.325 28.9859L4.1205 30.3047C2.49985 30.6492 1.46532 32.2423 1.80979 33.8629L3.47309 41.6881C3.81757 43.3087 5.41062 44.3433 7.03127 43.9988L13.2358 42.68C14.0141 42.5146 14.6947 42.0468 15.1281 41.3795Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>

            <path class="tooth" [attr.data-title]="'25_24'" [ngClass]="{'dp-selected': isSpaceChecked('25_24')}" d="M123.121 185.228L119.121 181.228C117.95 180.057 116.05 180.057 114.879 181.228L110.879 185.228C110.316 185.791 110 186.554 110 187.35V193.693C110 195.35 111.343 196.693 113 196.693H121C122.657 196.693 124 195.35 124 193.693V187.35C124 186.554 123.684 185.791 123.121 185.228Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'24_23'" [ngClass]="{'dp-selected': isSpaceChecked('24_23')}" d="M136.715 182.156L139.579 177.278C140.418 175.849 142.257 175.371 143.685 176.21L148.563 179.074C149.25 179.477 149.748 180.136 149.948 180.906L151.545 187.045C151.962 188.648 151 190.286 149.396 190.703L141.654 192.717C140.05 193.134 138.412 192.172 137.995 190.569L136.399 184.43C136.198 183.66 136.312 182.842 136.715 182.156Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'23_22'" [ngClass]="{'dp-selected': isSpaceChecked('23_22')}" d="M159.154 168.869L161.049 163.539C161.604 161.978 163.319 161.162 164.88 161.717L170.211 163.612C170.96 163.879 171.573 164.432 171.915 165.151L174.639 170.879C175.35 172.375 174.714 174.165 173.218 174.877L165.993 178.312C164.496 179.023 162.707 178.387 161.995 176.891L159.271 171.162C158.93 170.444 158.888 169.619 159.154 168.869Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'22_21'" [ngClass]="{'dp-selected': isSpaceChecked('22_21')}" d="M178.438 151.768L179.007 146.14C179.173 144.491 180.645 143.29 182.293 143.457L187.921 144.025C188.713 144.105 189.44 144.496 189.944 145.113L193.955 150.026C195.003 151.31 194.812 153.2 193.529 154.247L187.332 159.307C186.048 160.355 184.158 160.164 183.111 158.88L179.099 153.967C178.596 153.351 178.358 152.56 178.438 151.768Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'21_20'" [ngClass]="{'dp-selected': isSpaceChecked('21_20')}" d="M194.079 131.198L193.304 125.595C193.077 123.954 194.224 122.439 195.865 122.212L201.469 121.438C202.257 121.329 203.056 121.537 203.69 122.018L208.747 125.846C210.068 126.847 210.328 128.728 209.328 130.049L204.499 136.427C203.499 137.748 201.618 138.008 200.297 137.008L195.239 133.179C194.605 132.699 194.187 131.986 194.079 131.198Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'20_19'" [ngClass]="{'dp-selected': isSpaceChecked('20_19')}" d="M205.338 108.93L203.175 103.704C202.541 102.173 203.268 100.418 204.799 99.7844L210.025 97.6205C210.761 97.3161 211.587 97.3163 212.322 97.6209L218.181 100.049C219.712 100.684 220.439 102.439 219.804 103.969L216.742 111.36C216.107 112.89 214.352 113.617 212.822 112.983L206.962 110.554C206.227 110.25 205.643 109.666 205.338 108.93Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'19_18'" [ngClass]="{'dp-selected': isSpaceChecked('19_18')}" d="M214.565 74.5905L211.736 69.6915C210.908 68.2566 211.4 66.4219 212.835 65.5934L217.734 62.765C218.423 62.3672 219.241 62.2594 220.01 62.4653L226.137 64.107C227.737 64.5359 228.687 66.1809 228.258 67.7813L226.188 75.5087C225.759 77.1091 224.114 78.0588 222.514 77.63L216.387 75.9883C215.618 75.7823 214.963 75.2795 214.565 74.5905Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>
            <path class="tooth" [attr.data-title]="'18_17'" [ngClass]="{'dp-selected': isSpaceChecked('18_17')}" d="M220.131 41.3795L217.051 36.6352C216.148 35.2457 216.543 33.3877 217.933 32.4853L222.677 29.4044C223.344 28.971 224.156 28.8205 224.934 28.9859L231.139 30.3047C232.76 30.6492 233.794 32.2423 233.45 33.8629L231.786 41.6881C231.442 43.3087 229.849 44.3433 228.228 43.9988L222.024 42.68C221.245 42.5146 220.565 42.0468 220.131 41.3795Z" fill="none" stroke="#cecece" stroke-linecap="round" stroke-width="2"/>

          </g>
      </svg>
    </div>
    <div class="mt-3" class="teeth-chips">
      <mat-chip-list #chipList>
        <mat-chip  class="mat_chip_tooth" *ngFor="let s of selectedLower; index as i" selectable="false" removable="true"
          (removed)="removeSelected(selectedLower[i].tooth)">
          {{ mainConverterWithPins(s.tooth) }}-{{s.amount}}/{{s.beforeStep}}
          <nb-icon status="danger" matChipRemove icon="close-outline"></nb-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>


</div>







