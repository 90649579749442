import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
        ) {}
    errorMsg1: any;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err.status == 401, 401);
            console.log(err.status == 500, 500);
                 if (err.status ==  500) {
                    console.log(err);
                    
                 }   
                if (err.status == 401) {
                    this.authenticationService.logout();
                    this.router.navigate(['/login'])
                }
            return throwError(err);
        }))
    }
}
