import { NgModule, Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthServiceProxy, AutomationServiceProxy, BillingServiceProxy, CompanyServiceProxy, DashboardServiceProxy, Data3ShapeServiceProxy, DoctorServiceProxy, DocumentServiceProxy, InternalNoteServiceProxy, LocationServiceProxy, NotificationServiceProxy, OrderServiceProxy, PatientServiceProxy, PracticeServiceProxy, ProductServiceProxy, ReceivingSettingServiceProxy, ShipmentServiceProxy, StageServiceProxy, SubdomainServiceProxy, TreatmentPlanServiceProxy, WorkbenchTaskServiceProxy, XeroServiceProxy } from "./event-service-proxy";


@NgModule({
  providers: [
    FormBuilder,
    AuthServiceProxy,
    CompanyServiceProxy,
    LocationServiceProxy,
    PracticeServiceProxy,
    PatientServiceProxy,
    ProductServiceProxy,
    OrderServiceProxy,
    DashboardServiceProxy,
    NotificationServiceProxy,
    TreatmentPlanServiceProxy,
    BillingServiceProxy,
    SubdomainServiceProxy,
    WorkbenchTaskServiceProxy,
    ShipmentServiceProxy,
    StageServiceProxy,
    AutomationServiceProxy,
    DoctorServiceProxy, 
    Data3ShapeServiceProxy,
    DocumentServiceProxy,
    ReceivingSettingServiceProxy,
    XeroServiceProxy,
    InternalNoteServiceProxy
  ],
})
export class ServiceProxyModule { }
 