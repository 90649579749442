import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from './../../../@core/service';

@Directive({
  selector: '[checkPermission]'
})
export class CheckPermissionDirective {
  private currentUserClaims: any;

  constructor(
    private authService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.currentUserClaims = this.authService.getUserPermission();
  }

  @Input() set checkPermission(permission: string) {
    if (this.currentUserClaims && this.currentUserClaims[permission]) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}