import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
@Injectable({ providedIn: 'root' })
export class SignalrService {
    private hubConnection: signalR.HubConnection;

    constructor(private toastrService: NbToastrService) {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(environment.urlAddress +'/chathub')
            .build();
    }

    public startConnection = (userId: string): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            this.hubConnection.start()
                .then(() => {
                    this.hubConnection.invoke("AddConnection", userId);
                    resolve(true);
                })
                .catch((error) => {
                    console.error("Error starting hub connection:", error);
                    // const iconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
                    // this.toastrService.danger('Error starting hub connection', 'Error', { icon: iconConfig });
                    reject(false);
                });
        });
    }

    public addDataListener = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            this.hubConnection.on('notifyClient', (response) => {
                console.log("Received data from server:", response);
                const iconConfig = { icon: 'success-toastr', pack: 'great-pack' };
                if (response.isSuccess) {
                    this.toastrService.success('', "Success", { icon: iconConfig });
                } else {
                    this.toastrService.danger('Error when saving data', '', { icon: iconConfig });
                }
                this.hubConnection.stop();
                resolve(response);
            });
        });
    }
}