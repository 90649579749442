export enum TeethChartingSystem {
    FDI = 0,
    Palmer = 1,
    Universal = 2,
    Alphanumeric = 3
}

export function getTeethChartingSystems() {
    return Object.keys(TeethChartingSystem)
      .filter(key => !isNaN(Number(TeethChartingSystem[key])))
      .map(key => {
        return {
          id: TeethChartingSystem[key],
          name: key
        };
      });
  }

//   export function convertToFDI(system: number, toothNumber: number | string): number {
//     let toothKey: keyof typeof UniversalToFDINotation | string;
//     switch (system) {
//       case 1:
//         return PalmerToFDINotation[toothNumber as keyof typeof PalmerToFDINotation];
//       case 2:
//         toothKey = `T${toothNumber}`;
//         return UniversalToFDINotation[toothKey as keyof typeof UniversalToFDINotation];
//       case 3:
//         return AlphanumericToFDINotation[toothNumber as keyof typeof AlphanumericToFDINotation];
//       case TeethChartingSystem.FDI:
//       default:
//         return typeof toothNumber === 'number' ? toothNumber : parseInt(toothNumber, 10);
//     }
//   }

  export function convertFromFDI(targetSystem: number, toothNumber: number): number | string {
    let toothKey: string | undefined;
    switch (targetSystem) {
      case 1:
        toothKey = Object.keys(PalmerToFDINotation).find(key => PalmerToFDINotation[key as keyof typeof PalmerToFDINotation] === toothNumber);
        break;
      case 2:
        toothKey = Object.keys(UniversalToFDINotation).find(key => UniversalToFDINotation[key as keyof typeof UniversalToFDINotation] === toothNumber);
        break;
      case 3:
        toothKey = Object.keys(AlphanumericToFDINotation).find(key => AlphanumericToFDINotation[key as keyof typeof AlphanumericToFDINotation] === toothNumber);
        break;
      case 0:
      default:
        toothKey = toothNumber.toString();
    }
    return toothKey ? toothKey.replace('T', '') : toothNumber;
  }

  export enum AlphanumericToFDINotation {
    UR8 = 18,
    UR7 = 17,
    UR6 = 16,
    UR5 = 15,
    UR4 = 14,
    UR3 = 13,
    UR2 = 12,
    UR1 = 11,
    UL1 = 21,
    UL2 = 22,
    UL3 = 23,
    UL4 = 24,
    UL5 = 25,
    UL6 = 26,
    UL7 = 27,
    UL8 = 28,
    LL8 = 38,
    LL7 = 37,
    LL6 = 36,
    LL5 = 35,
    LL4 = 34,
    LL3 = 33,
    LL2 = 32,
    LL1 = 31,
    LR1 = 41,
    LR2 = 42,
    LR3 = 43,
    LR4 = 44,
    LR5 = 45,
    LR6 = 46,
    LR7 = 47,
    LR8 = 48,
  }

  export enum PalmerToFDINotation {
    UR8 = 18,
    UR7 = 17,
    UR6 = 16,
    UR5 = 15,
    UR4 = 14,
    UR3 = 13,
    UR2 = 12,
    UR1 = 11,
    UL1 = 21,
    UL2 = 22,
    UL3 = 23,
    UL4 = 24,
    UL5 = 25,
    UL6 = 26,
    UL7 = 27,
    UL8 = 28,
    LL8 = 38,
    LL7 = 37,
    LL6 = 36,
    LL5 = 35,
    LL4 = 34,
    LL3 = 33,
    LL2 = 32,
    LL1 = 31,
    LR1 = 41,
    LR2 = 42,
    LR3 = 43,
    LR4 = 44,
    LR5 = 45,
    LR6 = 46,
    LR7 = 47,
    LR8 = 48,
  }

  export enum UniversalToFDINotation {
    T1 = 18,
    T2 = 17,
    T3 = 16,
    T4 = 15,
    T5 = 14,
    T6 = 13,
    T7 = 12,
    T8 = 11,
    T9 = 21,
    T10 = 22,
    T11 = 23,
    T12 = 24,
    T13 = 25,
    T14 = 26,
    T15 = 27,
    T16 = 28,
    T17 = 38,
    T18 = 37,
    T19 = 36,
    T20 = 35,
    T21 = 34,
    T22 = 33,
    T23 = 32,
    T24 = 31,
    T25 = 41,
    T26 = 42,
    T27 = 43,
    T28 = 44,
    T29 = 45,
    T30 = 46,
    T31 = 47,
    T32 = 48,
  }