<form class="form-horizontal" [formGroup]="form" >
    <nb-card class="modal-card" [nbSpinner]="isLoading" nbSpinnerStatus="basic">
        <nb-card-header>
            <div class="close-modal-icon" (click)="close()">
                <img src="../../../../assets/images/icons/cancel.svg" alt="">
            </div>
            <div class="modal-title text-left">
              {{(edit && edit.id) ? 'Update account' : 'Add account'}} 
           
            </div>
        </nb-card-header>
        <nb-card-body>
         
        <div class="create-form-body">
            <div class="is-archived-client" *ngIf="edit.isArchive">
                Archived client
            </div>

<!-- 
            <div class="d-flex w-100">
                <div class="form-modal-group w-100 mr-1" [ngClass]="displayFieldCss('role')">
                    <label class="label-name" for="role">Role</label>
                    <ng-select [searchable]="true"
                     placeholder="All Roles" [(ngModel)]="userModelDto.userRole"
                    formControlName="role"  id="role">
                     <ng-option  [value]="'CompanyAdmin'">Company Admin</ng-option>
x                    <ng-option  [value]="'LocationAdmin'">Location Admin</ng-option>
                      <ng-option  [value]="'Doctor'">Dentist</ng-option>
                    </ng-select>
                        <app-field-error-display [displayError]="isFieldValid('role')" errorMsg="Please inform your role">
                    </app-field-error-display>
                </div>
             
            </div> -->

          
          
            
            <div class="d-flex w-100">
                <div class="form-modal-group w-100 mr-1" [ngClass]="displayFieldCss('name')">
                    <label class="label-name" for="name">Name</label>
                    <input [(ngModel)]="userModelDto.fullName" formControlName="name" id="name" type="text" nbInput fullWidth fieldSize="small"
                     placeholder="">
                    <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Please inform your name">
                    </app-field-error-display>
                </div>  
            </div>


            <div class="d-flex w-100">
                <div class="form-modal-group w-100 mr-1" [ngClass]="displayFieldCss('email')">
                    <label class="label-name" for="email">Email (Optional)</label>
                    <input  [(ngModel)]="userModelDto.email"
                     formControlName="email" id="email" type="email" nbInput fullWidth fieldSize="small"
                     placeholder="">
                    <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Please inform your email">
                    </app-field-error-display>
                </div>
            </div>



     








           <div class="d-flex w-100">
                <div class="form-modal-group w-100 mr-1" [ngClass]="displayFieldCss('userName')">
                    <label class="label-name" for="userName">UserName</label>
                    <input [ngClass]="{'disabled-input': edit.id}" (input)="checkUserName()" [(ngModel)]="userModelDto.userName" 
                    formControlName="userName" id="userName" type="text" nbInput fullWidth fieldSize="small">
                     <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                </div>  
            </div>




            <div class="d-flex w-100">
                <div class="form-modal-group w-100 mr-1"[ngClass]="displayFieldCss('phone')" >
                    <label class="label-name" for="name">Mobile number (receives notifications) (optional)</label>
                        <ngx-intl-tel-input appendTo="body"
                        [(ngModel)]="phoneNumbByObject"
                        formControlName="phone" id="phone" 
                        [cssClass]="'phone-country-input w-100'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="false"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="selectedCountryName"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National"
                        name="phone"
                      >
                      </ngx-intl-tel-input>
                    <app-field-error-display [displayError]="isFieldValid('phone')" errorMsg="Please inform phone">
                    </app-field-error-display>
                </div>
            </div>








            <div class="d-flex w-100">
                <div class="form-modal-group w-100 mr-1" [ngClass]="displayFieldCss('accountCode')">
                    <label class="label-name" for="email">Account Code (Optional)</label>
                    <input  [(ngModel)]="userModelDto.accountCode"
                     formControlName="accountCode" id="accountCode" type="text" nbInput fullWidth fieldSize="small"
                     placeholder="">
                    <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Please inform your account code">
                    </app-field-error-display>
                </div>
            </div>


            
            <div *ngIf="(edit && edit.id)" class="d-flex mt-2 justify-content-between align-items-center">
                <div>
                    <div style="font-size: 10px;" class="status-draft status-label invite-button" (click)="getInviteLink(edit.id)" *ngIf="!edit.register">
                        INVITE URL
                        <nb-icon icon="link-2-outline" class="invite-icon"></nb-icon>
                    </div>
                </div>
                <div class="row-action-container" *ngIf="!edit.isArchive">
                        <button (click)="deleteAccount()" class="great-button-danger"
                         nbButton status="danger" size="small">
                            Remove Client
                        </button>
                </div>
                <div class="row-action-container" *ngIf="edit.isArchive">
                    <button (click)="unArchivedAccount()" class="great-button-danger"
                     nbButton status="danger" size="small">
                        Unarchived Client
                    </button>
            </div>
            </div>
            <div *ngIf="user.userroles =='Admin' && !edit" class="form-modal-group w-100 mr-1" [ngClass]="displayFieldCss('companyId')">
                <label class="label-name" for="companyId">Company </label>
                <ng-select appendTo="body"  [loading]="isCompanyLoading" [searchable]="true"
                    (keyup)="getAllCompanyWithAdminRole($event.target.value)" placeholder="" [(ngModel)]="userModelDto.companyId"
                    formControlName="companyId" (change)="getAllLocationWithAdminRole(userModelDto.companyId)" id="companyId">
                    <ng-option *ngFor="let company of companyListDto" [value]="company.id">{{company.name}}</ng-option>
                </ng-select>
                <app-field-error-display [displayError]="isFieldValid('companyId')" errorMsg="Please inform your company ">
                </app-field-error-display>
                
               
            </div>
            
            <div  *ngIf="!edit"  class="form-modal-group" [ngClass]="displayFieldCss('locationIds')">
                <label class="label-name" for="locationIds">Location</label>
                <ng-select appendTo="body" (change)="getAllPracticesWithLocation(userModelDto.locationIds, '')"  [loading]="isLocationLoading" placeholder="All location" [multiple]="false" [(ngModel)]="userModelDto.locationIds"
                    formControlName="locationIds" id="locationIds" [searchable]="true" [clearable]="false">
                    <ng-option [value]='0' disabled>None</ng-option>
                    <ng-option *ngFor="let location of locationListDto" [value]="location.id">{{location.name}}</ng-option>
                </ng-select>
                <app-field-error-display [displayError]="isFieldValid('locationIds')" errorMsg="Please inform your location ">
                </app-field-error-display>
            </div>




            <!-- <div  *ngIf="(!edit &&  !edit.id)" class="form-modal-group" [ngClass]="displayFieldCss('practiceIds')">
                <label class="label-name" for="locationIds">Practices</label>
                <ng-select appendTo="body" [loading]="isPracticesLoading"  (keyup)="getAllPracticesWithLocation(userModelDto.locationIds, $event.target.value)"
                placeholder="All Practices" [multiple]="true" [(ngModel)]="userModelDto.practiceIds"
                    formControlName="practiceIds" id="practiceIds" [searchable]="true" [clearable]="false">
                    <ng-option *ngFor="let practice of practicesDto" [value]="practice.id">{{practice.name}}</ng-option>
                </ng-select>
                <app-field-error-display [displayError]="isFieldValid('practiceIds')" errorMsg="Please inform your practices ">
                </app-field-error-display>
            </div> -->



            <div [hidden]="companyId" class="form-modal-group w-100 mr-1" [ngClass]="displayFieldCss('rolename')">
                <label class="label-name" for="companyId">Role </label>
                <ng-select appendTo="body"  [searchable]="true"
                     placeholder="" [(ngModel)]="userModelDto.userRole"
                    formControlName="rolename"  id="rolename">
                    <ng-option  [value]="'Doctor'">Dentist</ng-option>
                    <ng-option  [value]="'Manager'">Manager</ng-option>
                </ng-select>
                <app-field-error-display [displayError]="isFieldValid('rolename')" errorMsg="Please inform your role ">
                </app-field-error-display>
                
               
            </div>

            <div class="form-modal-group"  >
                <div class="assign-section">
                    <table  class="client-table-list" >
                        <colgroup>
                            <col style="width:5%">
                            <col style="width:40%">
                            <col style="width:40%">
                            <col style="width:15%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th class="table-title" scope="col"></th>
                                <th class="table-title" scope="col"></th>
                                <th class="table-title" scope="col"> </th>
                                <th class="table-title" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="edit?.id">
                                
                                <tr *ngFor="let practice of userModelDto.practiceIds">
                                    <td>
                                        <img class="icon-client" src="../../../../assets/images/icons/practice-icon.svg" alt="">
                                    </td>
                                    <td class="client-label">{{practice.name}}   </td>
                                    <td class="client-label">
                                            {{userModelDto.userRole == 'Manager' ? 'Manager' : practice.role}}
                                      </td>
                                      <td class="client-label">
                                        {{practice.idhPerformerNumber}}
                                        </td>
                                        <td class="action-func" >
                                            <div class="d-flex" style="gap: 5px">
                                                    <nb-icon (click)="editClients(practice, 'edit')" pack="great-pack" icon="edit-icon-pencil-v2"></nb-icon>
                                                    <nb-icon (click)="removeClients(practice)" pack="great-pack" icon="remove-stage-trash"></nb-icon>
                                            </div>
                                        </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!edit?.id">
                                <tr *ngFor="let practice of practiceAssign">
                                    <td>
                                        <img class="icon-client" src="../../../../assets/images/icons/practice-icon.svg" alt="">
                                    </td>
                                    <td class="client-label">{{practice.practiceName}}   </td>
                                    <td class="client-label">
                                        <ng-container *ngIf="userModelDto.userRole !== 'Manager'">
                                            {{(practice.isOwnOrders ? 'Solo' : 'Principal')}}
                                        </ng-container>
                                        <ng-container *ngIf="userModelDto.userRole == 'Manager'">
                                            (Manager)
                                        </ng-container>


                                    </td>
                                    <td class="client-label">
                                        {{practice.idhPerformerNumber}}
                                        </td>
                                    <td class="action-func" >
                                        <div class="d-flex" style="gap: 5px">
                                                <nb-icon (click)="editClients(practice, 'create')" pack="great-pack" icon="edit-icon-pencil-v2"></nb-icon>
                                                <nb-icon (click)="removeClients(practice)" pack="great-pack" icon="remove-stage-trash"></nb-icon>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                         
                        </tbody>
                    </table>
                    
                </div>
                <div class="practice-list-box">
                    <div class="practice-item w-50">
                        <label class="label-name" for="locationIds">Assignments and roles</label>
                        <ng-select (change)="onChangeAssign()" appendTo="body" [loading]="isPracticesLoading" placeholder="All Practices" [multiple]="false"
                            [(ngModel)]="selectedPractice" formControlName="practiceIds" id="practiceIds" [searchable]="true"
                            [clearable]="false">
                            <ng-option *ngFor="let practice of practicesDto" [value]="practice.id">{{practice.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="practice-item w-50" *ngIf="selectedPractice  && userModelDto.userRole !== 'Manager'"> 
                        <div class="permissions-list">
                            <div class="radio-list-permission">
                                <div class="radio-item">
                                    <div class="radio-label">
                                        ORDER ACCESS
                                    </div>
                                    <nb-radio-group  formControlName="order" [(ngModel)]="order" class="practice-list-box" name="order">
                                        <nb-radio *ngFor="let option of permissionList" [value]="option.value">
                                            {{ option.value }}
                                        </nb-radio>
                                    </nb-radio-group>
                                </div>
                                <div class="radio-item">    
                                    <div class="radio-label">
                                       PATIENT ACCESS
                                    </div>
                                    <nb-radio-group  formControlName="patient" [(ngModel)]="patient" class="practice-list-box" name="patient">
                                        <nb-radio *ngFor="let option of permissionList" [value]="option.value">
                                            {{ option.value }}
                                        </nb-radio>
                                    </nb-radio-group>
                                </div>


                                <div class="radio-item">
                                    <div class="radio-label">
                                        PERFORMER NUMBER
                                    </div>
                                    <input [(ngModel)]="performerNumber" formControlName="performer" id="performer" type="number" nbInput fullWidth
                                        fieldSize="small" placeholder="">
                                </div>
                                <div class="radio-item">
                                    <div class="radio-label">
                                        ACCOUNT CODE
                                    </div>
                                    <input [(ngModel)]="tmpaccountCode"  formControlName="assignmentaccountCode" type="text" nbInput fullWidth
                                        fieldSize="small" placeholder="">
                                </div>

                                <div class="radio-item">
                                    <div class="radio-label">
                                        CREDIT LIMIT
                                    </div>
                                    <input [(ngModel)]="tmpcreditLimit"  formControlName="assignmentcreditLimit" type="number" nbInput fullWidth
                                        fieldSize="small" placeholder="">
                                </div>


                            </div>
                            <button nbButton size="small" fullWidth class="settings-bt-clinet" (click)="addAssignModel()">
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </nb-card-body>
        <nb-card-footer>
           
            <div class="close-modal-container position-relative">
                <div class="isStep" *ngIf="isStep">
                    <!-- {{(!isStep || userModelDto.practiceIds?.length > 0) ? '' : 'Step 1 of 2'}}   -->
                </div>
                <!-- <div class="delete-account" *ngIf="(edit && edit.id)" (click)="deleteAccount()">
                    <nb-icon icon="trash-icon-greatlab" pack="great-pack"></nb-icon>
                    Delete Account
                </div> -->
                <button shape="semi-round" (click)="close()" class="btn-shadow mr-2 close-button-size" nbButton size="small" status="basic">
                    Close
                  </button>
                <button  *ngIf="!edit.id" shape="semi-round" [disabled]="errorMessage !== ''"   (click)="onSubmit()" nbButton status="primary" size="small" class="save-button-size">
                    Create
                    <!-- {{(!isStep || userModelDto.practiceIds?.length > 0) ? 'Create' : 'Next Step'}}  -->
                </button>
                <button  *ngIf="(edit && edit.id)" shape="semi-round"  (click)="onUpdate()" nbButton status="primary" size="small" class="save-button-size">
                   Update
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</form>






















