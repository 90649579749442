<nb-card class="modal-card" [nbSpinner]="isPrintingDone || invoiceGeneratingCheckLoading">
    <nb-card-header>
        <div class="d-flex align-items-center justify-content-between">
            <div class="modal-title">
                Which status do you want to move to?
            </div>
            <div class="form-modal-group select-size-md">
                <ng-select *ngIf="isManual" appendTo="body" placeholder="All Status" (change)="onNewStatusChange()"
                    [(ngModel)]="orderStatusDto.statusId" id="status" [searchable]="false" [clearable]="false">
                    <ng-option [value]='0' disabled>None</ng-option>
                    <ng-option *ngFor="let status of statusListDto" [value]="status.id">{{status.name}}</ng-option>
                </ng-select>
                <div class="selected-status" *ngIf="!isManual">
                    <div class="next-status-label">
                        Next status
                    </div>
                    <div class="status-label status-{{orderStatusDto.statusId}}">
                        <ng-container *ngIf="orderStatusDto.statusId == 1">Submitted </ng-container>
                        <ng-container *ngIf="orderStatusDto.statusId == 2">Accepted </ng-container>
                        <ng-container *ngIf="orderStatusDto.statusId == 3">Design </ng-container>
                        <ng-container *ngIf="orderStatusDto.statusId == 4">Plan </ng-container>
                        <ng-container *ngIf="orderStatusDto.statusId == 5">Manufacturing </ng-container>
                        <ng-container *ngIf="orderStatusDto.statusId == 6">QC </ng-container>
                        <ng-container *ngIf="orderStatusDto.statusId == 7">Packaging </ng-container>
                        <ng-container *ngIf="orderStatusDto.statusId == 8">Dispatched </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="line"></div>

    </nb-card-header>
    <nb-card-body>

        <div class="group-feathure-box mb-3" *ngIf="orderStatusDto.statusId !== 8 ">
            <div class="label-title">
                Want to change main product selection?
            </div>
            <div class="label-desc">
                Consult Doctor before changing order specifications
            </div>
            <div class="label-action-link" (click)="routeToOrderEdit(order)">
                Add/Edit product selection
            </div>
        </div>


        <div class="ng-container">
            <div class="mt-2" *ngIf="orderStatusDto.statusId == 7 ">
                <div class="check-list-title">
                    <b> Checklist</b>
                </div>
                <div class="select-qc-checklist">
                    <nb-checkbox status="basic" [(ngModel)]="checkListALL" (checkedChange)="selectAllChacklist($event)">
                        <div class="checkbox-checklist">Select All</div>
                    </nb-checkbox>
                    <nb-checkbox [disabled]="checkListALL" *ngFor="let checkList of qualityControlDto" status="basic"
                        [(ngModel)]="checkList.value">
                        <div class="checkbox-checklist" [innerHtml]="checkList.text"></div>
                    </nb-checkbox>
                </div>
            </div>

            <div *ngIf="orderStatusDto.statusId == 8" [nbSpinner]="isLabelsGenerating" nbSpinnerStatus="info">
                <nb-tabset (changeTab)="changeTab($event.tabId)">
                    <nb-tab tabTitle="New shipping label" tabId="new" class="p-0">
                        <p class="selection-title">Shipping method</p>
                        <div class="selected-options-list">
                            <div *ngIf="isShipengineAvailable" class="shipping-option">
                                <input hidden [id]="'shipengine'" class="radio-card-input"
                                    [checked]="shippingMethod == 'shipengine'" type="radio" [(ngModel)]="shippingMethod"
                                    name="shipengine" [value]="'shipengine'" />
                                <label [for]="'shipengine'" class="selected-item-card">
                                    <div class="item-title">ShipEngine</div>
                                </label>
                            </div>
                            <div class="shipping-option">
                                <input hidden [id]="'independent'" class="radio-card-input"
                                    [checked]="shippingMethod == 'independent'" type="radio"
                                    [(ngModel)]="shippingMethod" name="independent" [value]="'independent'" />
                                <label [for]="'independent'" class="selected-item-card">
                                    <div class="item-title">Independent<br />shipping</div>
                                </label>
                            </div>
                            <div class="shipping-option">
                                <input hidden [id]="'driver'" class="radio-card-input"
                                    [checked]="shippingMethod == 'driver'" type="radio" [(ngModel)]="shippingMethod"
                                    name="driver" [value]="'driver'" />
                                <label [for]="'driver'" class="selected-item-card">
                                    <div class="item-title">Driver delivery</div>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="shippingMethod == 'shipengine'" class="shipping-method">
                            <div *ngIf="isAddressesValid" class="selection-block">
                                <p class="selection-title m-0">Shipping carrier</p>
                                <div class="selected-options-list" [nbSpinner]="isCarriersLoading"
                                    nbSpinnerStatus="info">
                                    <div *ngFor="let item of carriers| paginate: configCarrier , let i = index">
                                        <input hidden [id]="'carrier' + (i + 1)" class="radio-card-input"
                                            [checked]="selectedCarrier === item" type="radio" [value]="item"
                                            [(ngModel)]="selectedCarrier" name="carrier" (change)="onCarrierChange()" />
                                        <label [for]="'carrier' + (i + 1)" class="selected-item-card">
                                            <div class="item-title">{{item.friendlyName}}</div>
                                        </label>
                                    </div>
                                    <div class="selected-item-empty-card" *ngIf="carriers.length == 0">
                                        <div *ngIf="!isCarriersLoading" class="item-title">No shipping carriers
                                            available</div>
                                    </div>
                                </div>
                                <div class="pagination-container">
                                    <pagination-controls [id]="configCarrier.id" [maxSize]="5" directionLinks="true"
                                        autoHide="true" responsive="true"
                                        (pageChange)="onPageChange($event, 'configCarrier')"
                                        (pageBoundsCorrection)="onPageBoundsCorrection($event, 'configCarrier')"></pagination-controls>
                                </div>
                            </div>
                            <div class="selection-block" *ngIf="selectedCarrier">
                                <p class="selection-title m-0">Shipping service</p>
                                <div class="selected-options-list">
                                    <div
                                        *ngFor="let item of selectedCarrier.services | paginate: configService , let i = index">
                                        <input hidden [id]="'service' + (i + 1)" class="radio-card-input"
                                            [checked]="selectedService === item" type="radio" [value]="item"
                                            [(ngModel)]="selectedService" name="service" />
                                        <label [for]="'service' + (i + 1)" class="selected-item-card">
                                            <div class="item-title">{{item.name}}</div>
                                        </label>
                                    </div>
                                    <div class="selected-item-empty-card" *ngIf="selectedCarrier.services.length == 0">
                                        <div class="item-title">No services available</div>
                                    </div>
                                </div>
                                <div class="pagination-container">
                                    <pagination-controls [id]="configService.id" [maxSize]="5" directionLinks="true"
                                        autoHide="true" responsive="true"
                                        (pageChange)="onPageChange($event, 'configService')"
                                        (pageBoundsCorrection)="onPageBoundsCorrection($event, 'configService')"></pagination-controls>
                                </div>
                            </div>

                            <p *ngIf="!isAddressesValid" class="selection-title selection-title-error m-0">Creating
                                shipment unavailable!
                                {{invalidAddresses.length}} invalid address{{invalidAddresses.length > 1 ? 'es' : ''}}
                            </p>
                            <div *ngIf="!isAddressesValid" class="selection-block">
                                <div class="selected-options-list">
                                    <div
                                        *ngFor="let item of invalidAddresses | paginate: configAddress , let i = index">
                                        <input hidden [id]="'address' + (i + 1)" class="radio-card-input"
                                            [checked]="selectedAddress === item" type="radio" [value]="item"
                                            [(ngModel)]="selectedAddress" name="address" />
                                        <label [for]="'address' + (i + 1)" class="selected-item-card">
                                            <div class="item-title">{{item.name}}</div>
                                        </label>
                                    </div>
                                </div>
                                <div class="pagination-container" *ngIf="invalidAddresses.length > 3">
                                    <pagination-controls [id]="configAddress.id" [maxSize]="5" directionLinks="true"
                                        autoHide="true" responsive="true"
                                        (pageChange)="onPageChange($event, 'configAddress')"
                                        (pageBoundsCorrection)="onPageBoundsCorrection($event, 'configAddress')"></pagination-controls>
                                </div>
                            </div>
                            <div *ngIf="selectedAddress" class="selection-block">
                                <p class="selection-title m-0">Address Errors</p>
                                <p class="selection-title m-0" *ngFor="let error of selectedAddress.messages">
                                    {{error.type}}: {{error.message}}</p>
                                <button *ngIf="selectedAddress.isLocation" nbButton status="primary" size="small"
                                    (click)="editLocation()" class="btn-edit-address">
                                    Edit location
                                </button>
                                <button *ngIf="!selectedAddress.isLocation" nbButton status="primary" size="small"
                                    (click)="editPractice()" class="btn-edit-address">
                                    Edit practice
                                </button>
                            </div>
                        </div>
                        <div *ngIf="shippingMethod == 'independent'" class="shipping-method">
                            <div class="check-list-title">
                                Tracking number
                            </div>
                            <div class="select-qc-checklist">
                                <input [(ngModel)]="orderStatusDto.trackingNumber" type="text" nbInput fullWidth
                                    fieldSize="small">
                            </div>
                        </div>
                        <div *ngIf="shippingMethod == 'driver'" class="shipping-method">
                        </div>
                    </nb-tab>
                    <nb-tab tabTitle="Add to existing" tabId="existing" class="p-0">
                        <p class="selection-title">Select existing shipment</p>
                        <input nbInput fullWidth [(ngModel)]="searchOrderCode" (input)="searchOrder()"
                            placeholder="Order code" fieldSize="small" />
                        <div class="table-container">
                            <p class="no-shipments" *ngIf="searchLocationShipments.length == 0">
                                No shipments found
                            </p>
                            <table class="existing-shipments" *ngIf="searchLocationShipments.length > 0">
                                <tr>
                                    <th class="head-title col-md-1">ORDER ID</th>
                                    <th class="head-title col-md-2">TRACKING NUMBER</th>
                                    <th class="head-title col-md-1">DATE</th>
                                </tr>
                                <tr class="table-row" *ngFor="let shipment of searchLocationShipments"
                                    (click)="selectExistingShipment(shipment)">
                                    <td class="body-title col-md-1">{{shipment.order.code}}</td>
                                    <td class="body-title col-md-2">{{shipment.trackingNumber}}</td>
                                    <td class="body-title col-md-1">{{shipment.createdAt | date: (order.dateFormat ?
                                        order.dateFormat : 'dd/MM/YYYY')}}</td>
                                </tr>
                            </table>
                        </div>
                        <p class="selection-title" *ngIf="orderStatusDto.trackingNumber">
                            Selected shipment: {{orderStatusDto.trackingNumber}}
                        </p>
                    </nb-tab>
                </nb-tabset>
            </div>

        </div>




        <textarea *ngIf="shippingMethod != 'shipengine' && selectedTab != 'existing'" [(ngModel)]="orderStatusDto.note"
            class="text-area mt-2" rows="5" nbInput fullWidth placeholder="Notes..."></textarea>

        <div class="invoice-generation" *ngIf="invoicesWillBeGenerated && this.anyUninvoicedProducts()">
            <div class="line"></div>
            <div class="group-feathure-box">
                <div class="label-title">
                    Invoice generation
                </div>
            </div>
            <div class="row w-100 m-0 justify-content-center">
                <div class="col-12">
                    <div class="table-of-product">
                        <table class="table-base">
                            <tbody>
                                <tr>
                                    <th class="thead sm">Product</th>
                                    <th class="thead sm">Material</th>
                                    <th class="thead sm">Standard</th>
                                    <th class="thead sm">Shade</th>
                                    <th class="thead sm text-center">Teeth/Arch</th>
                                    <th class="thead sm text-center">Price</th>
                                </tr>
                                <ng-container *ngFor="let product of order.orderProducts | ngxFilter:'isAddon':false">
                                    <ng-container *ngIf="!product.isInvoiced">
                                        <tr style="cursor: pointer;" (click)="selectProduct(product)">
                                            <td class="tbody sm">
                                                {{product.productName}}
                                                <br>
                                                <small
                                                    class="category-label">{{getCurrentNameCategory(product.product.categoryId)}}</small>
                                            </td>
                                            <td class="tbody sm">{{product.materialName}}</td>
                                            <td class="tbody sm">{{product.standardTierName}}</td>
                                            <td class="tbody sm">{{product.shade? product.shade : '-'}}
                                                <br>
                                                <small class="category-label">{{product.shade? product.shadeSystem :
                                                    ''}}</small>
                                            </td>
                                            <td class="tbody sm text-center">{{product.teeth?
                                                convertTeethNotation(order.location.teethType, product.teeth) :
                                                product.arch}}</td>
                                            <td class="tbody sm text-center">
                                                {{product.itemTmp | currency:order?.location?.currency:"symbol" }}
                                            </td>
                                        </tr>

                                        <tr *ngIf="product.implantSpecification">
                                            <td colspan=6>
                                                <div class="implant-spec border-top-el">
                                                    <div class="body-content">
                                                        <small class="category-label">Implant Scanbody</small>
                                                        {{product.implantScanbody}}
                                                    </div>
                                                    <div class="body-content"> <small class="category-label">Implant
                                                            Text</small>
                                                        {{product.implantScanbodyText}}
                                                    </div>
                                                    <div class="body-content"> <small class="category-label">Implant
                                                            Specification</small>
                                                        {{product.implantSpecification}}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>

                                <tr *ngFor="let product of order.orderCustomFees">
                                    <td class="tbody sm">{{product.name}}</td>
                                    <td class="tbody sm">N/A</td>
                                    <td class="tbody sm">N/A</td>
                                    <td class="tbody sm">-</td>
                                    <td class="tbody sm"> </td>
                                    <td class="tbody sm">
                                        {{product.itemTmp | currency:order?.location?.currency:"symbol" }}
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan=4></td>
                                    <td colspan=1>
                                        <div class="total-label">Total</div>
                                    </td>
                                    <td colspan=1>
                                        <div class="total-price text-center">
                                            {{price | currency:order?.location?.currency:"symbol" }}

                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="row w-100 m-0 justify-content-center">
                <div class="col-12">
                    <div class="type-of-print">
                        <div class="type-print-title">
                            Print documents
                        </div>
                        <div class="print-list">
                            <div class="d-flex align-items-center">
                                <nb-checkbox class="d-flex align-items-center" [(ngModel)]="isLabDocket"></nb-checkbox>
                                <div class="item-title-select">Lab docket</div>
                            </div>
                            <div class="d-flex align-items-center">
                                <nb-checkbox class="d-flex align-items-center" [(ngModel)]="isInvoice"></nb-checkbox>
                                <div class="item-title-select">Invoice</div>
                            </div>
                            <div class="d-flex align-items-center">
                                <nb-checkbox class="d-flex align-items-center"
                                    [(ngModel)]="patientStatment"></nb-checkbox>
                                <div class="item-title-select">Patient statement</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="line"></div>
    </nb-card-body>
    <nb-card-footer>
        <div class="close-modal-container">
            <button shape="semi-round" (click)="decline()" class="btn-shadow mr-2 close-button-size" nbButton
                size="small" status="basic">
                Close
            </button>
            <button [disabled]="checkIsQC()"
                *ngIf="(orderStatusDto.statusId == 7 || (orderStatusDto.statusId == 8 && shippingMethod != 'shipengine')) && !isGenerated"
                shape="semi-round" (click)="approve()" nbButton status="primary" size="small" class="save-button-size">
                {{invoicesWillBeGenerated ? 'Update order and generate invoice' : 'Update order'}}
            </button>
            <button [disabled]="!selectedCarrier || !selectedService"
                *ngIf="shippingMethod == 'shipengine' && orderStatusDto.statusId == 8 && !isGenerated" shape="semi-round"
                (click)="generateLabel()" nbButton status="primary" size="small" class="save-button-size">
                {{invoicesWillBeGenerated ? 'Generate label and invoice' : 'Generate label'}}
            </button>
            <button *ngIf="orderStatusDto.statusId < 7 && !invoicesWillBeGenerated" shape="semi-round"
                (click)="approve()" nbButton status="primary" size="small" class="save-button-size"
                [disabled]="invoiceGeneratingCheckLoading">
                Update order
            </button>
            <button *ngIf="orderStatusDto.statusId < 7 && invoicesWillBeGenerated && !isGenerated" shape="semi-round"
                (click)="approve()" nbButton status="primary" size="small" class="save-button-size"
                [disabled]="invoiceGeneratingCheckLoading">
                Update order and generate invoice
            </button>
            <button *ngIf="isGenerated" (click)="onPrintInvoice()" shape="semi-round" nbButton status="primary"
                size="small" class="save-button-size" [disabled]="!isLabDocket && !isInvoice && !patientStatment">
                Print invoice
            </button>
        </div>
    </nb-card-footer>
</nb-card>