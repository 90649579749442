<section class="position-relative">

    <nb-card class="modal-card">
        <nb-card-header>
            <div class="w-100">
                <div class="modal-title d-flex justify-content-between">
                    Find Order
                    <button shape="semi-round" *ngIf="scanedQr" (click)="navigateTo(orderId)" nbButton status="primary"
                        size="small" class="save-button-size">
                        Order Details
                    </button>
                </div>
                <div class="modal-desc">
                    Scan barcode to open an order
                </div>
                <input #search autofocus (change)="getSearchString($event)" class="fake-scanner-input"
                    (focus)="onInputFocus()" (blur)="onInputBlur()" [(ngModel)]="searchParam" nbInput fieldSize="small"
                    placeholder="Search">

                <div class="line"></div>
            </div>
        </nb-card-header>
        <nb-card-body class="d-flex align-items-center" (click)="focus()" *ngIf="!searchParam">
            <div class="check-is-focus" *ngIf="!inputFocused" (click)="focus()">
                <div class="text-to-overlay-focus">
                    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" data-name="Слой 1" viewBox="0 0 24 24"
                        id="click">
                        <path fill="none" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
                            d="m16.519 7.566 2.56-2.56M17.289 9.545h3.62M16.434 11.489l2.56 2.559M14.575 6.711v-3.62M12.596 7.481l-2.56-2.56M14.014 9.986l-9.467 3.098 2.1 2.099-3.556 3.557 1.085 1.084M11.804 16.741l-.888 2.712-2.099-2.1-3.557 3.556-1.084-1.085M14.014 9.986l-.956 2.921" />
                        <path fill="#00f" d="M12.92 15.259a.689.689 0 0 1-.95 0 .672.672 0 1 1 .95 0Z" />
                    </svg>
                    <br>
                    Please click on this area to activate the scanner
                </div>
            </div>
            <div class="row m-0 w-100 h-100 align-items-center scanner-barcode">
                <div class="anim-box center spacer">
                    <div></div>
                    <div class="scanner" [ngClass]="{'active' : inputFocused}"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-lg"></div>
                    <div class="anim-item anim-item-lg"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-lg"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-md"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-md"></div>
                    <div class="anim-item anim-item-lg"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-lg"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-lg"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-lg"></div>
                    <div class="anim-item anim-item-sm"></div>
                    <div class="anim-item anim-item-md"></div>
                </div>
            </div>
            <div class="row m-0 w-100 h-100 align-items-center justify-content-center" *ngIf="searchParam">
                <ngx-generic-barcode [isConfig]="false" [code]="scanedQr"> </ngx-generic-barcode>
            </div>
        </nb-card-body>
        <nb-card-body class="d-flex align-items-center"  *ngIf="searchParam">
            <div class="row m-0 w-100 h-100 align-items-center justify-content-center">
                <ngx-generic-barcode  [isConfig]="false" [code]="scanedQr"> </ngx-generic-barcode>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="line"></div>
            <div class="close-modal-container" *ngIf="!isSearchOrderByAutomations">
                <button shape="semi-round" (click)="decline()" class="btn-shadow mr-2 close-button-size" nbButton
                    size="small" status="basic">
                    Close
                </button>
            </div>
            <div class="close-modal-container" *ngIf="isSearchOrderByAutomations">
                <button shape="semi-round" (click)="decline()" class="btn-shadow outline close-button-size" nbButton
                    size="small" status="basic">
                    Close
                </button>
                <button shape="semi-round" (click)="refreshCode()" class="btn-shadow close-button-size" nbButton
                    size="small" status="basic">
                    Save and add more
                </button>

            </div>
        </nb-card-footer>
    </nb-card>
</section>