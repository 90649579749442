<form class="form-horizontal" [formGroup]="form">
    <nb-card class="modal-card"   [nbSpinner]="isLoading" nbSpinnerStatus="info">
        <nb-card-header>
            <div class="close-modal-icon" (click)="close()">
                <img src="../../../../assets/images/icons/cancel.svg" alt="">
            </div>
            <div class="modal-title text-left">{{!isEdit ? 'Create' : 'Update'}} location</div>
        </nb-card-header>
        <nb-card-body>





            <!-- 
            postCode: string | undefined;
         -->





        <div class="create-form-body">
            <div class="logo-container">
                <div class="left ">
                    <img id="img-uploaded" [src]="locationModelDto.logoUrl? locationModelDto.logoUrl : 'http://placehold.it/350x350'" alt="your image" />
                </div>
                <div class="right w-100">
                        <input  [(ngModel)]="locationModelDto.logoUrl" formControlName="logoUrl" id="logoUrl" 
                        type="text" nbInput fullWidth fieldSize="small" placeholder="Image Path">
                        <span class="file-wrapper mt-2 d-flex align-items-center">
                            <input  (change)="upload($event.target.files)"  type="file"  name="photo" id="imgInp" class="uploader" />
                            <button shape="semi-round" nbButton status="primary" size="tiny" class="save-button-size sm">Upload Image</button>
                            <div class="name-file">{{getNameWithUrl( locationModelDto.logoUrl)}}</div>
                        </span>
                </div>
            </div>




            <div class="field-group">

            <div class="form-modal-group" [ngClass]="displayFieldCss('name')">
                <label class="label-name" for="name">Name</label>
                <input [(ngModel)]="locationModelDto.name" formControlName="name" id="name" 
                type="text" nbInput fullWidth fieldSize="small" placeholder="Greatlab">
                <app-field-error-display [displayError]="isFieldValid('name')" errorMsg="Please inform your company name">
                </app-field-error-display>
            </div>

            <div class="form-modal-group" [ngClass]="displayFieldCss('idhLaboratoryAccountNumber')">
                <label class="label-name" for="name">Lab Reference</label>
                <input [(ngModel)]="locationModelDto.idhLaboratoryAccountNumber" formControlName="idhLaboratoryAccountNumber" id="idhLaboratoryAccountNumber" 
                type="text" nbInput fullWidth fieldSize="small" placeholder="">
                <app-field-error-display [displayError]="isFieldValid('idhLaboratoryAccountNumber')" errorMsg="Please inform your lab reference">
                </app-field-error-display>
            </div>

            </div>


            <div class="field-group">
                <div class="form-modal-group" [ngClass]="displayFieldCss('email')">
                    <label class="label-name" for="email">Email</label>
                    <input [(ngModel)]="locationModelDto.email" formControlName="email" id="email" type="text" nbInput fullWidth fieldSize="small" placeholder="company@greatlab.io">
                    <app-field-error-display [displayError]="isFieldValid('email')" errorMsg="Please inform your email">
                    </app-field-error-display>
                </div>


                <div class="form-modal-group " [ngClass]="displayFieldCss('billingEmail')">
                    <label class="label-name" for="billingEmail">Billing Email</label>
                    <input [(ngModel)]="locationModelDto.billingEmail" formControlName="billingEmail" id="billingEmail"
                        type="text" nbInput fullWidth fieldSize="small" placeholder="company@greatlab.io">
                    <app-field-error-display [displayError]="isFieldValid('billingEmail')"
                        errorMsg="Please inform your billingEmail">
                    </app-field-error-display>
                </div>
            </div>





            <div class="field-group">
                <div class="form-modal-group" [ngClass]="displayFieldCss('address')">
                    <label class="label-name" for="country">Address</label>
                    <input [(ngModel)]="locationModelDto.address" formControlName="address" id="address" type="text" nbInput fullWidth fieldSize="small"placeholder="16 Elgin Rd, Ballsbridge, Dublin 4">
                    <app-field-error-display [displayError]="isFieldValid('address')" errorMsg="Please inform address">
                    </app-field-error-display>
                </div>

                <div class="form-modal-group " [ngClass]="displayFieldCss('address2')">
                    <label class="label-name" for="address2">Address Line 2</label>
                    <input [(ngModel)]="locationModelDto.address2" formControlName="address2" id="address"
                        type="text" nbInput fullWidth fieldSize="small"
                        placeholder="16 Elgin Rd, Ballsbridge, Dublin 4">
                    <app-field-error-display [displayError]="isFieldValid('address2')"
                        errorMsg="Please inform address">
                    </app-field-error-display>
                </div>
            </div>

            <div class="field-group">

                <div class="form-modal-group " [ngClass]="displayFieldCss('town')">
                    <label class="label-name" for="town">Town</label>
                    <input [(ngModel)]="locationModelDto.town" formControlName="town" id="town" type="text" nbInput
                        fullWidth fieldSize="small" placeholder="">
                    <app-field-error-display [displayError]="isFieldValid('town')" errorMsg="Please inform town">
                    </app-field-error-display>
                </div>


                <div class="form-modal-group " [ngClass]="displayFieldCss('city')">
                    <label class="label-name" for="city">City</label>
                    <input [(ngModel)]="locationModelDto.city" formControlName="city" id="city" type="text" nbInput
                        fullWidth fieldSize="small" placeholder="....">
                    <app-field-error-display [displayError]="isFieldValid('city')" errorMsg="Please inform city">
                    </app-field-error-display>
                </div>
            </div>


            <div class="field-group"> 
                <div class="form-modal-group" [ngClass]="displayFieldCss('country')">
                    <label class="label-name" for="country">Country</label>
                    <ng-select appendTo="body" [loading]="isCountriesLoading" placeholder="Select country"
                               [multiple]="false" [(ngModel)]="locationModelDto.country" formControlName="country"
                               [searchable]="true" [clearable]="false"(change)="onCountryChange(locationModelDto.country)">
                        <ng-option *ngFor="let country of countries" [value]="country.countryName">
                            {{country.countryName}}
                        </ng-option>
                    </ng-select>
                    <app-field-error-display [displayError]="isFieldValid('country')" errorMsg="Please inform country">
                    </app-field-error-display>
                  </div>
                <div class="form-modal-group " [ngClass]="displayFieldCss('postCode')">
                    <label class="label-name" for="postCode">PostCode</label>
                    <input [(ngModel)]="locationModelDto.postCode" formControlName="postCode" id="postCode"
                        type="text" nbInput fullWidth fieldSize="small" placeholder="....">
                    <app-field-error-display [displayError]="isFieldValid('postCode')"
                        errorMsg="Please inform postCode">
                    </app-field-error-display>
                </div>
            </div>
           
        




            <div class="form-modal-group" [ngClass]="displayFieldCss('phone')">
                <label class="label-name" for="name">Phone</label>
                <!-- <input [(ngModel)]="locationModelDto.phone" formControlName="phone" id="phone" type="text" nbInput fullWidth fieldSize="small" placeholder=" +353 1 344 1111"> -->
             
             
                <ngx-intl-tel-input [(ngModel)]="phoneNubmer" [cssClass]="'phone-number-input'"
                [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryName" [maxLength]="15" [phoneValidation]="true"
                [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone"
                formControlName="phone">
                 </ngx-intl-tel-input>
             
             

             
             
             
             
             
             
             
             
                <app-field-error-display [displayError]="isFieldValid('phone')" errorMsg="Please inform phone">
                </app-field-error-display>
            </div>
            <div class="form-modal-group" [ngClass]="displayFieldCss('taxNumber')">
                <label class="label-name" for="name">Tax number</label>
                <input [(ngModel)]="locationModelDto.taxNumber" formControlName="taxNumber" id="taxNumber" type="text" nbInput fullWidth fieldSize="small" placeholder="">
                <app-field-error-display [displayError]="isFieldValid('taxNumber')" errorMsg="Please inform tax number">
                </app-field-error-display>
            </div>
            <div class="form-modal-group" [ngClass]="displayFieldCss('scanHubEmail')">
                <label class="label-name" for="name">ScanHub email</label>
                <input [(ngModel)]="locationModelDto.scanHubEmail" formControlName="scanHubEmail" id="scanHubEmail" type="text" nbInput fullWidth fieldSize="small" placeholder="scunhub@gmail.com">
                <app-field-error-display [displayError]="isFieldValid('scanHubEmail')" errorMsg="Please inform scanhub email">
                </app-field-error-display>
            </div>

            <div class="field-group">
                 <div class="form-modal-group" [ngClass]="displayFieldCss('currency')">
                <label class="label-name" for="currency">Currency</label>
                  <ng-select appendTo="body" [loading]="isCurrenciesLoading" placeholder="All currencies"
                    [multiple]="false" [(ngModel)]="locationModelDto.currency" formControlName="currency"
                    [searchable]="true" [clearable]="true">
                    <ng-option *ngFor="let currency of currencies" [value]="currency.currencyCode">
                       {{currency.currencyCode}}         {{currency.currencySymbol}}
                    </ng-option>
                    </ng-select>
                    <app-field-error-display [displayError]="isFieldValid('currency')" errorMsg="Please inform currency">
                    </app-field-error-display>
                  </div>

                  <div class="form-modal-group" [ngClass]="displayFieldCss('dateFormat')">
                    <label class="label-name" for="dateFormat">DateFormat</label>
                      <ng-select appendTo="body" [loading]="isDateFormatsLoading" placeholder="All date formats"
                        [multiple]="false" [(ngModel)]="locationModelDto.dateFormat" formControlName="dateFormat"
                        [searchable]="true" [clearable]="true">
                        <ng-option *ngFor="let dateFormat of dateFormats" [value]="dateFormat.formatPattern">
                           {{dateFormat.formatPattern}} 
                        </ng-option>
                        </ng-select>
                        <app-field-error-display [displayError]="isFieldValid('dateFormat')" errorMsg="Please inform date format">
                        </app-field-error-display>
                    </div>
            </div>
            <div class="field-group">
            <div class="form-modal-group" [ngClass]="displayFieldCss('teethType')">
                <label class="label-name" for="teethType">Teeth Charting System</label>
                <ng-select appendTo="body" placeholder="All teeth charting systems"
                           [multiple]="false" [(ngModel)]="locationModelDto.teethType" formControlName="teethType"
                           [searchable]="true" [clearable]="true">
                  <ng-option *ngFor="let teethType of teethChartingSystems" [value]="teethType.id">
                    {{teethType.name}}
                  </ng-option>
                </ng-select>
                <app-field-error-display [displayError]="isFieldValid('teethType')" errorMsg="Please inform teeth charting system">
                </app-field-error-display>
              </div>

              <div class="form-modal-group" [ngClass]="displayFieldCss('shadeSystem')">
                <label class="label-name" for="shadeSystem">Teeth Shading System</label>
                <ng-select appendTo="body" placeholder="Shade Guide Preference"
                           [multiple]="false" [(ngModel)]="locationModelDto.shadeSystem" formControlName="shadeSystem"
                           [searchable]="true" [clearable]="true">
                  <ng-option *ngFor="let shadeSystem of teethShadeSystems" [value]="shadeSystem.id">
                    {{shadeSystem.name}}
                  </ng-option>
                </ng-select>
                <app-field-error-display [displayError]="isFieldValid('shadeSystem')" errorMsg="Please inform teeth shading system">
                </app-field-error-display>
              </div>
            </div>
            <!-- <div class="form-modal-group">
                <label class="label-name" for="">Email</label>
                <input type="text" nbInput fullWidth fieldSize="small" placeholder="email@email.com">
            </div> -->
                <!-- <div class="form-modal-group">
                    <label class="label-name" for="">Country</label>
                    <input type="text" nbInput fullWidth fieldSize="small" placeholder="Ireland">
                </div>
                <div class="form-modal-group">
                    <label class="label-name" for="">Phone</label>
                    <input type="text" nbInput fullWidth fieldSize="small" placeholder="00000-000-00">
                </div> -->
        </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="close-modal-container">
                <button shape="semi-round" (click)="close()" class="btn-shadow mr-2 close-button-size" nbButton size="small" status="basic">
                    Close
                  </button>
                <button *ngIf="!isEdit" shape="semi-round"  (click)="onSubmit()" nbButton status="primary" size="small" class="save-button-size">
                    Create 
                </button>
                <button *ngIf="isEdit" shape="semi-round"  (click)="onUpdate()" nbButton status="primary" size="small" class="save-button-size">
                    Update 
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</form>


