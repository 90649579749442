export enum CategoryNameEnumModel {
    'category_1' =	'Crown & Bridge',
    'category_2' =	'Prosthetics',
    'category_3' =	'Model',
    'category_4' =	'Implant Restoration',
    'category_5' =	'Implant',
    'category_6' =	'Orthodontics',
    'category_7' = 'Additionals',
    'category_8' =	'All-on-X',
    'category_9' =	'Services',
  }
  

  