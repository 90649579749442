import { AuthServiceProxy, UserDTO } from '../../../shared/service-proxies/event-service-proxy';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class OrderSerice {

    constructor(
        private _authService: AuthServiceProxy,
        private router: Router
    ) {

    }

    public checkIsTeethActive(type: any, product: any) {
        let teeth = product.arch ? product.arch.split(',') : [];
        let result = false;
        if (type == 'Lower') {
            result = teeth.find((x: any) => x == type) ? true : false;
        } else if (type == 'Upper') {
            result = teeth.find((x: any) => x == type) ? true : false;
        }
        return result;
    }

    public checkIsMultiOrderType(typeId: any) {
        switch (typeId) {
            case 1:
                return true;
                break;
            case 2:
                return true;
                break;
            case 3:
                return false;
                break;
            case 4:
                return true;
                break;
            case 5:
                return true;
                break;
            case 6:
                return false;
                break;
            case 8:
                return true;
                break;
            case 7:
                return true;
                break;
            case 9:
                return true;
                break;
            default:
                // 
                break;
        }
    }


}
