
<nb-card class="modal-card"  *ngIf="inviteUrl">
    <nb-card-header>
        <div class="close-modal-icon" (click)="closeLink()">
            <img src="../../../../assets/images/icons/cancel.svg" alt="">
        </div>
        <div class="modal-title text-left">
            {{title}}
            <!-- Invite link  -->
        </div>
        <div class="modal-desc text-left">
            {{desc}}

            <!-- Share setup link with the client (Please note expires after 24 hours) -->
        </div>
    </nb-card-header>
    <nb-card-body>
     
    <div class="create-form-body">
     
       
     
        
     


        <div class="d-flex w-100">
            <div class="form-modal-group w-100 mr-1" >
                <label class="label-name" for="url">URL</label>
                <input [(ngModel)]="inviteUrl"  type="text" nbInput fullWidth fieldSize="small">
            </div>
        </div>


   

    </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="close-modal-container">
            <button shape="semi-round" (click)="closeLink()" class="btn-shadow mr-2 close-button-size" nbButton size="small" status="basic">
                Close
              </button>
        </div>
    </nb-card-footer>
</nb-card>
