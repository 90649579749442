<div class="d-flex justify-content-center teeth-section">
  <div class="upper-arch d-flex justify-content-start flex-column">
    <div class="teeth-margin">
      <svg
        (click)="clickItem($event)"
        width="100%"
        height="100%"
        viewBox="0 0 229 196"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(1)"
          [ngClass]="{ 'dp-selected': isTeethChecked(1) }"
          d="M17 148C27.4775 147.36 38.6619 156.228 36.5 166.5C34.6585 175.25 25.8601 179.705 17 178.5C7.20857 177.169 1.49996 169 1.5 158.5C1.50004 148 9.70236 148.446 17 148Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(2)"
          [ngClass]="{ 'dp-selected': isTeethChecked(2) }"
          d="M23.5 117.5C32.5 118.5 39.6619 124.728 37.5 135C35.6585 143.75 29.3601 148.737 20.5 147.533C10.7086 146.202 6.99995 141 7 127.5C7.00005 114 16.2335 116.693 23.5 117.5Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(3)"
          [ngClass]="{ 'dp-selected': isTeethChecked(3) }"
          d="M20.7757 83.173C26.4373 75.8467 46 91.6344 46 103.551C46 113.008 37.4449 118.08 28.1328 116.806C13.9442 114.865 11.5 106 13.4187 99.038C15.3373 92.076 15.5 90 20.7757 83.173Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(4)"
          [ngClass]="{ 'dp-selected': isTeethChecked(4) }"
          d="M51.4328 80.066C54.5893 74.5727 43.5417 62.4873 43.5417 62.4873C38.1468 56.6973 26.5473 58.3355 23.5508 65.7833C21.3407 71.2767 26.0762 79.2608 32.4941 82.8127C39.0692 86.4517 48.2763 85.5594 51.4328 80.066Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(5)"
          [ngClass]="{ 'dp-selected': isTeethChecked(5) }"
          d="M54.6066 44.473C52.151 42.4387 44.1666 38.3702 38.2362 43.4558C31.3713 49.3429 36.6998 56.4048 42.4608 60.2385C47.3811 63.5126 55.1347 64.307 57.7751 60.2385C60.0148 56.7872 59.3099 48.3692 54.6066 44.473Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(6)"
          [ngClass]="{ 'dp-selected': isTeethChecked(6) }"
          d="M63.5637 20.7789C58.9905 19.103 55.1685 20.1805 51.6986 23.5396C47.5158 27.5887 45.4836 33.5571 48.3086 37.8951C52.2637 43.9685 66.3887 48.9377 70.9087 43.9685C74.2988 40.2415 69.2137 22.8494 63.5637 20.7789Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(7)"
          [ngClass]="{ 'dp-selected': isTeethChecked(7) }"
          d="M84.6016 9.75509C80.9584 7.19216 77.3272 7.23935 73.4668 9.38532C68.8135 11.9721 65.6056 16.7634 67.0743 21.2641C69.1305 27.5654 80.432 35.2985 85.601 31.9787C89.4779 29.4888 89.1026 12.9215 84.6016 9.75509Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(8)"
          [ngClass]="{ 'dp-selected': isTeethChecked(8) }"
          d="M111.783 3.82311C111.256 0.740979 105.99 0.740992 98.0907 1.25464C91.2448 1.6998 86.5666 4.36195 87.0318 8.44634C87.5585 13.0696 93.606 27.2457 102.83 25.9119C112.673 24.4886 112.309 6.90525 111.783 3.82311Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(9)"
          [ngClass]="{ 'dp-selected': isTeethChecked(9) }"
          d="M112.717 3.82311C113.244 0.740979 118.51 0.740992 126.409 1.25464C133.255 1.6998 137.933 4.36195 137.468 8.44634C136.942 13.0696 130.894 27.2457 121.67 25.9119C111.827 24.4886 112.191 6.90525 112.717 3.82311Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(10)"
          [ngClass]="{ 'dp-selected': isTeethChecked(10) }"
          d="M139.898 9.75509C143.542 7.19216 147.173 7.23935 151.033 9.38532C155.687 11.9721 158.894 16.7634 157.426 21.2641C155.369 27.5654 144.068 35.2985 138.899 31.9787C135.022 29.4888 135.397 12.9215 139.898 9.75509Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(11)"
          [ngClass]="{ 'dp-selected': isTeethChecked(11) }"
          d="M160.936 20.7789C165.51 19.103 169.331 20.1805 172.801 23.5396C176.984 27.5887 179.016 33.5571 176.191 37.8951C172.236 43.9685 158.111 48.9377 153.591 43.9685C150.201 40.2415 155.286 22.8494 160.936 20.7789Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(12)"
          [ngClass]="{ 'dp-selected': isTeethChecked(12) }"
          d="M169.893 44.473C172.349 42.4387 180.333 38.3702 186.264 43.4558C193.129 49.3429 187.8 56.4048 182.039 60.2385C177.119 63.5126 169.365 64.307 166.725 60.2385C164.485 56.7872 165.19 48.3692 169.893 44.473Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(13)"
          [ngClass]="{ 'dp-selected': isTeethChecked(13) }"
          d="M173.067 80.066C169.911 74.5727 180.958 62.4873 180.958 62.4873C186.353 56.6973 197.953 58.3355 200.949 65.7833C203.159 71.2767 198.424 79.2608 192.006 82.8127C185.431 86.4517 176.224 85.5594 173.067 80.066Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(14)"
          [ngClass]="{ 'dp-selected': isTeethChecked(14) }"
          d="M203.724 83.173C198.063 75.8467 178.5 91.6344 178.5 103.551C178.5 113.008 187.055 118.08 196.367 116.806C210.556 114.865 213 106 211.081 99.038C209.163 92.076 209 90 203.724 83.173Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(15)"
          [ngClass]="{ 'dp-selected': isTeethChecked(15) }"
          d="M201 117.5C192 118.5 184.838 124.728 187 135C188.842 143.75 195.14 148.737 204 147.533C213.791 146.202 217.5 141 217.5 127.5C217.5 114 208.267 116.693 201 117.5Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
        <path
          class="tooth"
          [attr.data-title]="convertToPalmer(16)"
          [ngClass]="{ 'dp-selected': isTeethChecked(16) }"
          d="M207.5 148C197.023 147.36 185.838 156.228 188 166.5C189.842 175.25 198.64 179.705 207.5 178.5C217.291 177.169 223 169 223 158.5C223 148 214.798 148.446 207.5 148Z"
          stroke="#7A7A7A"
          stroke-width="2"
        />
      </svg>
    </div>
    <div class="mt-3" class="teeth-chips">
      <mat-chip-list #chipList>
        <mat-chip
          class="mat_chip_tooth_without"
          color="accent"
          *ngFor="let s of selectedUpper; let i = index"
          selectable="false"
          removable="true"
          (removed)="removeSelected(selectedUpper[i].tooth)"
        >
          {{ mainConverterWithoutPins(s.tooth) }}
          <nb-icon status="danger" matChipRemove icon="close-outline"></nb-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>

  <div class="mt-3 lower-arch d-flex justify-content-start flex-column">
    <div class="teeth-margin">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 229 196"
        fill="none"
        (click)="clickItem($event)"
      >
        <g
          id="lower_arch"
          data-name="lower arch"
          transform="translate(1.001 1)"
        >
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(32)"
            [ngClass]="{ 'dp-selected': isTeethChecked(32) }"
            d="M35.9231 14.5757C34.2715 24.4523 26.8826 30.2397 18.4615 30.2397C8.85769 30.2396 1 26.0627 1 19.797C1 13.5314 10.6038 1.00002 18.4615 1.00002C26.3192 1.00002 36.7961 9.3544 35.9231 14.5757Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(31)"
            [ngClass]="{ 'dp-selected': isTeethChecked(31) }"
            d="M40.9158 46.0114C39.0287 57.1583 32.5811 63.69 22.9591 63.69C11.9856 63.69 6 58.9757 6 51.9043C6 44.8328 11.9856 30.69 22.9591 30.69C33.9327 30.69 41.9134 40.1187 40.9158 46.0114Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(30)"
            [ngClass]="{ 'dp-selected': isTeethChecked(30) }"
            d="M16.7154 68.8778C12.9021 74.0992 12.5666 80.3214 14.9693 86.6305C18.8297 96.767 25.4463 100.957 34.1768 96.029C41.0716 92.137 45.1128 79.3206 43.7806 73.055C41.6748 63.1505 37.4803 64.4569 28.9384 64.7007C24.3022 64.8331 19.7663 64.7003 16.7154 68.8778Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(29)"
            [ngClass]="{ 'dp-selected': isTeethChecked(29) }"
            d="M29.6835 99.3268C25.4704 103.575 21.7159 108.019 23.1307 114.506C24.4549 120.578 36.7899 120.425 42.789 114.506C47.6166 109.744 50.1607 100.339 48.5227 96.2908C46.8847 92.2429 35.4317 93.531 29.6835 99.3268Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(28)"
            [ngClass]="{ 'dp-selected': isTeethChecked(28) }"
            d="M40.6434 117.824C35.9469 122.545 31.7615 127.484 33.3386 134.693C34.8148 141.441 48.5654 141.27 55.253 134.693C60.6346 129.4 59.1578 122.322 57.3318 117.824C55.5058 113.326 47.0514 111.383 40.6434 117.824Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(27)"
            [ngClass]="{ 'dp-selected': isTeethChecked(27) }"
            d="M67.7638 149.754C70.1053 143.788 70.4649 135.688 68.4081 132.688C66.6446 130.115 53.9313 134.144 50.5516 142.521C47.8319 149.263 51.2623 154.385 54.1656 157.243C57.0689 160.1 64.569 157.894 67.7638 149.754Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(26)"
            [ngClass]="{ 'dp-selected': isTeethChecked(26) }"
            d="M65.6078 157.641C64.6576 163.324 67.2587 165.819 71.7193 168.084C77.0639 170.797 82.2188 173.251 84.8153 167.039C87.4345 160.774 86.5615 149.095 81.323 146.154C76.9577 143.703 66.4807 152.419 65.6078 157.641Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(25)"
            [ngClass]="{ 'dp-selected': isTeethChecked(25) }"
            d="M105.902 171.604C105.519 175.057 103.686 179.341 94.5 178.19C85.3145 177.039 86.0002 173.852 86.0001 165.849C86 161.245 93.6547 148.854 98.2474 149.735C102.84 150.615 106.667 164.698 105.902 171.604Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(24)"
            [ngClass]="{ 'dp-selected': isTeethChecked(24) }"
            d="M107.098 171.604C107.481 175.057 109.314 179.341 118.5 178.19C127.686 177.039 127 173.852 127 165.849C127 161.245 119.345 148.854 114.753 149.735C110.16 150.615 106.333 164.698 107.098 171.604Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(23)"
            [ngClass]="{ 'dp-selected': isTeethChecked(23) }"
            d="M147.392 157.641C148.342 163.324 145.741 165.819 141.281 168.084C135.936 170.797 130.781 173.251 128.185 167.039C125.566 160.774 126.439 149.095 131.677 146.154C136.042 143.703 146.519 152.419 147.392 157.641Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(22)"
            [ngClass]="{ 'dp-selected': isTeethChecked(22) }"
            d="M145.236 149.754C142.895 143.788 142.535 135.688 144.592 132.688C146.355 130.115 159.069 134.145 162.448 142.521C165.168 149.263 161.738 154.385 158.834 157.243C155.931 160.1 148.431 157.894 145.236 149.754Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(21)"
            [ngClass]="{ 'dp-selected': isTeethChecked(21) }"
            d="M172.357 117.824C177.053 122.545 181.238 127.484 179.661 134.693C178.185 141.441 164.435 141.27 157.747 134.693C152.365 129.4 153.842 122.322 155.668 117.824C157.494 113.326 165.949 111.383 172.357 117.824Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(20)"
            [ngClass]="{ 'dp-selected': isTeethChecked(20) }"
            d="M183.317 99.3268C187.53 103.575 191.284 108.019 189.869 114.506C188.545 120.578 176.21 120.425 170.211 114.506C165.383 109.744 162.839 100.339 164.477 96.2908C166.115 92.2429 177.568 93.531 183.317 99.3268Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(19)"
            [ngClass]="{ 'dp-selected': isTeethChecked(19) }"
            d="M196.285 68.8779C200.098 74.0992 200.433 80.3215 198.031 86.6306C194.17 96.7671 187.554 100.957 178.823 96.029C171.928 92.1371 167.887 79.3207 169.219 73.055C171.325 63.1505 175.52 64.4569 184.062 64.7008C188.698 64.8331 193.234 64.7004 196.285 68.8779Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(18)"
            [ngClass]="{ 'dp-selected': isTeethChecked(18) }"
            d="M172.084 46.0114C173.971 57.1583 180.419 63.69 190.041 63.69C201.014 63.69 207 58.9757 207 51.9043C207 44.8328 201.014 30.69 190.041 30.69C179.067 30.69 171.087 40.1187 172.084 46.0114Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
          <path
            class="tooth"
            [attr.data-title]="convertToPalmer(17)"
            [ngClass]="{ 'dp-selected': isTeethChecked(17) }"
            d="M177.077 14.5757C178.728 24.4523 186.117 30.2397 194.538 30.2397C204.142 30.2396 212 26.0627 212 19.797C212 13.5314 202.396 1.00002 194.538 1.00002C186.681 1.00002 176.204 9.3544 177.077 14.5757Z"
            stroke="#7A7A7A"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
    <div class="mt-3" class="teeth-chips">
      <mat-chip-list #chipList>
        <mat-chip
          class="mat_chip_tooth_without"
          color="accent"
          *ngFor="let s of selectedLower; index as i"
          selectable="false"
          removable="true"
          (removed)="removeSelected(selectedLower[i].tooth)"
        >
          {{ mainConverterWithoutPins(s.tooth) }} 
          <nb-icon status="danger" matChipRemove icon="close-outline"></nb-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
