import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-print-template',
  templateUrl: './print-template.component.html',
  styleUrls: ['./print-template.component.scss']
})
export class PrintTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
