import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stlFile'
})
export class StlFilePipe implements PipeTransform {

  transform(url: string): boolean {
    return this.isStlFile(url);
  }

  private isStlFile(url: string): boolean {
    const extension = url.split('.').pop()?.toLowerCase();
    return extension === 'stl';
  }

}
