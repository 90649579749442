import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthServiceProxy, ClientListModel, ClientListModelPagedListDto, DentistModel, DentistModelPagedListDto, PracticeAcessModel, PracticeAssignedUsersModel, PracticeCreateNewModel, PracticeProfileClientModelListDto, PracticeServiceProxy } from '../../../../shared/service-proxies/event-service-proxy';
import { finalize } from 'rxjs/operators';
import { log } from 'console';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-assing-clients-controle',
  templateUrl: './assing-clients-controle.component.html',
  styleUrls: ['./assing-clients-controle.component.scss']
})
export class AssingClientsControleComponent implements OnInit {
  selectClientsId: any;
  selectClientsModel: any;
  @Input() practice: any;
  @Input() isEdit: any;
  @Input() companyId: any;
  @Input() practiceNew: any;
  @Output() newItemEventDentist = new EventEmitter<any>();
  searchInput: string = "";
  isDentistLoading: boolean = false;
  practiceNewModel: PracticeCreateNewModel = new PracticeCreateNewModel();
  practiceAssignModelClientsList: any[] =[];
  showClistentControle: boolean = false;
  dentistList: ClientListModel[] = [];
  order: any = 'all';
  patient: any = 'all';
  constructor(
    private _practiceService: PracticeServiceProxy,
    private toastrService: NbToastrService,
    private authService: AuthServiceProxy
   
  
  ) { }

  ngOnInit(): void {
    this.getDentistist();
  }
  selectClient(e: any) {
    this.selectClientsModel = this.dentistList.find(x => x.userId == e);
    if (this.selectClientsModel.roleName == 'Manager') {
      this.addAssignModel();
    }
  }


searchDentist() {
  this.selectClientsModel = null;
  this.selectClientsId = null;
    this.getDentistist();
}

  getDentistist() {
    this.isDentistLoading = true;
    this._practiceService.getClientsByCompanyId(this.companyId,this.practiceNew.locationId, 1, 10, this.searchInput).pipe(
      finalize(() => {
        this.isDentistLoading = false;
      }))
      .subscribe(
        (resp: ClientListModelPagedListDto) => {
          this.dentistList = resp.items;
          if (this.practice?.clients?.length > 0) {
            this.practiceAssignModelClientsList = this.practice.clients;
          }
        },
        (error: any) => { }
      )

  }

  removeClients(item: any) {
    if (this.isEdit) {
      let clientAccessItem: PracticeAcessModel = new PracticeAcessModel();
      clientAccessItem.userId = item.id;
      clientAccessItem.practiceId = this.practice.id;
      this.authService.removeAcessToPractice(clientAccessItem).pipe(
        finalize(() => {
        }))
        .subscribe(
          (resp: any) => {
            this.practice.clients = this.practice.clients.filter(x => x !== item);
          },
          (error: any) => { }
        )
    } else {
      this.practiceAssignModelClientsList = this.practiceAssignModelClientsList.filter(x => x !== item);
    }
  }

addClients() {

  let clients: any = {};
  clients.userId = this.selectClientsModel.userId;
  clients.isOnlyOwnOrdersAcess = this.order == 'all' ? false : true;
  clients.isOnlyOwnPatientsAcess = this.patient == 'all' ? false : true;
  clients.fullName = this.selectClientsModel.fullName;
  clients.role = this.selectClientsModel.roleName;
  this.practiceAssignModelClientsList.push(clients);
  
  let clientAccessItem: PracticeAcessModel = new PracticeAcessModel();
  clientAccessItem.userId = this.selectClientsModel.userId;
  clientAccessItem.isOnlyOwnOrdersAcess = this.order == 'all' ? false : true;
  clientAccessItem.isOnlyOwnPatientsAcess = this.patient == 'all' ? false : true;
  clientAccessItem.practiceId = this.practice.id;
  this.authService.addAcessToPractice(clientAccessItem).pipe(
    finalize(() => {}))
    .subscribe(
      (resp: any) => {
        this.showClistentControle = false;
        this.selectClientsModel = {};
      },
      (error: any) => { }
    )
}



  addAssignModel() {
    if (this.isEdit) {
      if (this.practice?.clients?.find(x => x.userId == this.selectClientsModel.userId)) {
        const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
        this.toastrService.danger('', "Client already added", { icon: iconConfig });
        return false;
      }
      this.addClients();
    } else {
      if (this.practiceAssignModelClientsList.find(x => x.userId == this.selectClientsModel.userId)) {
        const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
        this.toastrService.danger('', "Client already added", { icon: iconConfig });
        return false;
      }
      let clients: any = {};
      clients.userId = this.selectClientsModel.userId;
      clients.isOnlyOwnOrdersAcess = this.order == 'all' ? false : true;
      clients.isOnlyOwnPatientsAcess = this.patient == 'all' ? false : true;
      clients.fullName = this.selectClientsModel.fullName;
      clients.role = this.selectClientsModel.roleName;
      this.practiceAssignModelClientsList.push(clients);
      this.showClistentControle = false;
      this.selectClientsModel = {};
      this.updateEmitEmitter();
    }
  }


updateEmitEmitter() {
  let result: any = {
    status: true,
    data: this.practiceAssignModelClientsList
  }
  this.newItemEventDentist.emit(result)
}


  options = [
    { value: 'This is value 1', label: 'Option 1' },
    { value: 'This is value 2', label: 'Option 2' },
    { value: 'This is value 3', label: 'Option 3' },
    { value: 'This is value 4', label: 'Option 4' },
  ];

  permissionList = [
    { value: 'own', label: 'Own' },
    { value: 'all', label: 'All' },
  ];
}
