import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbToastrService, NbDialogService, NbDialogRef, NbIconConfig } from '@nebular/theme';
import { finalize } from 'rxjs/operators';
import { AuthServiceProxy, PracticeServiceProxy, CompanyServiceProxy, LocationServiceProxy, PracticeAcessModel, PracticeModelListDto, PracticeModel, DoctorServiceProxy, ResultMessage } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from '../../../@core/service';
import { ModalCreateMemberComponent } from '../../settings/modal-create-member/modal-create-member.component';

@Component({
  selector: 'ngx-assignments-modal-edit',
  templateUrl: './assignments-modal-edit.component.html',
  styleUrls: ['./assignments-modal-edit.component.scss']
})
export class AssignmentsModalEditComponent implements OnInit {
  isPracticesLoading: boolean = false;
  user: any = {};
  isLoading: boolean =  false;
  @Input() companyId: any;
  @Input()  userModelDto: any;
  @Input()  item: any;
  @Input()  event: any;
  @Input() userDto: any;
  practicesDto: PracticeModel[] = [];
  performerNumber: any;
  order: string = 'all';
  patient: string = 'all';
  permissionList = [
    { value:  false, label: 'Own' },
    { value: true, label: 'All' },
  ];
  selectedPractice: any;
  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthServiceProxy,
    private _practiceService: PracticeServiceProxy,
    private _companyService: CompanyServiceProxy,
    private toastrService: NbToastrService,
    private _locationService: LocationServiceProxy,
    private dialogService: NbDialogService,
      private _authenticationService: AuthenticationService,
      private _doctorService: DoctorServiceProxy,
    protected dialogRef: NbDialogRef<ModalCreateMemberComponent>,
  ) {
    let currentUser = this._authenticationService.currentUserValue;
    let helper = new JwtHelperService();
    this.user = helper.decodeToken(currentUser.accessToken);
   }

  ngOnInit(): void {
    this.getAllPracticesWithLocation();
    console.log( this.event,this.userModelDto, '123');
  }
  onChangeAssign() {
   
  }

  updateAssignment() {
    let request: PracticeAcessModel = new PracticeAcessModel();
    request.userId = this.userDto.id;
    if (this.item.id) {
      request.practiceId = this.userModelDto.practiceIds.find(x => x.name == this.item.name).id;
    } else {
      request.practiceId = this.userModelDto.practiceIds.find(x => x.name == this.item.name).practiceId;
    }
    
   
    request.isOnlyOwnOrdersAcess = this.item.isOnlyOwnOrdersAcess
    request.isOnlyOwnPatientsAcess = this.item.isOnlyOwnPatientsAcess
    request.idhPerformerNumber = this.item.idhPerformerNumber 
    request.accountCode = this.item.accountCode
    request.creditLimit = this.item.creditLimit
    this._authService.updateAcessToPractice(request).pipe(
      finalize(() => {
      }))
      .subscribe(
        (resp: ResultMessage) => {
          if (resp.result) {
            const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
            this.toastrService.success('', "Success", { icon: iconConfig });
            this.dialogRef.close(true);
          } else {
            const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
            this.toastrService.danger('', resp.message, { icon: iconConfig });
          }
        },
        (error: any) => {
          const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
          this.toastrService.danger('', 'Error!', { icon: iconConfig });
         }
      )
  }
  getAllPracticesWithLocation() {
    this.isPracticesLoading = true;
    // this.loadCountries();
    this._practiceService.getAllPracticesByCompanyId(this.userModelDto.companyId).pipe(
      finalize(() => {
        this.isPracticesLoading = false;
      }))
      .subscribe(
        (resp: PracticeModelListDto) => {
        this.practicesDto = resp.items;
        this.selectedPractice = this.practicesDto.find(x => x.name == this.item.name).id
        },
        (error: any) => { }
      )
}
close() {
  this.dialogRef.close(false);
}
}
