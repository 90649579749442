import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { finalize } from 'rxjs/operators';
import { ReceivingSettingsModel, ReceivingSettingServiceProxy, OrderServiceProxy } from '../../../../shared/service-proxies/event-service-proxy';

@Component({
  selector: 'ngx-change-location',
  templateUrl: './change-location.component.html',
  styleUrls: ['./change-location.component.scss']
})
export class ChangeLocationComponent implements OnInit {
  @ViewChild('selectDropdownReceiving') selectDropdown: NgSelectComponent;
  isReceivedLoading: boolean = false;
  locationId: any;
  constructor(
    private _receivingService: ReceivingSettingServiceProxy,
    private _orderService: OrderServiceProxy
  ) { }
tmpArrayOption: any[] = [];
  ngOnInit(): void {
    this.getReceivingListOption();
  }
  getReceivingListOption() {
    this.isReceivedLoading = true;
  
    }

 
  onCloseDropdown() {
    this.selectDropdown.close();
}
  onShowDropdown() {
    this.selectDropdown.open();
  }
 
  mouseSetterType(type: any) {
   
  }

  selectReceiving(type: any) {
   

  }

  updateOrder() {
  
  }

 
  onSelectClick(event: any) {
    this.onCloseDropdown();
  }


}
