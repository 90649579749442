


import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({name: 'ngxFilterByTeeth'})


export class FilterByTeethPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  
  public transform(value: any, teethValue: any): any {
    if (!Array.isArray(value) || !teethValue) {
        return value;
      }
  
      return value.filter(item => {
        const teethArray = item.teeth.split(',').map(t => parseInt(t.trim(), 10));
        return teethArray.includes(teethValue);
      });
}
}

 