import { Component, OnInit } from '@angular/core';
import { HotglueService } from '../../../@core/service/hotglue/hotglue.service';

@Component({
  selector: 'app-hotglue-widget',
  templateUrl: './hotglue-widget.component.html',
  styleUrls: ['./hotglue-widget.component.scss']
})
export class HotglueWidgetComponent implements OnInit {
  private hotglueConfig: any;

  constructor(private hotglueService: HotglueService) {}

  ngOnInit() {
    // this.hotglueConfig = this.hotglueService.getHotglueConfig();
    this.loadWidget();
  }

  loadWidget() {
    const script = document.createElement('script');
    script.src = 'https://hotglue.xyz/widgetv2.js';
    script.async = true;
    script.onload = () => {
      console.log('Script loaded');
      if ((window as any).HotGlue) {
        console.log('HotGlue library found');
        this.initializeHotGlue();
      } else {
        console.error('HotGlue library not loaded.');
      }
    };
    script.onerror = () => {
      console.error('Failed to load the HotGlue script.');
    };
    document.body.appendChild(script);
  }

  initializeHotGlue() {
    const tenantId = 'your-tenant-id';
    const flowId = 'your-flow-id';
    const sourceId = 'your-source-id';

    (window as any).HotGlue.link(tenantId, flowId, sourceId, false, {
      hideBackButtons: true,
      listener: {
        onSourceLinked: () => {
          (window as any).HotGlue.close();
        }
      }
    });
  }

  openHotglueWidget() {
    this.initializeHotGlue();
  }
}

