
<div class="print" id="manualInvoiceDoket">
    <div class="print-page" *ngIf="invoiceIds.isDocket">
        <ngx-print-order-docket [isDocket]="true" [orderId]="invoiceIds.orderId" (loadComplete)="onOrderDocketLoad()"></ngx-print-order-docket>
    </div>
    <div class="print-break"></div>
    <div class="print-page" *ngIf="invoiceIds.isInvoice">
        <ngx-print-invoice-docket *ngIf="invoiceIds.invoiceId" [invoiceId]="invoiceIds.invoiceId" (loadComplete)="onInvoiceDocketLoad()">
        </ngx-print-invoice-docket>
    </div>
    <div class="print-break"></div>
    <div class="print-page" *ngIf="invoiceIds.isStatment">
        <ngx-print-statment-docket *ngIf="invoiceIds.statmentId" [invoiceId]="invoiceIds.statmentId"  (loadComplete)="onStatementDocketLoad()">
        </ngx-print-statment-docket>
    </div>
    <div class="print-break"></div>
</div>


