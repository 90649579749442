import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbDialogRef, NbDialogService, NbIconConfig, NbToastrService } from '@nebular/theme';
import { finalize, retry } from 'rxjs/operators';
import { BillingServiceProxy, Carrier, CreateShippingLabelsRequest, LocationModel, LocationServiceProxy, ManualInvoiceResponseModel, OrderDetailModel, OrderServiceProxy, OrderShipmentModel, PracticeServiceProxy, ProductPricePer, Service, ShipmentServiceProxy, UpdateOrderStatusModel, ValidateAddressResponse, ValidateAddressesRequest } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService, PrintService } from '../../../@core/service';
import { ORDERSTATUS, ORDERSTATUSBYDOCTOR } from '../status-order-list';
import { PaginationInstance } from 'ngx-pagination';
import { ShippingLabelPreviewModalComponent } from '../../../pages/orders/shipping-label-preview-modal/shipping-label-preview-modal.component';
import { CreateLocationModalComponent } from '../../../pages/location/create-location-modal/create-location-modal.component';
import { CreatePracticenModalComponent } from '../../../pages/practice/create-practice-modal/create-practice-modal.component';
import { CategoryNameEnumModel } from '../category-enum-modal';
import { convertFromFDI } from '../../../@core/models/TeethType';

@Component({
  selector: 'ngx-order-change-status-modal',
  templateUrl: './order-change-status-modal.component.html',
  styleUrls: ['./order-change-status-modal.component.scss']
})
export class OrderChangeStatusModalComponent implements OnInit {
  checkListALL: boolean = false;


  qualityControlDto: any[] = [
    { value: false, text: 'Product fulfills order requirements' },
    { value: false, text: 'Product meets standard requested' },
    { value: false, text: 'Product has been checked on models and/or articulator ' },
    { value: false, text: 'Product has been disinfected upon completion ' },
    { value: false, text: '<b> Approved for release </b> ' },


  ]


  checkIsQC() {
    let isNonQC = this.qualityControlDto.filter(x => x.value == false).length;
    if (this.orderStatusDto.statusId == 7) {
      if (isNonQC == 0) {
        return false;
      } else {
        return true;
      }
    } else if (this.orderStatusDto.statusId == 8) {
      // if ((this.orderStatusDto.trackingNumber && this.orderStatusDto.trackingNumber.length !== 0)) {
      //   return false;
      // } else {
      //   return true;
      // }
    }

  }

  selectAllChacklist(e: any) {
    this.qualityControlDto.map(x => {
      x.value = true;
      return x;
    })
  }






  @Input() order: OrderDetailModel;
  @Input() isManual: any;
  @Input() status: any;
  user: any = {};
  note: any;
  statusId: any;
  statusListDto: any[] = [];
  orderStatusDto: UpdateOrderStatusModel = new UpdateOrderStatusModel();

  constructor(
    protected dialogRef: NbDialogRef<OrderChangeStatusModalComponent>,
    private _authenticationService: AuthenticationService,
    private router: Router,
    private _orderService: OrderServiceProxy,
    private _shipmentService: ShipmentServiceProxy,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private _locationService: LocationServiceProxy,
    private _practiceService: PracticeServiceProxy,
    private _billingService: BillingServiceProxy,
    public printService: PrintService,
  ) {
    let currentUser = this._authenticationService.currentUserValue;
    let helper = new JwtHelperService();
    this.user = helper.decodeToken(currentUser.accessToken);
  }
  approve() {
    // this.dialogRef.close(true);


    this._orderService.updateOrderStatus(this.orderStatusDto).pipe(
      finalize(() => {

      }))
      .subscribe(
        (result) => {
          const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
          this.toastrService.success('', "Status changed", { icon: iconConfig });
          this.onStatusUpdate.emit();
          if (result.model) {
            this.invoiceResponse = result.model;
            this.isGenerated = true;
            if (this.isLabDocket || this.isInvoice || this.patientStatment) {
              this.onPrintInvoice();
            } else {
              this.dialogRef.close({ result: true, routeTo: 'status' });
            }
          }
          else {
            this.dialogRef.close({ result: true, routeTo: 'status' });
          }
        },
        (error: any) => { }
      )
  }
  decline() {
    this.dialogRef.close({ result: false, routeTo: null });
  }
  ngOnInit(): void {

    if (this.user.userroles == 'Doctor' || this.user.userroles == 'Manager') {
      this.statusListDto = [...ORDERSTATUSBYDOCTOR];
    } else {
      this.statusListDto = [...ORDERSTATUS];
    }

    this.orderStatusDto.orderId = this.order.id;

    if (this.isManual) {
      this.orderStatusDto.statusId = this.order.status.id;
    } else {
      if (this.status == 0) {
        let currentIndex = ORDERSTATUS.indexOf(ORDERSTATUS.find(x => x.id == this.order.status.id));
        if ((ORDERSTATUS.length - 1) !== currentIndex) {
          this.orderStatusDto.statusId = ORDERSTATUS[currentIndex + 1].id;
        } else {
          this.toastrService.warning('This is latest status', 'Warning')
          this.dialogRef.close({ result: false, routeTo: null });
        }
      } else {
        let currentIndex = ORDERSTATUS.indexOf(ORDERSTATUS.find(x => x.id == this.status));
        this.orderStatusDto.statusId = ORDERSTATUS[currentIndex].id;
      }
    }

    this._shipmentService.checkLocationShippingKey(this.order.locationId).subscribe((result) => {
      if (result.result) {
        this.isShipengineAvailable = true;
        this.validateAddresses();
      }
      this.changeTab('new');
    });

    this._shipmentService.getLocationShipments(this.order.locationId).subscribe((result) => {
      this.locationShipments = result;
      this.searchLocationShipments = result;
    });

    //invoicing stuff
    if (this.anyUninvoicedProducts()) {
      this.checkIfInvoicesWillBeGenerated();

      let tmpArr = this.order.orderProducts.filter(x => !x.isInvoiced);
      tmpArr.forEach(x => {
        this.totalPrice += x.product.price;
      });
      this.generateBasketFields();
    }
  }

  routeToOrderEdit(order: any) {
    this.dialogRef.close({ result: true, routeTo: 'product' });
    // this.router.navigate([`/pages/orders/update/${order.id}/category/${order.categoryId}`],{ queryParams: { extraEdit: true } });
  }

  //shipping
  shippingMethod: string;

  isShipengineAvailable: boolean = false;

  carriers: Carrier[] = [];
  isCarriersLoading: boolean = false;

  selectedCarrier: Carrier;
  selectedService: Service;

  locationShipments: OrderShipmentModel[] = [];
  searchLocationShipments: OrderShipmentModel[] = [];

  public configCarrier: PaginationInstance = {
    id: 'carrier',
    itemsPerPage: 3,
    currentPage: 1,
  };

  public configService: PaginationInstance = {
    id: 'service',
    itemsPerPage: 3,
    currentPage: 1,
  };

  public configAddress: PaginationInstance = {
    id: 'address',
    itemsPerPage: 3,
    currentPage: 1,
  };

  onCarrierChange() {
    this.configService.currentPage = 1;
    this.selectedService = null;
  }

  onPageChange(number: number, configName: any) {
    this[configName].currentPage = number;
  }

  onPageBoundsCorrection(number: number, configName: any) {
    this[configName].currentPage = number;
  }

  loadCarriers() {
    this.isCarriersLoading = true;
    this._shipmentService.carriers(this.order.locationId)
      .pipe(finalize(() => this.isCarriersLoading = false))
      .subscribe(carriers => {
        this.carriers = carriers;
      });
  }

  selectShippingMethod(method: string) {
    this.shippingMethod = method;
    this.orderStatusDto.trackingNumber = '';
  }

  isLabelsGenerating: boolean = false;

  generateLabel() {
    let request = new CreateShippingLabelsRequest({
      orderIds: [this.order.id],
      carrierCode: this.selectedCarrier.carrierCode,
      serviceCode: this.selectedService.serviceCode,
      isSingleLabel: true,
    });

    this.isLabelsGenerating = true;
    this._shipmentService.createShipmentLabelsForOrders(request)
      .pipe(finalize(() => this.isLabelsGenerating = false))
      .subscribe(
        (resp) => {
          this.onStatusUpdate.emit();
          if (resp.invoiceResponse) {
            this.invoiceResponse = resp.invoiceResponse;
            this.isGenerated = true;
            if (this.isLabDocket || this.isInvoice || this.patientStatment) {
              this.onPrintInvoice();
            }
          }
          if (resp.shipmentLabels.every(x => x.success)) {
            const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
            this.toastrService.success('', "Shipment created!", { icon: iconConfig });

            this.dialogService.open(ShippingLabelPreviewModalComponent, { context: { shippingLabels: resp.shipmentLabels } });
            this.dialogRef.close({ result: true, routeTo: 'status' });
          }
          else {
            resp.shipmentLabels.forEach(x => {
              const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
              this.toastrService.danger('', x.errorMessage, { icon: iconConfig });
            });
          }
        });
  }

  selectedTab: string;

  changeTab(tabId: string) {
    this.selectedTab = tabId;
    this.orderStatusDto.trackingNumber = '';

    switch (tabId) {
      case 'new':
        if (this.isShipengineAvailable) {
          this.shippingMethod = 'shipengine';
        }
        else {
          this.shippingMethod = 'independent';
        }
        break;
      case 'existing':
        this.shippingMethod = '';
        break;
    }
  }

  selectExistingShipment(shipment: OrderShipmentModel) {
    this.orderStatusDto.trackingNumber = shipment.trackingNumber;
  }

  addresses: ValidateAddressResponse[] = [];
  isAddressesValid: boolean = true;
  invalidAddresses: ValidateAddressResponse[] = [];
  selectedAddress: ValidateAddressResponse;

  location: LocationModel;

  validateAddresses() {
    this._shipmentService.validateAddresses(new ValidateAddressesRequest({ locationId: this.order.locationId, practiceIds: [this.order.practiceId] }))
      .subscribe(
        (result) => {
          this.addresses = result;
          if (result.every(x => x.status === 'verified')) {
            this.isAddressesValid = true;
            this.loadCarriers();
          }
          else {
            this.isAddressesValid = false;
            this.invalidAddresses = result.filter(x => x.status !== 'verified');
          }

          this._locationService.getById(this.addresses.find(x => x.isLocation).id).subscribe((result) => {
            this.location = result;
          });
        });
  }

  editPractice() {
    this._practiceService.getById(this.selectedAddress.id).subscribe((result) => {
      this.dialogService.open(CreatePracticenModalComponent, {
        context: {
          locationId: this.location.id,
          companyId: this.location.companyId,
          practice: result,
          isEdit: true,
          isDoctorAlreadyExist: true
        }
      })
        .onClose.subscribe((resp) => {
          if (resp) {
            const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
            this.toastrService.success('', "Practice successfully updated", { icon: iconConfig });
          }
        });
    });
  }

  editLocation() {
    this.dialogService.open(CreateLocationModalComponent, {
      context: {
        companyId: this.location.companyId,
        locaiotn: this.location,
        isEdit: true
      }
    })
      .onClose.subscribe((resp) => {
        if (resp) {
          const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
          this.toastrService.success('', "Location successfully updated", { icon: iconConfig });
        }
      });
  }

  searchOrderCode: string = '';

  searchOrder() {
    this.searchLocationShipments = this.locationShipments.filter(x => x.order.code.includes(this.searchOrderCode));
  }

  //invoicing methods and members

  isLabDocket: boolean = true;
  isInvoice: boolean = true;
  invoiceResponse: ManualInvoiceResponseModel = new ManualInvoiceResponseModel();
  patientStatment: boolean = true;
  isGenerated: boolean = false;
  isPrintingDone: boolean = false;
  totalPrice: number = 0;
  price: any;
  shipping: any;
  total: any;
  invoicesWillBeGenerated = false;
  invoiceGeneratingCheckLoading = false;
  @Output() onStatusUpdate = new EventEmitter<any>(true);

  onNewStatusChange() {
    if (this.anyUninvoicedProducts()) {
      this.checkIfInvoicesWillBeGenerated();
    }
  }

  checkIfInvoicesWillBeGenerated() {
    this.invoiceGeneratingCheckLoading = true;
    this._billingService.checkIfInvoicesShouldBeCreated(this.order.id, this.orderStatusDto.statusId).pipe(
      finalize(() => {
        this.invoiceGeneratingCheckLoading = false;
      }))
      .subscribe(result => this.invoicesWillBeGenerated = result);
  }

  getCurrentNameCategory(categoryId: number) {
    return CategoryNameEnumModel[`category_${categoryId}`]
  }

  convertTeethNotation(system: number, toothNumberStr: string): string {
    const toothNumbers = toothNumberStr.split(',');
    const convertedTeethNumbers = toothNumbers.map(toothStr => {
      const toothNumber = parseInt(toothStr, 10);
      if (!isNaN(toothNumber)) {
        return convertFromFDI(system, toothNumber);
      } else {
        console.error('Invalid tooth number:', toothStr);
        return '';
      }
    }).join(', ');

    return convertedTeethNumbers;
  }

  generateBasketFields() {
    this.price = 0;
    this.shipping = 0;
    this.total = 0;
    this.order.orderProducts.filter(x => !x.isInvoiced).map((item: any) => {
      var countIt = 0;
      switch (item.product.pricePer) {
        case ProductPricePer.Tooth:
          countIt = item.teeth?.split(',').length;
          break;
        case ProductPricePer.Arch:
          countIt = item?.arch?.split(',').length;
          break;
        case ProductPricePer.Product:
          countIt = 1;
          break;
      }
      if (item.product.customPrice !== 0) {
        item.itemTmp = countIt * item.product.customPrice * item.quantity;
      } else {
        item.itemTmp = countIt * item.product.price * item.quantity;
      }
      item.coutQt = countIt;
      this.price += item.itemTmp;
      return item;
    })
    this.order.orderCustomFees?.filter(x => !x.isInvoiced).map((fee: any) => {
      var countIt = 1;
      fee.itemTmp = (countIt * fee.amount) * 1;
      this.price += fee.itemTmp;
    })
  }

  onPrintInvoice() {
    let isCreateInvoice = this.order.orderProducts.filter(x => !x.isInvoiced);
    if (isCreateInvoice.length == 0) {
      const iconConfig: NbIconConfig = { icon: 'danger-toastr', pack: 'great-pack' };
      this.toastrService.danger('', "Error!", { icon: iconConfig });
      return false;
    }
    let data = {
      invoiceId: this.invoiceResponse.invoiceId,
      statmentId: this.invoiceResponse.statementId,
      orderId: this.order.id,
      isDocket: this.isLabDocket,
      isInvoice: this.isInvoice,
      isStatment: this.patientStatment
    }
    this.isPrintingDone = true;
    const iconConfig: NbIconConfig = { icon: 'success-toastr', pack: 'great-pack' };
    this.toastrService.info('', "Printing is generating...", { icon: iconConfig });
    this.printService.printDocument("invoice", JSON.stringify(data), () => {
      // this.getOrderDetials();
      this.isPrintingDone = false;
      // Additional logic after printing can be placed here.
    });
  }

  anyUninvoicedProducts(): boolean {
    return this.order.orderProducts.some(x => !x.isInvoiced);
  }
}

