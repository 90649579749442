<div id="invoice_doc" class="invoice-section" *ngIf="patientStatement.id">
    <div class="container position-relative">
        <table class="head-table mt-3">
            <colgroup>
                <col style="width:33%">
                <col style="width:33%">
                <col style="width:33%">
            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <div class="head-left-column">
                            <div class="head-info-contact">
                                {{patientStatement.locationAddress}}
                            </div>
                            <div class="head-info-contact">
                                {{patientStatement.locationPhone}}
                            </div>
                            <div class="head-info-contact">
                                {{patientStatement.locationEmail}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="logo-box">
                            <img [src]="patientStatement.location.logoUrl" alt="labLogo"> 
                        </div>
                    </td>
                    <td>
                        <div class="head-right-column">
                            <div class="head-info">
                                Order number <b>{{patientStatement.orderShortCode}}</b> 
                            </div>
    
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br> <br>
        <div class="line"></div>
        <div class="statement-title">
            
            {{patientStatement.locationDocumentSettings?.patientStatementTitle ? patientStatement.locationDocumentSettings?.patientStatementTitle : "Patient Statement "}}
        </div>
        <div class="statement-desc">
            If you have any queries regarding the fit or performance of your appliance you should contact the prescribing dentist for further information. 
        </div>
        <table class="head-content">
            <colgroup>
                <col style="width:25%">
                <col style="width:25%">
                <col style="width:25%">
                <col style="width:25%">
            </colgroup>
            <tbody>
                <tr>
                    <td class="statement-details-title first">
                        Order number
                    </td>
                    <td class="statement-details-desc first">
                        {{patientStatement.orderShortCode}}
                    </td>
                    <td></td><td></td>
                </tr>
                <tr>
                    <td class="statement-details-title">
                        Patient
                    </td>
                    <td class="statement-details-desc ">
                        {{patientStatement.patientName}}
                    </td>
                    <td></td><td></td>
                </tr>
                <tr>
                    <td class="statement-details-title">
                        Doctor
                    </td>
                    <td class="statement-details-desc">
                        {{patientStatement.doctorName}}
                    </td>
                    <td></td><td></td>
                </tr>
                <tr>
                    <td class="statement-details-title">
                        Practice Name
                    </td>
                    <td class="statement-details-desc">
                        {{patientStatement.practiceName}}
                    </td>
                    <td></td><td></td>
                </tr>
                <tr>
                    <td class="statement-details-title">
                        Address
                    </td>
                    <td class="statement-details-desc">
                        {{patientStatement.practiceAddress}}
                    </td>
                    <td></td><td></td>
                </tr>
            </tbody>
        </table>
        <br><br>
        <div class="statement-desc">
            Order description
         </div>
    
        <table border=1 width=100 class="invoice-products">
            <colgroup>
                <col style="width:16.6%">
                <col style="width:16.6%">
                <col style="width:16.6%">
                <col style="width:16.6%">
                <col style="width:16.6%">
                <col style="width:16.6%">
            </colgroup>
            <thead>
                <tr>
                    <th class="table-statement-title left" scope="col">SKU</th>
                    <th class="table-statement-title" scope="col">Product</th>
                    <th class="table-statement-title" scope="col">Material</th>
                    <th class="table-statement-title" scope="col">Standard</th>
                    <th class="table-statement-title" scope="col">Date of Manufacture</th>
                    <th class="table-statement-title" scope="col">Quantity</th>
                </tr>
            </thead>
    
            <tbody>
                <tr *ngFor="let item of patientStatement.patientStatementItems">
                    <td class="table-statement-value left"> {{item.sku}}</td>
                    <td class="table-statement-value">{{item.productName}}</td>
                    <td class="table-statement-value">{{item.materialName}}</td>
                    <td class="table-statement-value">{{item.standardName}}</td>
                    <td class="table-statement-value">{{patientStatement.orderManufactureDate | date:
                        (patientStatement.location.dateFormat ? patientStatement.location.dateFormat : 'dd/MM/YYYY')}}</td>
                    <td class="table-statement-value">{{item.quantity}}</td>
                </tr>
            </tbody>
        </table>


        <br><br>







        <table class="head-table mt-3">
            <colgroup>
                <col style="width:30%">
                <col style="width:70%">
            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <div class="statement-desc">
                            Origin of Manufacture Declaration
                         </div>
                    </td>
                    <td>
                        <div class="statement-desc">
                           <b> All the above stated appliances have been wholly manufactured within the EU.</b>
                         </div>
                    </td>
                </tr>
            </tbody>
        </table>
    <br><br>
    <div class="line"></div>
    <br>
            <div class="footer">
                <!-- <div class="footer-title">Terms and Conditions</div>
                <div class="footer-content">
                  sdadsaas
                </div>
             -->


             <table class="footer-content w-100">
                <colgroup>
                    <!-- <col style="width:20%"> -->
                    <col style="width:80%">
                    <col style="width:20%">
                </colgroup>
                <tbody>
                    


                     <tr>
                        <td class="center-column p-footer">
                            <div class="footer-xs-text" [innerHtml]="!isDeffaultFiitertemplate ? patientStatement.locationDocumentSettings.patientStatementTermsConditions : patientStatmentTermsConditions">

                            </div>
                            <div class="footer-md-text text-center" [innerHtml]="!isDeffaultFiitertemplate ? patientStatement.locationDocumentSettings.patientStatementFooter : patientStatmentFooter"></div>
                        </td>
                        <td class="center-column">
                            <div class="footer-logo">
                                <img [src]="!isDeffaultFiitertemplate ? patientStatement.locationDocumentSettings.patientStatementFooterLogo : patientStatmentFooterLogo" alt="">
                            </div>
                            <div class="footer-logo">
                                <div class="powered">
                                    <div class="text">Powered by</div>
                                    <img src="../../../../assets/images/greatlab-logo.png"
                                        alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
    
            </table>
    
             <!-- <table class="footer-content">
                <colgroup>
                    <col style="width:90%">
                    <col style="width:10%">
                </colgroup>
                <tbody>
                    <tr>
                        <td class="center-column p-footer">
                            <div class="footer-xs-text">
                                This is a custom-made medical device that has been manufactured to satisfy the design characteristics and properties specified by the prescriber for the above named patient. This medical device is intended for exclusive use by this patient and conforms to the relevant essential requirements specified in Annex 1 of the Medical Devices Directive and the United Kingdom Medical Devices Regulations. This statement does not apply to medical devices that have been repaired and/or refurbished for an individual patients use.
                            </div>
                            <div class="footer-md-text">2023 GreatLab Ltd, Registered in England and Wales - Company No. 411111</div>
                        </td>
                        <td class="center-column">
                            <div class="footer-logo">
                                <img src="../../../../assets/images/icons/damas_black.png" alt="">
                            </div>
                            <div class="footer-logo">
                                <div class="powered">
                                    <div class="text">Powered by</div>
                                    <img   [src]="'../../../../assets/images/greatlab-logo.png'" alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
        
            </table> -->
        








            </div>
        
    </div>
</div>