import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { finalize } from 'rxjs/operators';
import { InvoiceModel, CurrencyModel, BillingServiceProxy, LocationServiceProxy } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from '../../../@core/service';

@Component({
  selector: 'ngx-print-invoice-docket',
  templateUrl: './print-invoice-docket.component.html',
  styleUrls: ['./print-invoice-docket.component.scss']
})
export class PrintInvoiceDocketComponent implements OnInit,AfterViewInit {
  user: any = {};
  @Input() invoiceId: any;
  invoiceModelDto: InvoiceModel = new InvoiceModel();
  currencyDto: CurrencyModel;
  currencySymbol: any;
  currencyCode: string = 'GBP';
  currentDate: Date = new Date();


  @Output() loadComplete = new EventEmitter<void>();
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadComplete.emit();
    }, 1000);
  }


  constructor(
    private _authenticationService: AuthenticationService,
    private _billingService: BillingServiceProxy,
    private _locationService: LocationServiceProxy,
    private route: ActivatedRoute
  ) { 
    let currentUser = this._authenticationService.currentUserValue;
      let helper = new JwtHelperService();
      this.user = helper.decodeToken(currentUser.accessToken);
     
  }
  ngOnInit(): void {
    this.getInvoiceDetails();
  }





  getInvoiceDetails() {
    this._billingService.getById(this.invoiceId).pipe(
      finalize(() => {
     
      }))
    .subscribe(
      (result) => { 
        this.invoiceModelDto = result;
        this.getCurrency(this.invoiceModelDto.currency);
      },
      (error: any) => {}
    )
  }

  getCurrency(currency: any) {
    if (!currency) {
      this.currencySymbol = '£';
      return;
    }
    this._locationService.getCurrencuSymbolByCode(currency).pipe(
      finalize(() => {
      })
    ).subscribe(
      (resp) => {
        if (resp) {
          this.currencyDto = resp;
          this.currencySymbol = this.currencyDto.currencySymbol;
          this.currencyCode = this.currencyDto.currencyCode;
        } else {
          this.currencySymbol = '£'; 
        }
      },
      (error: any) => {
        console.error('Error fetching currency:', error);
      }
    );
  }

  

}
