import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConstClaims, Role } from '../models';
import { AuthServiceProxy, ForgotUsernameModel, ResetPasswordModel, UserDTO } from '../../../shared/service-proxies/event-service-proxy';
import { Router } from '@angular/router';

export class ClaimValue {
  index: boolean;
  create: boolean;
  view: boolean;
  update: boolean;
  delete: boolean;
}
export class Claim {
  user: ClaimValue;
  clinic: ClaimValue;
  treatmentplan: ClaimValue;
  practice: ClaimValue;
  patient: ClaimValue;
  order: ClaimValue;
  role: Role;
  printing: ClaimValue;
  printingportal: ClaimValue;
  treatmentreview: ClaimValue;
  notification: ClaimValue;
  communicate: ClaimValue;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public user: UserDTO = new UserDTO();
  
  private userRole: Role;
  private readonly storage: Storage;
  constructor(
    private _authService: AuthServiceProxy,
    private router: Router
  ) {
    this.storage = window.localStorage;
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(this.storage.getItem('access')),
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get currentUserRole(): any {
    const helper = new JwtHelperService();
    this.userRole = helper.decodeToken(
      this.currentUserValue.accessToken,
    ).userroles;
    return this.userRole;
  }
  public experationToken(): boolean {
    const helper = new JwtHelperService();
    const token = JSON.parse(localStorage.getItem('access'));
    if (!token) {
      return false;
    }
    return !helper.isTokenExpired(token.accessToken);
  }
  forgotUsername(email: any, domain: any) {
    let forgotUsername: ForgotUsernameModel = new ForgotUsernameModel();
    forgotUsername.email = email;
    forgotUsername.domain = domain;
    return this._authService.forgotUsername(forgotUsername).pipe(
      map((user) => {
        return user;
      }),
    );
  }

  getUserPermission(): any {
    const helper = new JwtHelperService();
    const token = this.currentUserValue.accessToken;
    if (token) {
      const decodedToken = helper.decodeToken(token);
      return decodedToken;
    }
    return null;
  }

  login(username: string, password: string) {
    this.user.username = username;
    this.user.password = password;
    return this._authService.login(this.user).pipe(
      map((user) => {
        if (user.accessToken) {
          this.storage.setItem('access', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      }),
    );
  }

  logout() {
    this.storage.removeItem('access');
    this.storage.removeItem('order_column_settings');
    this.storage.removeItem('invoice_column_settings');
    this.storage.removeItem('consolidation_column_settings');
    this.storage.removeItem('activity_statement_column_settings');
    this.storage.removeItem('billing_statement_column_settings');
    this.storage.removeItem('batch_orders');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login'])
  }

  requestPasswordReset(email: string){
    return this._authService.sendPasswordResetEmail(email);
  }

  resetPassword(email: string, password: string, token: string){
    let model = new ResetPasswordModel({
      username: email,
      newPassword: password,
      token: token
    })
    return this._authService.resetPassword(model)
  }
}
