import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HotglueService {
  private hotglueConfig: any;

  constructor() {
    this.hotglueConfig = {
      config: {
        apiKey: 'ZYfBz6p63f5K5I9iStK2c5FF77G0XltV9f0kZt9Y',
        envId: 'dev.hotglue.greatlab.io'
      }
    };
  }

  getHotglueConfig() {
    return this.hotglueConfig;
  }


}

