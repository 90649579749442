<div id="invoice_doc" class="invoice-section" *ngIf="invoiceModelDto.id">
    <div class="container position-relative">
        <table class="head-table mt-3">
            <colgroup>
                <col style="width:70%">
                <col style="width:30%">

            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <!-- <div class="head-left-column">
                            <div class="head-info-contact xl">
                                {{invoiceModelDto.location.name}}
                            </div>
                         
                            <div class="head-info-contact">
                                email: {{invoiceModelDto.location.email}}
                            </div>
                            <div class="head-info-contact">
                                tel: {{invoiceModelDto.location.phone}}
                            </div>
                        </div> -->


                        <table class="w-100">
                            <colgroup>
                                <col style="width:50%">
                                <col style="width:50%">

                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="statement-details-title first">
                                        <b>{{invoiceModelDto.clientName}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        {{invoiceModelDto.practice.name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        <div>
                                            {{invoiceModelDto.practice.billingAddress ? invoiceModelDto.practice.billingAddress : invoiceModelDto.practice.address}}
                                        </div>
                                        <div *ngIf="invoiceModelDto.practice.address2 || invoiceModelDto.practice.billingAddress2">
                                            {{invoiceModelDto.practice.billingAddress2 ? invoiceModelDto.practice.billingAddress2 : invoiceModelDto.practice.address2}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        {{invoiceModelDto.practice.billingCity ? invoiceModelDto.practice.billingCity : invoiceModelDto.practice.city}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        {{invoiceModelDto.practice.billingState ? invoiceModelDto.practice.billingState : invoiceModelDto.practice.state}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        {{invoiceModelDto.practice.billingPostCode ? invoiceModelDto.practice.billingPostCode : invoiceModelDto.practice.postCode}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td class="vertical-center">
                        <div class="logo-box text-right m-auto">
                            <div *ngIf="invoiceModelDto.location.logoUrl" class="text-center mb-3">
                                <img [src]="invoiceModelDto.location.logoUrl" alt="labLogo">
                            </div>
                            <div class="head-info-contact mt-1">
                                <span> {{invoiceModelDto.location.address}},&nbsp;</span>
                                <span *ngIf="invoiceModelDto.location.address2">
                                    {{invoiceModelDto.location.address2}}&nbsp;</span>
                                <span *ngIf="invoiceModelDto.location.town">
                                    {{invoiceModelDto.location.town}},&nbsp;</span>
                                <span *ngIf="invoiceModelDto.location.city">
                                    {{invoiceModelDto.location.city}},&nbsp;</span>
                                <span *ngIf="invoiceModelDto.location.country">
                                    {{invoiceModelDto.location.country}},&nbsp;</span>
                                <span *ngIf="invoiceModelDto.location.postCode">
                                    {{invoiceModelDto.location.postCode}}</span>
                            </div>
                            <table class="w-100 mt-2">
                                <colgroup>
                                    <col style="width:60%">
                                    <col style="width:40%">
                                </colgroup>
                                <tbody>
                                    <tr *ngIf="invoiceModelDto.taxNumber">
                                        <td class="statement-details-title text-left">
                                            Tax Number
                                        </td>
                                        <td class="statement-details-desc text-left">
                                            {{invoiceModelDto.taxNumber}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="statement-details-title text-left">
                                            Invoice Date
                                        </td>
                                        <td class="statement-details-desc text-left">
                                            {{invoiceModelDto.date | date:
                                            (invoiceModelDto.dateFormat ? invoiceModelDto.dateFormat : 'dd/MM/YYYY')}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="statement-details-title text-left">
                                            Invoice Due Date
                                        </td>
                                        <td class="statement-details-desc text-left">
                                            {{invoiceModelDto.dueDate | date:
                                            (invoiceModelDto.dateFormat ? invoiceModelDto.dateFormat : 'dd/MM/YYYY')}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
    


                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
        <br> 
        <div class="line"></div>



        <!-- <table class="head-table mt-3">
            <colgroup>
                <col style="width: 40%">
                <col style="width: 25%">
                <col style="width:35%">

            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <table class="head-content">
                            <colgroup>
                                <col style="width:50%">
                                <col style="width:50%">

                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="statement-details-title first">
                                        <b>{{invoiceModelDto.clientName}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        {{invoiceModelDto.practice.name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        <div>
                                            {{invoiceModelDto.practice.address}}
                                        </div>
                                        <div *ngIf="invoiceModelDto.practice.address2">
                                            {{invoiceModelDto.practice.address2}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        {{invoiceModelDto.practice.city}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        {{invoiceModelDto.practice.postCode}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </td>
                    <td></td>
                    <td>

                        <table class="head-content">
                            <colgroup>
                                <col style="width:60%">
                                <col style="width:40%">

                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="statement-details-title  first">
                                        <b>INVOICE</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        Invoice Number
                                    </td>
                                    <td class="statement-details-desc text-right ">
                                        {{invoiceModelDto.invoiceNumber}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        Invoice Date
                                    </td>
                                    <td class="statement-details-desc text-right">
                                        {{invoiceModelDto.date | date:
                                        (invoiceModelDto.dateFormat ? invoiceModelDto.dateFormat : 'dd/MM/YYYY')}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="statement-details-title">
                                        Invoice Due Date
                                    </td>
                                    <td class="statement-details-desc text-right">
                                        {{invoiceModelDto.dueDate | date:
                                        (invoiceModelDto.dateFormat ? invoiceModelDto.dateFormat : 'dd/MM/YYYY')}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </td>

                </tr>
            </tbody>
        </table> -->

        <div class="statement-details-title" *ngIf="invoiceModelDto.accountCode">
            <b>Account Code:</b> {{invoiceModelDto.accountCode}}
        </div>
        <br>
        <div class="statement-details-title">
            <b>Invoice Number:</b> {{invoiceModelDto.invoiceNumber}}
        </div>
       
        <br>

        <div class="patient-order-badge">
            {{invoiceModelDto.clientName}}
            ({{invoiceModelDto.practiceName}})
        </div>
        <br>
        <div style="border: 1px solid #ACACAC4D;">
            <table width=100 class="invoice-products statment-border">
                <!-- <colgroup>
                    <col style="width:5%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                </colgroup> -->
                <thead>
                    <tr>
                        <th class="table-statement-title " scope="col">ORDER ID</th>
                        <th class="table-statement-title left" scope="col">PATIENT</th>
                        <th class="table-statement-title left" scope="col">DESCRIPTION</th>
                        <th class="table-statement-title left" scope="col">SKU</th>
                        <th class="table-statement-title" scope="col">QTY</th>
                        <th class="table-statement-title" scope="col">UNIT</th>
                        <th class="table-statement-title" scope="col">TAX</th>
                        <th class="table-statement-title" scope="col">DISCOUNT% </th>
                        <th class="table-statement-title" scope="col">TOTAL </th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="pre-header" colspan=6>
                    </tr>
                    <tr *ngFor="let invoice of invoiceModelDto.invoiceItems">
                        <td class="table-statement-value ">
                            {{ invoiceModelDto.order ? invoiceModelDto.order.code : "N/A" }}
                        </td>
                        <td class="table-statement-value left">
                            {{ invoiceModelDto.patientName }}
                        </td>
                        <td class="table-statement-value left">
                            {{ invoice.description }}
                        </td>
                        <td class="table-statement-value left bold">
                            {{ invoice.sku }}
                        </td>
                        <td class="table-statement-value">
                            {{ invoice.quantity }}
                        </td>
                        <td class="table-statement-value">
                            {{ invoice.unitPrice |
                                currency:invoiceModelDto.currency:"symbol"}}
                        </td>
                        <td class="table-statement-value">
                            {{ invoice.tax |
                                currency:invoiceModelDto.currency:"symbol"}}
                        </td>
                        <td class="table-statement-value text-right">
                            {{ invoice.discount }}
                        </td>
                        <td class="table-statement-value text-right">
                            {{ invoice.total |
                                currency:invoiceModelDto.currency:"symbol" }}
                             
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <table class="head-table">
            <colgroup>
                <col style="width: 40%">
                <col style="width: 30%">
                <col style="width:30%">

            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <table class="head-content mt-0  w-100">
                            <colgroup>
                                <col style="width:50%">
                                <col style="width:50%">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="shipped-date">
                                        <!-- Shipped on 03/02/2024 -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </td>
                    <td></td>
                    <td>

                        <table class="head-conten mt-1 w-100">
                            <colgroup>
                                <col style="width:60%">
                                <col style="width:40%">

                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="total-price text-right">
                                        Subtotal
                                    </td>
                                    <td class="total-price text-right ">
                                        {{invoiceModelDto.subTotal | currency:invoiceModelDto.currency:"symbol"}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="total-price text-right">
                                        Total Tax
                                    </td>
                                    <td class="total-price text-right">
                                        {{invoiceModelDto.totalTax | currency:invoiceModelDto.currency:"symbol"}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="total-price text-right">
                                        Discount
                                    </td>
                                    <td class="total-price text-right">
                                        {{invoiceModelDto.discount | currency:invoiceModelDto.currency:"symbol"}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="total-price text-right">
                                        <b>Amount Due</b>
                                    </td>
                                    <td class="total-price text-right">
                                        <b> {{invoiceModelDto.amountDue |
                                            currency:invoiceModelDto.currency:"symbol"}}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </td>

                </tr>
            </tbody>
        </table>
        <br>

        <br><br>

        <br>
        <div class="terms-container">
            <div class="payment-terms">
                <div class="terms">
                    {{invoiceModelDto.termsAndConditions}}
                </div>
                <div class="banking-terms">
                    <div> {{invoiceModelDto.bankingDetails}}</div>
                    <div> {{invoiceModelDto.taxNumber}}</div>
                </div>
            </div>
            <!-- <div class="terms-of-condition">
                {{invoice.locationBillingSettings.statementTerms}}
            </div> -->
        </div>
        <div class="footer-statment">
            <div class="container">
                <div class="date-doc">
                    {{currentDate | date:
                    (invoiceModelDto.dateFormat ? invoiceModelDto.dateFormat : 'dd/MM/YYYY')}}
                </div>
                <div class="logo-url">
                    Powered by
                    <img src="./assets/images/greatlab-logo.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>