import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-lightbulb',
  templateUrl: './doing-blinking-icon.component.html',
  styleUrls: ['./doing-blinking-icon.component.scss']
})
export class DoingBlinkingIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
