import { Component, OnInit, Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as THREE from 'three';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-stl-model-viewer-modal',
  templateUrl: './stl-model-viewer-modal.component.html',
  styleUrls: ['./stl-model-viewer-modal.component.scss']
})
export class StlModelViewerModalComponent implements OnInit {
  @ViewChild('rendererContainer') rendererContainer!: ElementRef;
  isLoaderStl: boolean = true;
  @Input() stl: string;
  renderer = new THREE.WebGLRenderer({ antialias: true });
  camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 1, 15);
  controls = new OrbitControls(this.camera, this.renderer.domElement);
  scene = new THREE.Scene();
  light = new THREE.PointLight(0xffffff, 80);
  expandIframe: boolean = false;
  isloaderResize: boolean = true;

  constructor(
    protected ref: NbDialogRef<StlModelViewerModalComponent>,
  ) {
    this.generate();
  }

  generate() {
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.shadowMap.enabled = true;
    this.camera.position.set(3, 3, 3);
    this.light.position.set(1, 1, 2);
    this.scene.background = new THREE.Color(0xebebeb);
    this.controls.enableZoom = true;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 7;
    this.controls.update();
    this.scene.add(this.light);
    const gridHelper = new THREE.GridHelper(5, 50);
    this.scene.add(gridHelper);
  }

close() {
  this.ref.close(false);
}

  ngOnInit() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.animate();
  }



  onModelLoaded(e: any) {
    this.isLoaderStl = false;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    debugger
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  toggleFullScreen() {
 
    this.expandIframe = !this.expandIframe;
    setTimeout(() => {
      this.ngOnInit();
    }, 500);
  }

  animate() {
    requestAnimationFrame(() => this.animate());
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  }
}
