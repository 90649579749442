<div class="w-100 position-relative image-container">
  <div class="text-center label-teeth">{{arch ==  'lower' ? 'Lower arch' : 'Upper arch'}}</div>
  <ng-container [ngSwitch]="colorsAmount">
    <svg  xmlns="http://www.w3.org/2000/svg"   class="color-teeth" viewBox="0 0 1500 1500" *ngSwitchCase="1">
      <path [attr.fill]="color1 || '#fff'" stroke-linejoin="round" stroke-width="20px" stroke="#000" fill-rule="evenodd" class="cls-1" d="M724.3,298.1S406.339,286.334,259.9,674.951C86.3,1132.5,295.628,1186.62,295.628,1186.62s184.483,67.87,232.2-128.32c91.213-288.123,169.238-326.195,233.821-328.121,63.434,1.221,166.532,110.456,206.217,285.891,46.788,168.32,66.468,190.67,157.508,185.17,105.37-12.25,197.64-85.12,181.86-232.281S1207.23,289.453,724.3,298.1Z"/>
      <path [attr.fill]="color1 || '#d7d7d7'" stroke-width="15px" stroke="#000" fill-rule="evenodd" class="cls-2" d="M742.16,367.949S436.769,353.971,302.123,752.92c-73.331,273.81-60.079,394.72-60.079,394.72s65.7,86.77,201.345,34.11c72.939-41.93,94.032-141.93,118.534-212.791,40.113-121.857,105.99-250.5,210.423-237.4,141.813,44.666,195.031,262.276,205.281,305.621,12.7,32.92,25.913,184.94,146.113,162.44,109.72-18.07,151.01-79.6,151.01-79.6S1238.35,366.762,742.16,367.949Z"/>
    </svg>
    <svg id="Group_1_copy"  class="color-teeth" data-name="Group 1 copy" 
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500" *ngSwitchCase="2">
      <path [attr.fill]="color1 || '#fff'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy" data-name="Shape 1 copy" class="cls-1" d="M755.124,295.1S409.065,270.87,254.228,674.89c-174.539,461.12,35.915,515.66,35.915,515.66s185.477,68.4,233.448-129.32c91.7-290.371,167.15-327.74,232.081-329.681C759.207,578.755,757.155,330.337,755.124,295.1Z"/>
      <path [attr.fill]="color1 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy" data-name="Shape 2 copy" class="cls-2" d="M756,366S432.045,351.407,296.673,753.467c-73.725,275.943-60.4,397.793-60.4,397.793s66.055,87.45,202.43,34.38c73.332-42.26,94.539-143.04,119.173-214.45C601.762,848.829,652.727,732.7,757,730,757.773,582.187,756.5,426.471,756,366Z"/>
      <path [attr.fill]="color2 || '#fff'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_2" data-name="Shape 1 copy 2" class="cls-1" d="M738,295s-3.741,282.026,0,437c81.148-19.49,188.1,109.866,228,286.66,47.04,169.64,66.83,192.16,158.35,186.62,105.95-12.34,198.71-85.79,182.84-234.09S1199.56,293.332,738,295Z"/>
      <path [attr.fill]="color2 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_2" data-name="Shape 2 copy 2" class="cls-2" d="M739,366s1.279,267.217,0,367c130.57-19.082,226.512,263.26,236.817,306.94,12.765,33.18,26.053,186.38,146.9,163.71,110.3-18.22,151.82-80.22,151.82-80.22S1237.87,364.8,739,366Z"/>
      <rect x="738" y="298" width="18" height="433"/>
    </svg>
    <svg id="Group_1_copy_2"   class="color-teeth" data-name="Group 1 copy 2" 
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500" *ngSwitchCase="3">
      <path [attr.fill]="color1 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" class="cls-1" d="M585,322C474.671,361.427,337.764,461,257.292,674.877c-174.7,461.1,35.948,515.643,35.948,515.643S475.286,1261.72,529,1064c9.117-18.52,39.477-116.042,57-156C593.766,537.69,586.962,369.731,585,322Z"/>
      <path [attr.fill]="color1 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" class="cls-2" d="M586.813,401.661c-96.68,43.748-215.683,140.068-287.037,351.79-73.793,275.939-61.665,384.909-60.458,397.779,3.821,14.48,96.037,86.03,202.616,34.38,71.737-37.27,82.659-107.67,119.282-214.443,7.923-22.068,16.563-44.338,26-65.951C587.221,735.783,589.423,456.409,586.813,401.661Z"/>
      <path [attr.fill]="color2 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_3" data-name="Shape 1 copy 3" class="cls-1" d="M724.616,295.1s-68.864-2.552-157.41,33.814c-0.241,92.827-.241,362.729-0.011,618.193,74.679-186.929,139.733-214.925,195-216.575,67.5,7.406,135.212,83.072,183.7,208.082-0.024-305.54-.024-453.62-0.632-602.744C882.885,308.845,809.934,293.572,724.616,295.1Z"/>
      <path [attr.fill]="color2 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_3" data-name="Shape 2 copy 3" class="cls-2" d="M742.59,365.492s-79.448-3.642-175.364,45.625c-2.271,48.889-.288,328.715.184,543.164,43.873-116.986,108.4-233.5,205.557-222.357C856.738,756.174,912.7,854.238,945.3,934.646c0.576-208.614.576-403.806,0.683-518.947C888.58,384.025,821.339,365.3,742.59,365.492Z"/>
      <path [attr.fill]="color3 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_4" data-name="Shape 1 copy 4" class="cls-1" d="M926.087,892.164a592.359,592.359,0,0,1,43.629,126.476c47.084,169.63,66.894,192.15,158.494,186.61,106.05-12.34,198.89-85.78,183.01-234.083-13.37-124.9-75.6-525.132-385.209-643.13C926,472,926,812.543,926.087,892.164Z"/>
      <path [attr.fill]="color3 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_4" data-name="Shape 2 copy 4" class="cls-2" d="M925.982,891.229c31.4,64.295,48.589,127.631,53.561,148.691,12.776,33.18,26.077,186.37,147.037,163.7,110.41-18.22,151.96-80.22,151.96-80.22s-27.09-561.462-352.3-717.887C926,534.086,926,847.657,925.982,891.229Z"/>
      <path fill-rule="evenodd" class="cls-3" d="M567,334.326L587,324V895.022L567,926V334.326Z"/>
      <path id="Rectangle_1_copy_6" data-name="Rectangle 1 copy 6" class="cls-3" d="M926,331l20,10.136V926l-20-37.409V331Z"/>
    </svg>
    <svg id="Group_1_copy_4"   class="color-teeth" data-name="Group 1 copy 4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500" *ngSwitchCase="4">
      <path [attr.fill]="color1 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" class="cls-1" d="M444.986,395.991C373.5,452.63,302.534,540.013,251.84,674.791,75.759,1139.71,288.072,1194.7,288.072,1194.7s86.332,31.81,156.88-10.25C444.894,1049.51,444.894,576.455,444.986,395.991Z"/>
      <path [attr.fill]="color1 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" class="cls-2" d="M444.68,497.341c-56.7,57.53-110.58,139.605-150.02,256.674-74.377,278.215-60.936,401.065-60.936,401.065s66.638,88.17,204.218,34.66q3.569-2.055,6.974-4.28C444.894,1076.01,444.894,632.4,444.68,497.341Z"/>
      <path [attr.fill]="color2 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_11" data-name="Shape 1 copy 11" class="cls-1" d="M723.374,291.173s-148.686-5.51-290.711,114.445c-0.35,167.476-.35,550.9-0.294,785.332,38.385-17.89,74.03-54.91,91.538-126.99,89.127-281.9,165.852-328.333,229.944-333.166-0.086-88.754-.086-355.981-0.02-439.453Q738.869,290.9,723.374,291.173Z"/>
      <path [attr.fill]="color2 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_11" data-name="Shape 2 copy 11" class="cls-2" d="M741.508,362.177s-166.492-7.63-309.257,147.882c0.062,128.892.062,383.357,0.118,681.591,1.925-.7,3.863-1.44,5.817-2.19,74.049-42.63,95.464-144.29,120.339-216.319,42.087-117.21,83.4-231.733,195.234-240.8,0.006-159.063.006-316.016,0-370.045Q747.68,362.162,741.508,362.177Z"/>
      <path [attr.fill]="color3 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_9" data-name="Shape 1 copy 9" class="cls-1" d="M741.336,732.292a147.533,147.533,0,0,1,19.954-1.886c64.4,1.241,169.068,112.285,209.358,290.624,32.562,117.29,52.192,164.86,91.092,181.38,0.29-128.74.29-666.419,0.31-798.064C982.441,337.38,878.041,292.362,741.086,291.094,741.225,340.7,741.225,543.82,741.336,732.292Z"/>
      <path [attr.fill]="color3 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_9" data-name="Shape 2 copy 9" class="cls-2" d="M741.508,362.177s-0.283,256.15.069,371.611a173.988,173.988,0,0,1,30.577-1.979c128.9,28.159,198,266.619,208.408,310.681,10.031,26.05,20.388,125.42,82,157.43-0.53-180.37-.53-598.614-0.33-694.321C985.144,420.567,881.3,361.842,741.508,362.177Z"/>
      <path [attr.fill]="color4 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_10" data-name="Shape 1 copy 10" class="cls-1" d="M1047.6,1194.4c20.25,14.78,45.73,17.12,82.95,14.87,106.99-12.45,200.65-86.54,184.63-236.129-11.53-107.68-59.06-418.25-267.65-580.549C1047.58,533.1,1047.58,972.54,1047.6,1194.4Z"/>
      <path [attr.fill]="color4 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_10" data-name="Shape 2 copy 10" class="cls-2" d="M1048.37,1190.73c19.66,15.47,45.66,23.43,80.53,16.89,111.39-18.37,153.31-80.91,153.31-80.91s-20.2-418.681-234.4-636.378C1047.11,1170.11,1047.58,1064.52,1048.37,1190.73Z"/>
      <rect id="Rectangle_1_copy_3" data-name="Rectangle 1 copy 3" x="432" y="408" width="13" height="774"/>
      <rect id="Rectangle_1_copy_4" data-name="Rectangle 1 copy 4" x="740.969" y="291" width="13" height="433.031"/>
      <rect id="Rectangle_1_copy_10" data-name="Rectangle 1 copy 10" x="1048" y="404" width="13" height="792"/>
    </svg>
    <svg id="Group_1_copy_3"    class="color-teeth" data-name="Group 1 copy 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500" *ngSwitchCase="5">
      <path [attr.fill]="color1 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" class="cls-1" d="M368.428,471.414C325.463,522.757,285.6,588.7,253.75,673.379,77.974,1137.52,289.92,1192.42,289.92,1192.42s34.888,12.86,78.408,11.4C368.354,1099.79,368.354,873.234,368.428,471.414Z"/>
      <path [attr.fill]="color1 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" class="cls-2" d="M368.349,596.421c-26.782,43.474-51.285,95-71.853,156.05-74.248,277.749-60.83,400.4-60.83,400.4s43.454,57.5,132.631,51.35C368.354,1049.23,368.354,847.026,368.349,596.421Z"/>
      <path [attr.fill]="color2 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_5" data-name="Shape 1 copy 5" class="cls-1" d="M576.071,320.97c-64.021,24.575-139.709,69.263-207.643,150.444q-7.458,8.912-14.784,18.418c0.24,126.322.387,455.032,0.334,713.968,4.648,0.16,9.444.18,14.35,0.02,57.181-1.92,129.263-28.57,156.7-141.57,17.465-55.28,34.453-101.475,50.961-140.072C575.918,711.532,575.918,379.536,576.071,320.97Z"/>
      <path [attr.fill]="color2 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_5" data-name="Shape 2 copy 5" class="cls-2" d="M575.854,402.777c-72.335,34.833-155.53,99.213-221.827,218.075,0.05,232.67.05,478.758,0.216,583.828,24.541,0.11,52.952-4.63,85.288-17.21,73.852-42.53,95.209-143.97,120.017-215.85,5.32-14.823,10.745-29.616,16.374-44.169C575.918,682.033,575.918,495.243,575.854,402.777Z"/>
      <path [attr.fill]="color3 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_6" data-name="Shape 1 copy 6" class="cls-1" d="M723.956,291.1s-71.453-2.649-162.538,35.768c-0.379,173.3-.379,337.266-0.311,631.853C638.118,760.366,705.062,731.1,761.77,729.4c58.311,1.125,149.722,92.559,196.139,242.56,0.144-115.653.144-509.785,0.09-634.4C892.631,306.993,815.345,289.463,723.956,291.1Z"/>
      <path [attr.fill]="color3 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_6" data-name="Shape 2 copy 6" class="cls-2" d="M742.041,361.956s-82.617-3.789-181.27,48.442c0.268,107.3.268,241.523-1.223,561.222q0.693-1.929,1.388-3.859C604.831,845.941,656.411,727.368,771,729c89.244,21.859,154.494,155.206,187.135,242.373-0.082-298.444-.082-469.486-0.03-552.333C897.9,383.208,826.556,361.753,742.041,361.956Z"/>
      <path [attr.fill]="color4 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_7" data-name="Shape 1 copy 7" class="cls-1" d="M943.8,931.519a614.339,614.339,0,0,1,26.768,87.891c47.382,170.74,67.3,193.41,159.472,187.84q6.27-.735,12.46-1.74c0.06-258.178.06-423.561,0.14-716.1-50.42-65.874-115.43-122.658-199.3-158.4C943.678,382.113,943.678,788.715,943.8,931.519Z"/>
      <path [attr.fill]="color4 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_7" data-name="Shape 2 copy 7" class="cls-2" d="M943.366,934.432c20.573,48.3,33.295,90.318,37.089,106.388,12.856,33.4,26.245,187.6,147.945,164.78q7.275-1.2,14.17-2.65c-0.01-145.21-.01-413.189.1-584.242-48.16-85.419-112.84-161.465-199.641-208.21C943.173,542.773,943.173,764.223,943.366,934.432ZM958,1039"/>
      <path [attr.fill]="color5 || '#fff'" stroke-linejoin="round" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_1_copy_8" data-name="Shape 1 copy 8" class="cls-1" d="M1127.64,1207.39c0.79-.05,1.6-0.09,2.4-0.14,106.7-12.43,200.12-86.35,184.14-235.63-9.84-91.955-45.98-332.186-186.59-501.068C1127.84,631.439,1127.84,1049.18,1127.64,1207.39Z"/>
      <path [attr.fill]="color5 || '#d7d7d7'" stroke="#000" stroke-width="15px" fill-rule="evenodd" id="Shape_2_copy_8" data-name="Shape 2 copy 8" class="cls-2" d="M1127.91,1205.69l0.49-.09c111.09-18.33,152.9-80.74,152.9-80.74S1266.5,818,1127.8,593.507C1127.84,905.505,1127.84,1107.95,1127.91,1205.69Z"/>
      <rect id="Rectangle_1_copy_3" data-name="Rectangle 1 copy 3" x="354" y="490" width="15" height="712"/>
      <rect id="Rectangle_1_copy_7" data-name="Rectangle 1 copy 7" x="561" y="327" width="15" height="600"/>
      <rect id="Rectangle_1_copy_8" data-name="Rectangle 1 copy 8" x="943" y="338" width="15" height="595"/>
      <rect id="Rectangle_1_copy_9" data-name="Rectangle 1 copy 9" x="1128" y="490" width="15" height="712"/>
    </svg>
  </ng-container>
</div>
