export enum ConstClaims {
    User = 'user',
    Clinic = 'clinic',
    Patient = "patient",
    TreatmentPlan = "treatmentplan",
    Order = "order",
    Practice = "practice",
    Role = "role",
    
    Printing = "printing",
    Communicate = "communicate",
    PrintingPortal = "printingportal",
    Notification = "notification",
    TreatmentReview = "treatmentreview"
  }