import { Component, Input, OnInit } from '@angular/core';
import { ShipmentLabelResponse, ShipmentServiceProxy, TrackingResponse } from '../../../../shared/service-proxies/event-service-proxy';
import { NbDialogRef } from '@nebular/theme';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ngx-shipping-label-preview-modal',
  templateUrl: './shipping-label-preview-modal.component.html',
  styleUrls: ['./shipping-label-preview-modal.component.scss']
})
export class ShippingLabelPreviewModalComponent implements OnInit {
  @Input() shippingLabels: ShipmentLabelResponse[];

  constructor(
    protected dialogRef: NbDialogRef<ShippingLabelPreviewModalComponent>,
    private _shippingService: ShipmentServiceProxy) { }

  pdf: Blob = null;
  selectedLabel: ShipmentLabelResponse;
  selectedPage: number = 1;

  ngOnInit(): void {
    this._shippingService.mergePdfFiles(this.shippingLabels.map(x => x.labelDownload.pdf)).subscribe((result) => {
      this.pdf = new Blob([result.data], { type: 'application/pdf' });

    });
    this.selectedLabel = this.shippingLabels[0];
  }

  next() {
    this.tracking = null;
    if (this.selectedPage < this.shippingLabels.length) {
      this.selectedPage++;
    }
    else {
      this.selectedPage = 1;
    }
    this.selectedLabel = this.shippingLabels[this.selectedPage - 1];
  }

  previous() {
    this.tracking = null;
    if (this.selectedPage > 1) {
      this.selectedPage--;
    }
    else {
      this.selectedPage = this.shippingLabels.length;
    }
    this.selectedLabel = this.shippingLabels[this.selectedPage - 1];
  }

  close() {
    this.dialogRef.close(false);
  }

  download() {
    const url = window.URL.createObjectURL(this.pdf);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ShippingLabels.pdf';
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  print() {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = window.URL.createObjectURL(this.pdf);
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  tracking: TrackingResponse;
  isTrackingLoading: boolean = false;

  viewTracking() {
    let url = `https://tracking.greatlab.io/?trackingNumber=${this.selectedLabel.trackingNumber}&carrierCode=${this.selectedLabel.carrierCode}`;
    window.open(url, '_blank');
  }
}
