<div class="modal-viewer-stl">
    <nb-card [nbSpinner]="isLoaderStl" class="stl-card-modal" nbSpinnerStatus="danger" [ngClass]="{'fullscreen-card': expandIframe}">
      <!-- <nb-card-header>
        <div class="fullscreen" (click)="toggleFullScreen()">
          <img [src]="expandIframe ? '../../../../assets/images/icons/full-screen.svg' : '../../../../assets/images/icons/full-screen-back.svg'" alt="">
        </div>
      </nb-card-header> -->
      <nb-card-body>
        <div class="close-modal">
          <nb-icon (click)="close()"  icon="close-modal-icon" pack="great-pack"></nb-icon>
        </div>
        <stl-model-viewer #stlModelViewer *ngIf="isloaderResize" [light]="light" [scene]="scene" [camera]="camera" [renderer]="renderer"
          [controls]="controls" [meshOptions]="[{ position: { x: 0, y: 0.5, z: 0 } }]" [stlModels]="[stl]" (rendered)="onModelLoaded($event)"></stl-model-viewer>
      </nb-card-body>
    <nb-card-footer>
        <div class="row w-100 m-0 p-2 justify-content-end">
                <button (click)="close()" nbButton size="small" class="settings-bt-clinet">
                    Cancel
                </button>
        </div>
    </nb-card-footer>
    </nb-card>
  </div>
  