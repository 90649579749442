<div class="locaction-select-section">
    <div class="change-location-select" (click)="onShowDropdown()">
        <nb-icon icon="arrow-down-black" pack="great-pack"></nb-icon>
        <ng-select (mouseleave)="onSelectClick($event)" [loading]="isReceivedLoading" [markFirst]="true" [appendTo]="'body'"
            (mouseenter)="onShowDropdown()" #selectDropdownReceiving [loading]="false" 
            [closeOnSelect]="false" class="location-select-dropdown" [searchable]="false" [clearable]="false"
            (close)="onSelectClick($event)" bindLabel="name" bindValue="name" [(ngModel)]="locationId">
            <ng-template ng-label-tmp let-item="item">
                sdasdasd
            </ng-template>
            <ng-template ng-header-tmp>
                <div class="receiving-header-select">
                    123
                </div>
            </ng-template>
    
    
    
            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                <div class="ng-option disabled p-0">
                    12321
                </div>
            </ng-template>
            <ng-template ng-loadingtext-tmp>
                <div class="ng-option disabled p-0">
                </div>
            </ng-template>
    
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    
                dasdsa
            </ng-template>
    
            <ng-template ng-footer-tmp>
                <div class="receiving-footer-select">
                    sdsa
                </div>
            </ng-template>
    
        </ng-select>
    </div>
</div>