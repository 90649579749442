<table>
    <tbody>
      <tr>
        <td>
          <div class="content">
            <router-outlet></router-outlet>
          </div>
        </td>
      </tr>
    </tbody>
  </table>