import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { finalize } from 'rxjs/operators';
import {OrderServiceProxy, ReceivingSettingServiceProxy, ReceivingSettingsModel } from '../../../../shared/service-proxies/event-service-proxy';

@Component({
  selector: 'ngx-impressions-received-select',
  templateUrl: './impressions-received-select.component.html',
  styleUrls: ['./impressions-received-select.component.scss']
})
export class ImpressionsReceivedSelectComponent implements OnInit {
  @ViewChild('selectDropdownReceiving') selectDropdown: NgSelectComponent;
  @Output() outPutEventVal = new EventEmitter<any>();
  @Input() order: any;
  isReceivedLoading: boolean = false;
  receivingOptionSelect: any;
  receivingSettingsModelDto: ReceivingSettingsModel = new ReceivingSettingsModel();
  countOfAnalog: any[] = [];  
  constructor(
    private _receivingService: ReceivingSettingServiceProxy,
    private _orderService: OrderServiceProxy
  ) { }
tmpArrayOption: any[] = [];
  ngOnInit(): void {
    if (this.order.receivingType && this.order.receivingOption) {
      if (this.order.receivingType == 'Analogue') {
        this.tmpArrayOption = this.convertStringToArray(this.order.receivingOption);
        this.receivingOptionSelect = this.order.receivingOption;
      } else {
        this.receivingOptionSelect = this.order.receivingOption;
      }

    } else {
      this.receivingOptionSelect = null;
    }
    
    this.getReceivingListOption();
  }
  convertStringToArray(str: any) {
    let arrDto: any[] = [];
    arrDto = str.split(',').map(item => {
      let [count, name] = item.split('_');
      return { name, count: parseInt(count) };
    });
    return arrDto;
  }
 
  getReceivingListOption() {
    this.isReceivedLoading = true;
    this._receivingService.getSettingsByLocationId(this.order.locationId).pipe(
      finalize(() => {
        this.isReceivedLoading = false;
      }))
      .subscribe(
        (result) => {
          this.receivingSettingsModelDto = result;
          this.receivingSettingsModelDto.receivingOptions.map((x: any) => {
            if (this.tmpArrayOption.find(a => a.name == x.name)) {
              x.count = this.tmpArrayOption.find(a => a.name == x.name).count;
            } else {
              x.count = 0;
            }
          });
          
          this.countOfAnalog = Array.from(
            { length: this.receivingSettingsModelDto.analogueUnitsLimit + 1 },
            (v, k) => ({ id: k, name: (k).toString() })
          );
          
        },
        (error: any) => { }
      )
    }

 
  onCloseDropdown() {
   if (this.order.receivingType == 'Analogue') {
     let items = this.receivingSettingsModelDto.receivingOptions.filter((x: any) => x.count !== 0);
     if (items.length == 0) {
      this.receivingOptionSelect = null;
      this.order.receivingOption = null;
      this.order.receivingType = null;
      return false
     }
     let result = items.map((a: any) => {
      let item = `${a.count}_${a.name}`;
      return item;
     }).join(',');
     this.order.receivingOption = result;
    } else {

    }
    this.updateOrder();
    this.selectDropdown.close();
}
  onShowDropdown() {
    this.selectDropdown.open();
  }
 
  mouseSetterType(type: any) {
    if ( this.order.receivingType == null) {
      this.order.receivingType = type
    }
  }

  selectReceiving(type: any) {
    if (type == 'notApplicable') {
      this.order.receivingOption = 'notApplicable';
      this.receivingOptionSelect = 'notApplicable';
    }
    if (type !== this.order.receivingType) {
      this.order.receivingType = type;
    } else {
      this.order.receivingType = null;
    }

  }

  updateOrder() {
    this._orderService.update(this.order).pipe(
      finalize(() => {
      }))
      .subscribe(
        (result) => {
        },
        (error: any) => { }
      )
  }

 
  onSelectClick(event: any) {
 
    this.onCloseDropdown();
   
  }


}
