import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { log } from 'console';
import { filter, finalize } from 'rxjs/operators';
import { AuthServiceProxy, OnboardingFinishedStepModel, OrderServiceProxy } from '../../../../shared/service-proxies/event-service-proxy';
import { AuthenticationService } from '../../../@core/service';
import { ModalCreateMemberComponent } from '../../../pages/settings/modal-create-member/modal-create-member.component';
import { FindOrderByBarcodeComponent } from '../../helpers/find-order-by-barcode/find-order-by-barcode.component';
import { SignalrService } from '../../../@core/service/signalr/signal-r.service';
import { HeaderService } from '../../../@core/service/header.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  user: any = {};
  showButton: boolean = false;
  constructor(
    private router: Router,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private _authenticationService: AuthenticationService,
    private _orderService: OrderServiceProxy,
    private _authService: AuthServiceProxy,
    private signalrService: SignalrService,
    private eventService: HeaderService,
    private changeDetect: ChangeDetectorRef
  ) {
  let currentUser = this._authenticationService.currentUserValue;
  let helper = new JwtHelperService();
  this.user = helper.decodeToken(currentUser.accessToken);
  this.router.events
  .pipe(filter(event => event instanceof NavigationEnd))
  .subscribe((event: NavigationEnd) => {
    this.checkRoute(event.urlAfterRedirects);
  });
  }
  
  async openChat() {
    if (this.user.userroles !== 'Doctor') {
      (<any>window).Intercom('show');
    }
  }
  private eventSubscription: Subscription;
  isOrderFlowType: boolean = false;
  checkRoute(url: string): void {
    const urlSegments = url.split('/');
    if (urlSegments.length === 5 && urlSegments[1] === 'pages' && urlSegments[2] === 'orders' && urlSegments[3] === 'update') {
      this.showButton = true;
      const eventData = { message: true };
      this.eventService.sendEvent(eventData);
      this.eventSubscription = this.eventService.getEvent().subscribe(data => {
        this.isOrderFlowType = data.message;

      });
    } else {
      this.showButton = false;
    }
  }
  logout() {
    this._authenticationService.logout();
  }
countInbox: number = 0;
countInboxDoctor: number = 0;

ngOnInit() {
  this.getCountWithInbox();
  if (this.user.userroles == 'Doctor') {
    this.getCountWithInboxDoctor();
  }
  this.eventSubscription = this.eventService.getEvent().subscribe(data => {
        this.isOrderFlowType = data.message;
      });
}
isWelocme: boolean = false;

getCountWithInboxDoctor() {
  this._orderService.doctorInboxCount().pipe(
    finalize(() => {
      
    }))
  .subscribe(
    (resp) => { 
      this.countInboxDoctor = resp;
    },
    (error: any) => {}
  )
}
 

getCountWithInbox() {
  this._orderService.locationInboxCount().pipe(
    finalize(() => {
      
    }))
  .subscribe(
    (resp) => { 
      this.countInbox = resp;
    },
    (error: any) => {}
  )

}
 
searchBar(event: any) {
  this.router.navigate(['/pages/orders'], {queryParams: {searchParam: event, page: 1}});
} 
 findOrder() {
   this.dialogService.open(FindOrderByBarcodeComponent, {context: {isSearchOrderByAutomations: false}})
     .onClose.subscribe((resp) => {
       if (resp) {
        
       }
     });
 }
 orderFlowType(e: any) {
  const eventData = { message: e };
  this.eventService.sendEvent(eventData);
 }
}
