<nb-card class="modal-card" [nbSpinner]="isLoader" nbSpinnerStatus="primary" *ngIf="tutorial">
    <nb-card-header>


    </nb-card-header>
 
   
   
    <div  [innerHtml]="tutorial"></div>

    <nb-card-footer>

    </nb-card-footer>
</nb-card>