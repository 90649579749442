
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({name: 'ngxOrderFilter'})


export class OrderFilterPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  
  transform(orders: any[],  tagList: any[], orderStatusIds: any[]): any[] {
    if (!orders) {
        return [];
      }
  
      if ((!tagList || tagList.length === 0) && (!orderStatusIds || orderStatusIds.length === 0)) {
        return orders;
      }
  
      const tagFilters = tagList ? tagList.map(tag => tag.tag.toLowerCase()) : [];
      const statusIds = orderStatusIds ? orderStatusIds.map(status => status.id) : [];
  
      return orders.filter(order => {
        return (
          (tagList ? tagFilters.some(tag => order.tagList?.toLowerCase()?.includes(tag)) : true) &&
          (orderStatusIds ? statusIds.includes(order.orderStatusId) : true)
        );
      });
    }
}























