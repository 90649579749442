export enum TeethShadeSystem {
    VitapanClassic = 0,
    Vita3DMaster = 1,
    VitaBleach3DMaster = 2,
    DentsplyPortrait = 3,
    DentsplyBioblend = 4,
    IvoclarIPS = 5,
    IvoclarCromascop = 6,
    IvoclarVivodent = 7,
    DelphicSchottlander = 8
}

export function getTeethShadeSystems() {
    return Object.keys(TeethShadeSystem)
      .filter(key => !isNaN(Number(TeethShadeSystem[key])))
      .map(key => {
        const id = TeethShadeSystem[key];
        return {
          id: id,
          name: TeethShadeSystemDescriptions[id].description
        };
      });
}

export function getShadeSystemDetails(shadeSystemId: TeethShadeSystem): {id: number, name: string, abbreviation: string, shades: any[]} {
    let systemDetails = TeethShadeSystemDescriptions[shadeSystemId];

    if (!systemDetails) {
        console.warn(`Shade system with ID ${shadeSystemId} not found. Using VitapanClassic as fallback.`);
        systemDetails = TeethShadeSystemDescriptions[TeethShadeSystem.VitapanClassic];
        shadeSystemId = TeethShadeSystem.VitapanClassic; 
    }

    return {
        id: shadeSystemId,
        name: systemDetails.description,
        abbreviation: systemDetails.abbreviation,
        shades: systemDetails.shades
    };
}

export function getShadeSystemByAbbreviation(abbreviation: string): {id: number, name: string, abbreviation: string, shades: any[]} | null {
    const systemId = Object.keys(TeethShadeSystemDescriptions)
        .find(key => TeethShadeSystemDescriptions[key].abbreviation === abbreviation);

    if (!systemId) {
        console.warn(`Shade system with abbreviation ${abbreviation} not found.`);
        return null;
    }

    const systemDetails = TeethShadeSystemDescriptions[systemId];

    return {
        id: Number(systemId),
        name: systemDetails.description,
        abbreviation: abbreviation,
        shades: systemDetails.shades
    };
}


export const TeethShadeSystemDescriptions = {
    [TeethShadeSystem.VitapanClassic]: {
        description: "Vitapan Classic",
        abbreviation: "Vitapan C",
        shades: [
            { name: "A1" },
            { name: "A2" },
            { name: "A3" },
            { name: "A3.5" },
            { name: "A4" },
            { name: "B1" },
            { name: "B2" },
            { name: "B3" },
            { name: "B4" },
            { name: "C1" },
            { name: "C2" },
            { name: "C3" },
            { name: "C4" },
            { name: "D2" },
            { name: "D3" },
            { name: "D4" },
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.Vita3DMaster]: {
        description: "Vita 3D Master",
        abbreviation: "Vita 3D",
        shades: [
            { name: "2M1" },
            { name: "2M2" },
            { name: "3M2" },
            { name: "3R2.5" },
            { name: "4M2" },
            { name: "1M1" },
            { name: "2L1.5" },
            { name: "3L2.5" },
            { name: "3M3" },
            { name: "3M1" },
            { name: "3L1.5" },
            { name: "4L1.5" },
            { name: "5M1" },
            { name: "3M1" }, //
            { name: "3M2" },
            { name: "3L1.5" }, // 
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.VitaBleach3DMaster]: {
        description: "Vita Bleach 3D Master",
        abbreviation: "Vita B",
        shades: [
            { name: "0M1" },
            { name: "0.5M1" },
            { name: "1M1" },
            { name: "1M1.5" },
            { name: "1M2" },
            { name: "1.5M2" },
            { name: "2M2" },
            { name: "2.5M2" },
            { name: "3M2" },
            { name: "3.5M2" },
            { name: "4M2" },
            { name: "4.5M2" },
            { name: "5M2" },
            { name: "5M2.5" },
            { name: "5M3" },
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.DentsplyPortrait]: {
        description: "Dentsply Portrait",
        abbreviation: "Dentsply P",
        shades: [
            { name: "P1" },
            { name: "P2" },
            { name: "P3" },
            { name: "P3.5" },
            { name: "P4" },
            { name: "P11" },
            { name: "P12" },
            { name: "P13" },
            { name: "P14" },
            { name: "P21" },
            { name: "P22" },
            { name: "P23" },
            { name: "P24" },
            { name: "P32" },
            { name: "P33" },
            { name: "P34" },
            { name: "PW7" },
            { name: "PW4" },
            { name: "PW2" },
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.DentsplyBioblend]: {
        description: "Dentsply Bioblend",
        abbreviation: "Dentsply B",
        shades: [
            { name: "100" },
            { name: "102" },
            { name: "104" },
            { name: "106" },
            { name: "108" },
            { name: "109" },
            { name: "110" },
            { name: "113" },
            { name: "116" },
            { name: "118" },
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.IvoclarIPS]: {
        description: "Ivoclar IPS",
        abbreviation: "Ivoclar IPS",
        shades: [
            { name: "A1" },
            { name: "A2" },
            { name: "A3" },
            { name: "A3.5" },
            { name: "A4" },
            { name: "B1" },
            { name: "B2" },
            { name: "B3" },
            { name: "B4" },
            { name: "C1" },
            { name: "C2" },
            { name: "C3" },
            { name: "C4" },
            { name: "D2" },
            { name: "D3" },
            { name: "D4" },
            { name: "BL1" },
            { name: "BL2" },
            { name: "BL3" },
            { name: "BL4" },
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.IvoclarCromascop]: {
        description: "Ivoclar Cromascop",
        abbreviation: "Ivoclar C",
        shades: [
            { name: "110" },
            { name: "120" },
            { name: "130" },
            { name: "140" },
            { name: "220" },
            { name: "230" },
            { name: "240" },
            { name: "310" },
            { name: "320" },
            { name: "330" },
            { name: "340" },
            { name: "410" },
            { name: "420" },
            { name: "430" },
            { name: "440" },
            { name: "510" },
            { name: "520" },
            { name: "530" },
            { name: "540" },
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.IvoclarVivodent]: {
        description: "Ivoclar Vivodent",
        abbreviation: "Ivoclar V",
        shades: [
            { name: "01/110" },
            { name: "1A/120" },
            { name: "2A/130" },
            { name: "1C/140" },
            { name: "2B/210" },
            { name: "1D/220" },
            { name: "1E/230" },
            { name: "2C/240" },
            { name: "3A/310" },
            { name: "5B/320" },
            { name: "2E/330" },
            { name: "3E/340" },
            { name: "4A/410" },
            { name: "6B/420" },
            { name: "4B/430" },
            { name: "6C/440" },
            { name: "6D/510" },
            { name: "4c/520" },
            { name: "3C/530" },
            { name: "4D/540" },
            { name: "TBD" }
        ]
    },
    [TeethShadeSystem.DelphicSchottlander]: {
        description: "Delphic Schottlander",
        abbreviation: "Delphic S",
        shades: [
            { name: "O1" },
            { name: "O2" },
            { name: "O3" },
            { name: "O4" },
            { name: "O5" },
            { name: "G4" },
            { name: "G8" },
            { name: "G10" },
            { name: "TBD" }
        ]
    }
};




